<div *ngIf="dataReady && !error">


  <div class="container">
    <div class="row nlf-sticky">
      <!--
      <span class="float-end">
        <a [routerLink]="['/content/edit/', content._id]">
          <fa-icon [icon]="faEdit"></fa-icon>Edit</a>
        <a [routerLink]="['/content/create/', content._id]">
          <fa-icon [icon]="faPlus"></fa-icon>Create</a>
      </span>
      -->
      <!-- Page Heading/Breadcrumbs -->
      <h1 class="mt-4 mb-3 ms-1">
        {{ content.title }}
      </h1>

      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['/content/space', content.space_key]">Hjem</a>
        </li>
        <li *ngFor="let item of parents" class="breadcrumb-item">
          <a [routerLink]="['/content/view', item.space_key, item.slug]">{{ item.title }}</a>
        </li>
        <li class="breadcrumb-item active">{{ content.title }}</li>
      </ol>

    </div>
  </div>

  <div class="container">
    <div class="row">
      <!-- Post Content Column -->

      <!-- Date/Time -->
      <p class="small">Opprettet {{ content._created | amCalendar }} sist endret {{ content._updated | amCalendar }} av
        <nlf-resolve-lungo-person [person_id]="content.owner" [link]="true" [avatar]="true"></nlf-resolve-lungo-person>
        <sup class="text-danger mx-1" title="Applikasjonen er ikke ferdig utviklet">beta</sup>
      </p>

      <hr>

      <!-- Post Content -->
      <div [innerHTML]="content.body | safe: 'html'"></div>
      <hr>

      <!--
      <div class="col-lg-12">
        <div class="col-lg-3">

          <h3>Undersider:</h3>
          <div *ngFor="let child of children | reverse">
            <a [routerLink]="['/content/view', child.space_key, child.slug]">{{ child.title }} ({{ child.levelBelove + 1 }})</a>
          </div>
        </div>
        <div class="col-lg-3">
          <h3>Sidestilt:</h3>
          <div *ngFor="let sibling of siblings">
            <a [routerLink]="['/content/view', sibling.space_key, sibling.slug]">{{ sibling.title }}</a>
          </div>
        </div>

      <div class="col-lg-3">
      <h3>Space tree:</h3>
      <div *ngFor="let space of space_tree">
        <nlf-content-tree [tree]="space" [current]="content._id" ></nlf-content-tree>
      </div>
      </div>
      </div>

    -->
    </div>
  </div>
  <div *ngIf="!dataReady && !error">
    <nlf-ui-page-spinner></nlf-ui-page-spinner>
  </div>

  <div *ngIf="error">
    <p class="align-middle text-center text-secondary my-5">
      ERROR, please go back to <a [routerLink]="['/content']">content home</a>
    </p>
  </div>

</div>