  <div class="nlf-sticky-toolbar">
    <div class="container nlf-dark">
      <fa-icon [icon]="faSitemap"></fa-icon>Content spaces

      <a [routerLink]="['create']" class="float-end btn btn-primary btn-sm">
        <fa-icon [icon]="faPlus"></fa-icon>Ny space
      </a>
      <sup class="text-danger mx-1" title="Applikasjonen er ikke ferdig utviklet">beta</sup>
    </div>
  </div>
  <div class="container">
    <span *ngIf="!!dataReady" class="overflow-auto">
      <ngx-table [id]="'content-spaces-table'" [configuration]="tableSpaces" [data]="spaces" [columns]="columns" [(pagination)]="pagination" (event)="eventEmitted($event)">

        <ng-template let-row>
          <td class="ngx-easy-table-small">
            <fa-icon [icon]="faSitemap"></fa-icon>&nbsp;
            <a [routerLink]="['space/', row.space_key]">{{ row.space_key }}</a>
          </td>
          <td class="ngx-easy-table-small">
            <a [routerLink]="['space/', row.space_key, row.slug]">{{ row.title }}</a>
          </td>
          <td>
            <span class="pointer" (click)="delete(row)">
              <fa-icon [icon]="faClose"></fa-icon>
            </span>
            <span class="pointer" [routerLink]="['edit/', row._id]">
              <fa-icon [icon]="faEdit"></fa-icon>
            </span>
            <span class="pointer" [routerLink]="['create/', row._id]">
              <fa-icon [icon]="faPlus"></fa-icon>
            </span>
          </td>
        </ng-template>
      </ngx-table>
    </span>
    <span *ngIf="!dataReady">
      <p class="align-middle text-center  text-secondary">
        <nlf-ui-component-spinner size="3"></nlf-ui-component-spinner>
      </p>
    </span>

  </div>
