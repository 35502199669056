<div *ngIf="editorFullscreen" class="nlf-toolbar fixed-top" style="display: static; margin-bottom: 100px;">
  <h2>
    <input [(ngModel)]="content.title" (ngModelChange)="change()" type="text" class="form-control" id="contenttitle" placeholder="Tittel" (ngModelChange)="slugify()">
  </h2>
</div>

<div *ngIf="dataReady">

  <div class="nlf-sticky-toolbar">
    <div class="container">
      <fa-icon [icon]="faEdit"></fa-icon>{{ content.title }}
      <sup class="text-danger mx-1" title="Applikasjonen er ikke ferdig utviklet">beta</sup>
    </div>
  </div>

</div>
<nlf-ui-breadcrumb></nlf-ui-breadcrumb>

<div *ngIf="dataReady" class="h-100">

  <div class="col-sm-12 h-100">
    <div class="container">

      <div class="form-group">
        <label for="contenttitle">Tittel</label>
        <input [(ngModel)]="content.title" (ngModelChange)="change()" type="text" class="form-control" id="contenttitle" placeholder="Tittel" (ngModelChange)="slugify()">
      </div>

      <div class="form-group">
        <label for="contenttitle">Slug</label>
        <input [(ngModel)]="content.slug" (ngModelChange)="change()" type="text" class="form-control" id="contentslug" placeholder="" disabled>
      </div>
      <!-- Create page has parent page (space root page) -->
      <div class="form-group" *ngIf="mode==='create_page'">
        <label for="contenttitle">Parent</label>
        <input [(ngModel)]="content.parent" (ngModelChange)="change()" type="text" class="form-control" id="contentparent" placeholder="">
      </div>

      <!-- Space key, always show -->
      <div class="form-group">
        <label for="spacekey">Space key</label>
        <input *ngIf="mode==='create_space'" [(ngModel)]="content.space_key" (ngModelChange)="change()" type="text" class="form-control" id="spacekey" placeholder="Unik nøkkel space">
        <input *ngIf="mode==='edit' || mode==='create_page'" [ngModel]="content.space_key" (ngModelChange)="change()" type="text" class="form-control" id="spacekey" disabled>
      </div>

      <!-- Jodit: tagname, id, defaultvalue, config -->
      <jodit-editor #editor id="joditid" [config]="joditConfig" [(defaultValue)]="content.body"></jodit-editor>

      <!-- <p [innerHTML]="content.body | safe: 'html'"></p> -->

    </div>


    <div class="nlf-toolbar fixed-bottom">
      <span class="float-end">


        <a *ngIf="mode==='create_space'" class="btn" [routerLink]="['/content']">
          <fa-icon [icon]="faClose"></fa-icon>Cancel
        </a>

        <a *ngIf="mode==='create_page'" class="btn" [routerLink]="['/content/space', content.space_key]">
          <fa-icon [icon]="faClose"></fa-icon>Cancel
        </a>

        <a *ngIf="mode==='edit'" class="btn" [routerLink]="['/content/view', content.space_key, content.slug]">
          <fa-icon [icon]="faClose"></fa-icon>Cancel
        </a>

        <button [disabled]="!changed" *ngIf="mode==='edit'" (click)="save()" class="btn btn-primary">
          <fa-icon [icon]="faSave"></fa-icon>Save
        </button>

        <button *ngIf="mode==='create_space'" (click)="create()" class="btn btn-success">
          <fa-icon [icon]="faPlus"></fa-icon>Create space
        </button>
        <button *ngIf="mode==='create_page'" (click)="create()" class="btn btn-success">
          <fa-icon [icon]="faPlus"></fa-icon>Create page
        </button>
      </span>
    </div>
  </div>
</div>
<div *ngIf="!dataReady">
  <nlf-ui-page-spinner></nlf-ui-page-spinner>
</div>
