<div class="form-group">

  <label [for]="path">{{ customLabel || label }}
    <span *ngIf="!!unit">[{{ unit }}]</span>
    <fa-icon (click)="openModal(modalHelp)" *ngIf="show_help" class="ms-1 pointer" [icon]="faQuestion"></fa-icon>
  </label>

  <span *ngIf="!attribute">
    <nlf-ui-component-spinner></nlf-ui-component-spinner>
  </span>

  <!-- -->
  <ng-container *ngIf="!!attribute">

    <!-- Simple within limits, all populated -->
    <ng-container *ngIf="choices.length>0 && !search">
      <nlf-ors-editor-tag-static (change)="debouncedUpdate($event)" [disable]="disabled" [(items)]="items"
        [tags]="choices" [multiple]="(attribute.max>1 || attribute.max===-1) ? true : false"
        [max]="attribute.max>1 ? attribute.max : undefined" [id]="path">
      </nlf-ors-editor-tag-static>
    </ng-container>
    <!-- /END simple tags -->

    <!-- SEARCH MANY -->
    <ng-container *ngIf="search">
      <ng-select [id]="path" [disabled]="disabled" class="form-control w-auto mw-100" [items]="tags$ | async"
        [typeahead]="tagsInput$" [addTag]="false" [multiple]="(attribute.max>1 || attribute.max===-1) ? true : false"
        [maxSelectedItems]="attribute.max>1 ? attribute.max : undefined" [hideSelected]="true"
        [(ngModel)]="selectedTags" (change)="onChange($event)" [clearable]="true" bindLabel="label" labelForId="id"
        autoCapitalize="off" placeholder="Skriv for å søke" notFoundText="Fant ikke noe">
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
          <div>
            <span [ngOptionHighlight]="search">{{item.label}}</span>
          </div>
          <small>{{ item.descr }}</small>
        </ng-template>
      </ng-select>
    </ng-container>
    <!-- /END SEARCH MANY -->

    <!-- Just an Input -->
    <ng-container *ngIf="!search && !attribute.choices_key && choices.length===0 && attribute.max===1">

      <!-- Number -->
      <span *ngIf="!!attribute.restrictions">
        <input
          *ngIf="attribute.restrictions.type==='decimal' || attribute.restrictions.type==='integer' || attribute.restrictions.type==='int'"
          type="number" [max]="attribute.restrictions.max" [min]="attribute.restrictions.min"
          [step]="attribute.restrictions.inc" [disabled]="disabled" (change)="debouncedUpdate($event)"
          [(ngModel)]="items" [id]="path" class="form-control">

        <!-- String -->
        <input *ngIf="attribute.restrictions.type==='string'" type="{{ type || 'text'}}"
          [max]="attribute.restrictions.max" [min]="attribute.restrictions.min || 0" [disabled]="disabled"
          (change)="debouncedUpdate($event)" [(ngModel)]="items" [id]="path" class="form-control">
      </span>

      <span *ngIf="!attribute.restrictions">
        <!-- String -->
        <input *ngIf="attribute.type==='string'" type="{{ type || 'text'}}" [disabled]="disabled"
          (change)="debouncedUpdate($event)" [(ngModel)]="items" [id]="path" class="form-control">
        <!-- Number -->
        <input *ngIf="attribute.type!='string'" type="number" [disabled]="disabled" (change)="debouncedUpdate($event)"
          [(ngModel)]="items" [id]="path" class="form-control">
      </span>


    </ng-container>
    <!-- /END Just an input -->

  </ng-container>
  <!-- DEBUG:
  <code>{{ items | json }}</code>
  <code>{{ selectedTags | json }}</code>
  -->
</div>


<!-- RUNWAY MODAL -->
<ng-template #modalHelp>
  <div class="modal-header">
    <h4 class="modal-customLabel pull-left">
      {{ customLabel || label }}
    </h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="modalRef.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">

    <!-- CHOICES DESCR -->
    <div class="row my-3" *ngIf="!!attribute.choices_key && !!choices">
      <div class="col-md-12">
        <dl>
          <div *ngFor="let choice of choices; let idx = index">
            <dt>{{ choice.label }} <span class="badge bg-secondary" *ngIf="!!choice.icao">{{ choice.icao }}</span>
            </dt>
            <dd>{{ choice.descr }}</dd>
            <dd>{{ choice.expl }}</dd>
          </div>
        </dl>
      </div>
    </div>

    <!-- ATTRIBUTE SUMMARY -->
    <div *ngIf="!!attribute" class="row">
      <div class="col-md-12">
        <div class="alert alert-info">
          <div>Attributt: {{ attribute.attribute }}</div>
          <div>Attributt Id: {{ attribute.attribute_id }}</div>
          <div>Data type: {{ attribute.type }}</div>
          <div>Antall: {{ attribute.min }} - {{ attribute.max }}</div>
          <div *ngIf="!!attribute.default">Default: {{ attribute.default }}</div>
          <div *ngIf="!!attribute.restrictions">
            <div>Type: {{ attribute.restrictions.type }}</div>
            <div>Min: {{ attribute.restrictions.min }}</div>
            <div>Max: {{ attribute.restrictions.max }}</div>
          </div>
          <div>RIT version: {{ attribute.rit_version }}</div>
        </div>
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button class="btn btn-outline-dark" (click)="modalRef.close()">
      <fa-icon [icon]="faTimes" [fixedWidth]="true"></fa-icon>Ferdig
    </button>
  </div>
</ng-template>
