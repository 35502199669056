<ng-container *ngIf="!!observation">

  <div class="row">
  <div class="col-xs-12 col-md-12">
    <div *ngIf="!!observation.weather.auto?.metar_nearest?.parsed" [innerHTML]="observation.weather.auto?.metar_nearest?.parsed | newlines">
    </div>
  </div>
</div>
<div *ngIf="!!tafmetar" class="row mt-3">
  <div class="col-xs-12 col-md-6">
    <div class="d-grid gap-2">
      <button (click)="openModal(autoModal, 'metar')" [ngClass]="!!tafmetar.metar ? 'btn-success': 'btn-secondary'" class="btn my-1">METAR</button>
    </div>
  </div>
  <div class="col-xs-12 col-md-6">
    <div class="d-grid gap-2">
      <button (click)="openModal(autoModal, 'taf')" [ngClass]="!!tafmetar.taf ? 'btn-success': 'btn-secondary'" class="btn my-1">TAF</button>
    </div>
  </div>
  <!--
  <div class="col-xs-12 col-md-4">
    <div class="d-grid gap-2">
      <button (click)="openModal(autoModal, 'yr')" [ngClass]="!!tafmetar.yr ? 'btn-success': 'btn-secondary'" class="btn my-1">YR</button>
    </div>
  </div>
-->
</div>
</ng-container>

<ng-template #autoModal>

  <div class="modal-header">
    <h4 class="modal-title pull-left">
      {{ modal.title | upperfirst }} {{ observation.when | date:'yyyy-MM-dd'}}
    </h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="modalRef.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="col-xs-12 overflow-auto">
      <div *ngFor="let row of modal.data">
        {{ row }}
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button (click)="modalRef.dismiss()" class="btn btn-outline-dark">Ferdig</button>
  </div>
</ng-template>
