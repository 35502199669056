<ngb-alert class="page-alert"
[ngClass]="{'fixed-top': !message.inline}"
*ngIf="message" [type]="message.type"
[dismissible]="true" (close)="close()">
  <fa-icon *ngIf="message.type === 'success'" [fixedWidth]="true" [icon]="faCheck"></fa-icon>
  <fa-icon *ngIf="message.type === 'info'" [fixedWidth]="true" [icon]="faInfoCircle"></fa-icon>
  <fa-icon *ngIf="message.type === 'warning'" [fixedWidth]="true" [icon]="faExclamationCircle"></fa-icon>
  <fa-icon *ngIf="message.type === 'danger'" [fixedWidth]="true" [icon]="faExclamationTriangle"></fa-icon>
   {{ message.text }}
</ngb-alert>
