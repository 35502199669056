<div class="modal-header">
  <h4 class="modal-title pull-left">
    Hjelp for #{{ observation.id }}</h4>
  <button type="button" class="close float-end" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>
    Du redigerer nå observasjon #{{ observation.id }}. Denne siden er det vi kaller editoren. Du vil også kunne se
    rapporten i rapportvisning.
  </p>
  <p>
    For hver logiske komponent i editoren vil du kunne se hjelpetekst for respektive bolker ved å trykke på
    spørsmålstegnet
    <fa-icon class="text-info mx-1" [icon]="faQuestion"></fa-icon> .
  </p>
  <p>
    Øverst på siden har du verktøylinjen som bla viser løpenummer, tittel og rating (til venstre) og til høyre finner
    du følgende:
  </p>
  <p>
    <fa-icon class="text-info mx-1" [icon]="faQuestion"></fa-icon>

    <span (click)="save()" *ngIf="changes" class="pointer badge bg-warning mx-1">
      <fa-icon [icon]="faSave"></fa-icon>
    </span>

    <span class="badge bg-secondary mx-1">{{ observation._updated | amTimeAgo }}</span>

    <span class="badge bg-success mx-1">v{{ observation._version }}</span>

    <nlf-resolve-observation-state activity="fallskjerm" [state]="observation.workflow.state" icon="true"></nlf-resolve-observation-state>

    <!-- E5X -->
    <nlf-ors-e5x *ngIf="['motorfly', 'sportsfly', 'seilfly'].indexOf(observation._model.type)"></nlf-ors-e5x>

    <!-- ACTIVITIES -->
    <a class="badge bg-info mx-1" [routerLink]="['/ors', observation._model.type , 'activities', observation.id]">
      <fa-icon [icon]="faHistory"></fa-icon> Aktivitet
    </a>

    <!-- ACCESS!!! -->
    <nlf-ors-editor-users *ngIf="['closed', 'withdrawn'].indexOf(observation.workflow.state)<0 && observation.acl_user.x" [model]="observation._model.type" [_id]="observation._id" [observation]="observation"></nlf-ors-editor-users>

    <!-- REPORT LINK -->
    <a [routerLink]="['/ors', observation._model.type , 'report', observation.id]" *ngIf="observation.acl_user.x || observation.acl_user.w" class="badge bg-primary text-white mx-1">
      <fa-icon [icon]="faFile"></fa-icon> Report
    </a>

  </p>
  <p>
    <span class="badge bg-secondary mx-1">{{ observation._updated | amTimeAgo }}</span>
    sier noe om hvor lenge det er siden du har lagret. Klikker du på Denne vil du få opp informasjon om observasjonen
    du redigerer.

  </p>
  <p>
    Om du har endringer vil også
    <span class="badge pointer bg-warning mx-1">
      <fa-icon [icon]="faSave"></fa-icon>
    </span>
    vises og indikerer at du kan lagre ved å klikke på det.
  </p>
  <p>
    <span class="badge bg-success mx-1">v{{ observation._version }}</span> viser hvilken versjon av observasjonen du
    jobber på. Dette er altså versjon {{ observation._version }}.
    Alle endringer tas vare på og ved å klikke på denne kan du se alle versjoner av denne observasjonen og videre
    kan du se endringer mellom versjonene.
  </p>
  <p>
    <strong>Når du er ferdig med å redigere skal du trykke på:</strong>
  </p>
  <p>
    <nlf-resolve-observation-state [activity]="observation._model.type" [state]="observation.workflow.state" icon="true"></nlf-resolve-observation-state>
    <!--
    <span class="pointer mx-1" [routerLink]="['/ors', observation._model.type,'edit', 'workflow', observation.id]"
      (click)="activeModal.close()">
      <nlf-resolve-observation-state [activity]="observation._model.type" [state]="observation.workflow.state" icon="true"></nlf-resolve-observation-state>
    </span>
  -->
  </p>
  <p>
    Denne knappen aktiverer arbeidsflyten (saksgangen) og du får et nytt vindu hvor du kan legge inn kommentarer før
    du godkjenner
    og sender videre eller avslår og sender tilbake (avhengig hvor du er).
  </p>
  <p>
    Om du har opprettet ved en feiltagelse eller noe annet og du ønsker å slette eller avbryte observasjonen så
    velger du "Trekk
    tilbake". Ombestemmer du deg etter å ha trukket observasjonen kan du "Gjenåpne".
  </p>

  <p *ngIf="observation._model.type=='motorfly'">
    <!-- E5X -->
    <nlf-ors-e5x></nlf-ors-e5x> viser status på innsendelse av rapporten til Luftfartstilsynet (LT). Formatet det
    sendes på kalles E5X. Denne knappen aktiverer vinduet for å behandle generering av fil, innsendelse og historikk.
  </p>

  <p>
    <!-- ACTIVITIES -->
    <a *ngIf="observation.workflow.state!='closed'" class="badge bg-info mx-1" [routerLink]="['/ors', observation._model.type , 'activities', observation.id]">
      <fa-icon [icon]="faHistory"></fa-icon> Aktivitet
    </a> åpner en side som viser alle hendelser for denne observasjonen. Her kan du purre og sende meldinger til de som behandler denne observasjonen.
  </p>

  <p *ngIf="['closed', 'withdrawn'].indexOf(observation.workflow.state)<0 && observation.acl_user.x">
    <!-- ACCESS!!! -->
    <nlf-ors-editor-users *ngIf="['closed', 'withdrawn'].indexOf(observation.workflow.state)<0 && observation.acl_user.x" [model]="observation._model.type" [_id]="observation._id" [observation]="observation"></nlf-ors-editor-users>
    gir deg muligheten til å legge til personer til observasjonen både med skrive og lese rettigheter etter ditt eget ønske. Søk de opp, legg de til og husk å huke av for hvilken rettighet de skal ha!
  </p>
  <p>
    <!-- REPORT LINK -->
    <a [routerLink]="['/ors', observation._model.type , 'report', observation.id]" *ngIf="observation.acl_user.x || observation.acl_user.w" class="badge bg-primary text-white mx-1">
      <fa-icon [icon]="faFile"></fa-icon> Report
    </a> åpner observasjonen i rapportvisning og du kan på den måten forhåndsvise hvordan den endelige observasjonen vil komme til å se ut når den er ferdigbehandlet.</p>

  <p>
    <strong>Hurtigtaster</strong><br />
    <span class="badge bg-secondary">CTRL+s</span>/<span class="badge bg-secondary">COMMAND+s</span> Lagre<br>
    <span class="badge bg-secondary">CTRL+h</span>/<span class="badge bg-secondary">COMMAND+h</span> Hjelp (denne)<br>
    <span class="badge bg-secondary">CTRL+g</span>/<span class="badge bg-secondary">COMMAND+g</span> Debug<br>
  </p>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-dark" (click)="activeModal.close()">Ferdig</button>
</div>
