<!-- THUMBNAILS -->

<div class="row text-center text-lg-left">

  <div *ngFor="let thumb of thumbnails; let idx = index" class="col-lg-2 col-md-3 col-xs-6 mb-2 mt-1">
    <span (click)="openModal(modalImage, thumb, idx)" class="pointer text-center">
      <img class="img-fluid img-thumbnail" [src]="domSanitizer.bypassSecurityTrustUrl(thumb.src)" alt="{{ thumb.filename }}">
    </span>
  </div>
</div>

<!-- /THUMBNAILS -->

<div *ngIf="!dataReady">
  <nlf-ui-component-spinner></nlf-ui-component-spinner>Loading thumbnails...
</div>

<!-- Modal -->
<ng-template #modalImage>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ viewImageName }}</h4>

    <button type="button" class="close float-end" aria-label="Close" (click)="modalRef.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">

    <img *ngIf="!viewImageLoading" class="img-fluid" [src]="domSanitizer.bypassSecurityTrustUrl(viewimage.src)" alt="file">

    <span *ngIf="!!viewImageLoading" class="text-center">
      <!--<nlf-ui-page-spinner></nlf-ui-page-spinner>-->
      <fa-icon class="text-center" [icon]="faSpinner" animation="pulse" size="5x"></fa-icon>
    </span>

  </div>
  <div class="modal-footer">
    <span *ngIf="thumbnails.length>1" class="me-auto">
      <span (click)="getModalImage(viewImageData['idx']-1)" class="pointer ms-1 me-2">
        <fa-icon [icon]="faChevronLeft" class="mx-2"></fa-icon> Forrige
      </span>
      <span (click)="getModalImage(viewImageData['idx']+1)" class="pointer me-1 ms-2">Neste <fa-icon [icon]="faChevronRight" class="mx-2"></fa-icon></span>
    </span>

    <a class="btn btn-secondary" (click)="modalRef.close()">Close</a>
  </div>
</ng-template>
