<span *ngIf="dataReady">
  <span *ngIf="!!link && function.id !== ''">
      <a [routerLink]="['/function', function.id]">{{ function.type_name }}</a>
  </span>
  <span *ngIf="!link || function.id === ''">
      {{ function.type_name }}
  </span>
  <nlf-resolve-lungo-organization [organization_id]="function.active_in_org_id" [show_activity]="true"></nlf-resolve-lungo-organization>
</span>
<span *ngIf="!dataReady">
  <nlf-ui-component-spinner></nlf-ui-component-spinner>
</span>
