<div *ngIf="!showOnlyBtn" class="row">
  <div class="col-md-3">
    <div class="d-grid gap-2">
      <button class="btn btn-secondary" (click)="openModal(modal)">
        <fa-icon [icon]="faFire"></fa-icon> Damage(s)
      </button>
    </div>
  </div>
  <div class="col-md-9">
    <nlf-ors-editor-tag-e5x-render *ngIf="!!attr.highestDamage.value" classes="badge bg-danger" [items]="attr.highestDamage.value" path="E5X.Occurrence.HighestDamage"></nlf-ors-editor-tag-e5x-render>
  </div>
</div>

<div *ngIf="showOnlyBtn" class="d-grid gap-2">
  <button class="btn btn-secondary" (click)="openModal(modal)">
    <fa-icon [icon]="faFire"></fa-icon> Damage(s)
  </button>
</div>

<!-- ROUTE MODAL -->
<ng-template #modal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      Damage(s)
    </h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="modalRef.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">

    <div class="row">
      <div class="col-md-6">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="attr.highestDamage.value" path="E5X.Occurrence.HighestDamage" id="HighestDamage"></nlf-ors-editor-tag-e5x>
      </div>

      <div class="col-md-6">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="attr.damageNotToAC.value" path="E5X.Occurrence.DamageNotToAC" id="DamageNotToAC"></nlf-ors-editor-tag-e5x>
      </div>
    </div>
    <div class="row" *ngIf="!!attr.damageNotToAC.value && attr.damageNotToAC.value != 97">
      <div class="col-md-12">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="attr.objectDamaged.value" path="E5X.Occurrence.ObjectDamaged" id="ObjectDamaged"></nlf-ors-editor-tag-e5x>
      </div>

      <div class="col-md-12">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="attr.thirdPartyDamage.value" path="E5X.Occurrence.ThirdPartyDamage" id="ThirdPartyDamage"></nlf-ors-editor-tag-e5x>
      </div>
    </div>
    <div *ngIf="devDebug" class="row">
      AC: {{ attr | json }}
    </div>


    <!--
    <div class="row" *ngIf="!!attr.highestDamage.value && attr.highestDamage.value > 0 && [98, 99].indexOf(attr.highestDamage.value)<0">
        <div class="col-md-12">
            <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="attr.partsStruck.value" path="E5X.Occurrence.Aircraft.PartsStruck" id="PartsStruck"></nlf-ors-editor-tag-e5x>
        </div>

        <div class="col-md-12">
            <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="attr.partsDamaged.value" path="E5X.Occurrence.Aircraft.PartsDamaged" id="PartsDamaged"></nlf-ors-editor-tag-e5x>
        </div>
    </div>
    -->
  </div>

  <div class="modal-footer">
    <button class="btn btn-outline-dark" (click)="modalRef.close()">
      <fa-icon [icon]="faTimes" [fixedWidth]="true"></fa-icon>Avbryt
    </button>
    <button [disabled]="disabled" [ngClass]="{'disabled': disabled}" class="btn btn-success" (click)="modalUpdate(modalIndex)">
      <fa-icon [icon]="faCheck" [fixedWidth]="true"></fa-icon>Oppdater
    </button>
  </div>
</ng-template>
