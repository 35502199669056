<div class="input-group">
  <input [disabled]="disabled" class="form-control" placeholder="yyyy-mm-dd" name="dp" (dateSelect)="debouncedEmit($event.next)" [(ngModel)]="model"
    ngbDatepicker [footerTemplate]="footerTemplate" #d="ngbDatepicker" [maxDate]="today">
  <button [disabled]="disabled" class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
    <fa-icon [icon]="faCalendar"></fa-icon>
  </button>
</div>

<ng-template #footerTemplate>
  <hr class="my-0">
  <button class="btn btn-primary btn-sm m-2 float-start" (click)="model = today; d.close()">Today</button>
  <button class="btn btn-secondary btn-sm m-2 float-end" (click)="d.close()">Close</button>
</ng-template>
