<span *ngIf="dataReady">
  
  <span *ngIf="!!link && function.id !== ''">
    <a [routerLink]="['/function/type', function_type.id]">{{ function_type.name }}</a>
  </span>
  <span *ngIf="!link || function.id === ''">
    {{ function_type.name }}
  </span>
  <span *ngIf="long">
    {{ function_type.category_name }}
  </span>

</span>
<span *ngIf="!dataReady">
  <nlf-ui-component-spinner></nlf-ui-component-spinner>
</span>