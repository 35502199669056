<header class="nlf-sticky-toolbar">
  <div class="container lead">
    OBSREG Seilfly

    <button [routerLink]="['/ors', 'seilfly', 'search']" type="button" class="btn btn-sm btn-primary float-end">
      <fa-icon [icon]="faSearch"></fa-icon> Søk
    </button>

    <nlf-help class="float-end mx-1" key="ors-seilfly"></nlf-help>
  </div>


</header>

<div class="container">

  <div class="row">
    <!-- MY -->
    <div class="col-md-6 col-xs-12 overflow-auto">
      <div class="nlf-ors-toolbar">
        <fa-icon [icon]="faUser" class="text-secondary"></fa-icon> Mine observasjoner
      </div>
      <nlf-ors-self-table activity="seilfly"></nlf-ors-self-table>
    </div>
    <!-- MY TODO -->
    <div class="col-md-6 col-xs-12 overflow-auto">
      <div class="nlf-ors-toolbar">
        <fa-icon [icon]="faExchange" class="text-secondary"></fa-icon> Til min behandling
      </div>
      <nlf-ors-todo-table activity="seilfly"></nlf-ors-todo-table>
    </div>

    <!-- Group = tight, with padding deck = spacing, no padding card-body style="padding: 0;"
      <div class="card-deck">

        <div class="card mb-3 me-1">
          <h5 class="card-header">
            <fa-icon [icon]="faUser"></fa-icon>Mine observasjoner</h5>
          <div class="card-body table-responsive" style="padding: 0;">

          </div>
        </div>

        <div class="card mb-3 ms-1">
          <h5 class="card-header">
            <fa-icon [icon]="faRandom"></fa-icon>Til min behandling</h5>
          <div class="card-body" style="padding: 0;">
            <nlf-ors-todo-table  activity="seilfly"></nlf-ors-todo-table>
          </div>
        </div>
      </div>
      -->
  </div>
</div>

<!-- CREATE -->
<div class="py-5 nlf-bg-blue col-md-12">
  <div class="container">
    <p class="m-0 text-center text-white">
      <nlf-ors-seilfly-create></nlf-ors-seilfly-create>
    </p>
  </div>
</div>

<!-- ALL OBSREG -->
<div class="container">
  <div class="row">
    <div class="col-md-12 col-xs-12 overflow-auto">
      <div class="nlf-ors-toolbar">
        <fa-icon [icon]="faList" class="text-secondary"></fa-icon> Alle observasjoner
      </div>
      <nlf-ors-all-table activity="seilfly"></nlf-ors-all-table>
    </div>
  </div>
</div>
