<ng-container *ngIf="dataReady">
  <form class="form-inline">
    <div class="form-group">
      <div class="input-group has-validation">
        <input [ngClass]="{'is-invalid': dateError, 'is-valid': !dateError }" type="text"
          [disabled]="!observation.acl_user.w" class="form-control" placeholder="yyyy-mm-dd" name="dp"
          [(ngModel)]="date" [maxDate]="getMaxDate()" (ngModelChange)="debouncedUpdate()" ngbDatepicker
          #d="ngbDatepicker">

        <button [disabled]="!observation.acl_user.w" class="btn btn-outline-secondary" (click)="d.toggle()"
          type="button">
          <fa-icon [icon]="faCalendar"></fa-icon>
        </button>

        <div id="validationServerUsernameFeedback" class="invalid-feedback">
          <ng-container *ngIf="dateErrorType==='future'">
            Du kan ikke sette dato i fremtiden for observasjonen den ble opprettet {{observation._created |
            date:'yyyy-MM-dd klokken HH:mm':tz}} {{ tz }}
          </ng-container>
          <ng-container *ngIf="dateErrorType==='format'">
            Feil datoformat, vennligst skriv inn på formen "yyyy-mm-dd" eller velg fra nedtrekksmenyen
          </ng-container>

        </div>

      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-md-5">
      <ngb-timepicker [readonlyInputs]="!observation.acl_user.w" [(ngModel)]="time"
        (ngModelChange)="debouncedUpdate($event)"></ngb-timepicker>
    </div>
    <div class="col-md-4" style="align-self:'center';">{{ tz }}</div>
  </div>
  <!--
<hr> {{ date | json }} og {{ time | json }}
<br> {{ maxDate }} {{ observation.when | json }}
-->
</ng-container>