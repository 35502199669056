<span *ngIf="!!dataReady">
  <ngx-table [id]="'ors-todo-table'" [configuration]="tableConf" [data]="data" [columns]="columns" [(pagination)]="pagination" (event)="eventEmitted($event)">

    <ng-template let-row>
      <td class="ngx-easy-table-small">
        <a *ngIf="row.workflow.state!=='closed'" [routerLink]="['/ors', row._model.type, 'edit', row.id]">{{ row.id }}</a>
        <a *ngIf="row.workflow.state==='closed'" [routerLink]="['/ors', row._model.type, 'report', row.id]">{{ row.id }}</a>
      </td>
      <td class="ngx-easy-table-small">
        <span *ngIf="row.workflow.state!=='draft'">{{ row.workflow.last_transition | amTimeAgo }}</span>
        <span *ngIf="row.workflow.state==='draft'">{{ row._updated | amTimeAgo }}</span>
      </td>
      <td class="ngx-easy-table-small">
        <a *ngIf="row.workflow.state!=='closed'" [routerLink]="['/ors', row._model.type, 'edit', row.id]">{{ row.title || (row.tags | nlfOrsTags: '/':23:'..') }}</a>
        <a *ngIf="row.workflow.state==='closed'" [routerLink]="['/ors', row._model.type, 'report', row.id]">{{ row.title || (row.tags | nlfOrsTags: '/':23:'..') }}</a>
      </td>
      <td class="ngx-easy-table-small">
        <span *ngIf="!!row.workflow.state">
          <ng-container *ngIf="['closed', 'withdrawn'].indexOf(row.workflow.state)<0">
            <fa-icon *ngIf="timedelta(row._updated)>=5*24*3600&&timedelta(row._updated)<10*24*3600" [icon]="faClock" class="text-warning" ngbTooltip="Det er over 5 dager siden det har vært aktivitet"></fa-icon>
            <fa-icon *ngIf="timedelta(row._updated)>=10*24*3600" [icon]="faClock" class="text-danger" ngbTooltip="Det er over 10 dager siden det har vært aktivitet"></fa-icon>
          </ng-container>

          {{ row.workflow.state | nlfOrsState: row._model.type }}
        </span>
      </td>

      <!--<td [innerHTML]="row.type | nlfOrsType: row._model.type" class="ngx-easy-table-small">-->
      <td class="ngx-easy-table-small text-end">
        <nlf-resolve-observation-types [type]="row.type" [activity]="row._model.type" [badge]="true"></nlf-resolve-observation-types>
      </td>

    </ng-template>
  </ngx-table>
</span>
<span *ngIf="!dataReady">
  <p class="align-middle text-center text-secondary">
    <nlf-ui-component-spinner size="3"></nlf-ui-component-spinner>
  </p>
</span>
