<div *ngIf="dataReady">
  <nlf-ors-report-files-thumbnails [filelist]="filelist" size="small"></nlf-ors-report-files-thumbnails>
  <div class="table-responsive">
    <table class="table table-sm table-striped table-hover">
      <tbody>

        <tr *ngFor="let f of filelist">

          <td>
            <span *ngIf="state!='closed'">
              <fa-icon *ngIf="!!f.r" class="text-danger" [icon]="faLock"></fa-icon>
              <fa-icon *ngIf="!f.r" class="text-success" [icon]="faUnlock"></fa-icon>
            </span>
            <!-- <a [routerLink]="['/file/download/', f._id]">{{f.name}}</a> -->
            {{f.name}}
          </td>
          <td>{{ f.size | bytes : 2 }}</td>
          <td>{{f.content_type}}&nbsp;</td>
          <td>
            <a target="_blank" href="{{ f.download }}" class="mx-1"><fa-icon class="pointer" [icon]="faDownload" title="Last ned"></fa-icon></a>
            <!--
            <fa-icon *ngIf="f.size<=10000000" class="pointer" [icon]="faDownload" (click)="download(f._id)" title="Last ned"></fa-icon>
            <a *ngIf="f.size>10000000" target="_blank" [routerLink]="['/api', 'v1', 'files', f._id]"><fa-icon [icon]="faDownload" title="Last ned"></fa-icon></a>
            -->
          </td>
          <td>&nbsp;</td>

        </tr>

      </tbody>
    </table>
  </div>
</div>
<div *ngIf="!dataReady">
  <nlf-ui-component-spinner></nlf-ui-component-spinner>
</div>
