  <!-- WHOS GONNA BE NOTIIED? -->
  <span class="avatar-group avatar-group-sm avatar-group-overlapped mt-10">

    <!-- AVATARS -->
    <span *ngFor="let person_id of persons; let idx = index">
      <div *ngIf="max>idx" class="avatar">
      <nlf-resolve-avatar [person_id]="person_id" [title]="true"></nlf-resolve-avatar>
      </div>
    </span>
    <!-- Max numbers?? -->
    <div *ngIf="show_remaining && persons.length>max" class="avatar pointer" (click)="nextStep()">
      <span [ngbTooltip]="persons.length" class="avatar-text avatar-text-green rounded-circle">
        <span class="initial-wrap"><span>+{{ persons.length-max }}</span></span>
      </span>
    </div>
    <!-- persons modal -->
    <div *ngIf="show_modal && persons.length>0 && persons.length<=max" class="avatar pointer" (click)="openPersonsModal(peopleModalTemplate)">
      <span ngbTooltip="vis liste" class="avatar-text avatar-text-green rounded-circle">
        <span class="initial-wrap"><span><fa-icon [icon]="faList"></fa-icon></span></span>
      </span>
    </div>
    <!--<fa-icon class="pointer" [icon]="envelope-open-o"></fa-icon>-->

    <!-- ADD RECEPIENT
    <div *ngIf="1==4" class="avatar">
      <a href="#">
        <span class="add-new-plus">
          <fa-icon [icon]="faPlus"></fa-icon>
        </span>
      </a>
    </div>
    -->
  </span>


  <ng-template #peopleModalTemplate>

      <div class="modal-header">
        <h4 class="modal-title pull-left">
          {{ modal_title }}
        </h4>
        <button type="button" class="close float-end" aria-label="Close" (click)="modalRef.dismiss()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div *ngFor="let person_id of persons">
          <nlf-resolve-lungo-person [person_id]="person_id" [link]="true" [avatar]="true"></nlf-resolve-lungo-person>
        </div>
      </div>

      <div class="modal-footer">
        <button class="btn btn-outline-dark" (click)="modalRef.dismiss()">Lukk</button>
      </div>
  </ng-template>
