<div *ngIf="!showOnlyBtn" class="row">
  <div class="col-md-3">
    <div class="d-grid gap-2">
      <button class="btn btn-primary" (click)="openModal(modal)">
        <fa-icon [icon]="faMedkit"></fa-icon>Injuries
      </button>
    </div>
  </div>
  <div class="col-md-9">
    <nlf-ors-editor-tag-e5x-render classes="badge bg-danger" [items]="attr.injuryLevel.value" path="E5X.Occurrence.InjuryLevel"></nlf-ors-editor-tag-e5x-render>

  </div>
</div>

<div *ngIf="showOnlyBtn" class="d-grid gap-2">
  <button class="btn btn-secondary" (click)="openModal(modal)">
    <fa-icon [icon]="faMedkit"></fa-icon>Injuries
  </button>
</div>



<!-- INJURIES MODAL -->
<ng-template #modal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      Injuries/Fatalities for Occurrence
    </h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="modalRef.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">

    <div class="row">
      <div class="col-xs-12 col-md-12">

        <div class="row">


          <div class="col-md-12 mb-3">
            <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="attr.injuryLevel.value" path="E5X.Occurrence.InjuryLevel" id="InjuryLevel"></nlf-ors-editor-tag-e5x>
          </div>


          <div class="col-md-4">
            <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="attr.totalSeriousInjuriesGround.value" path="E5X.Occurrence.TotalSeriousInjuriesGround" id="TotalSeriousInjuriesGround"></nlf-ors-editor-tag-e5x>
          </div>

          <div class="col-md-4">
            <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="attr.totalMinorInjuriesGround.value" path="E5X.Occurrence.TotalMinorInjuriesGround" id="TotalMinorInjuriesGround"></nlf-ors-editor-tag-e5x>
          </div>

          <div class="col-md-4">
            <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="attr.totalFatalitiesGround.value" path="E5X.Occurrence.TotalFatalitiesGround" id="TotalFatalitiesGround"></nlf-ors-editor-tag-e5x>
          </div>


        </div>

        <div class="row">

          <div *ngIf="devDebug" class="row">
            AC: {{ attr | json }}
          </div>

        </div>
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button class="btn btn-outline-dark" (click)="modalRef.close()">
      <fa-icon [icon]="faTimes" [fixedWidth]="true"></fa-icon>Avbryt
    </button>
    <button [disabled]="disabled" [ngClass]="{'disabled': disabled}" class="btn btn-success" (click)="modalUpdate(modalIndex)">
      <fa-icon [icon]="faCheck" [fixedWidth]="true"></fa-icon>Oppdater
    </button>
  </div>
</ng-template>
