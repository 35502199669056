<span *ngIf="!!ask" class="pt-2">

  <!-- Badges with letters -->
  <span *ngIf="!verbose">
    <!--!isNeutral(l.key) && -->

    <span *ngFor="let l of askValues">
      <span class="ms-1" [ngClass]="{'badge':true,
      'bg-danger': isNegative(l.key),
      'bg-secondary': isNeutral(l.key),
      'bg-success': isPositive(l.key)}">{{ l.nick }}</span>
    </span>

  </span>

  <!-- Fullblown -->
  <div *ngIf="!!verbose">
    <h4>
      <span *ngFor="let l of askValues">

        <span class="ms-1" [ngClass]="{'badge':true,
      'bg-danger': isNegative(l.key),
      'bg-secondary': isNeutral(l.key),
      'bg-success': isPositive(l.key)}">{{ l.label }}
        </span>

      </span>
    </h4>
    <ul class="list-unstyled">
      <li>
        <span *ngIf="isPositive('knowledge')">
          <strong class="text-success">Gode</strong> kunnskaper hadde innvirkning</span>
        <span *ngIf="isNeutral('knowledge')">Kunnskapen til de involverte hadde
          <strong>ingen</strong> innvirkning</span>
        <span *ngIf="isNegative('knowledge')">
          <strong class="text-danger">Mangelfulle</strong> kunnskaper hadde betydning</span>
      </li>
      <li>
        <span *ngIf="isPositive('skills')">
          <strong class="text-success">Gode</strong> ferdigheter hadde innvirkning</span>
        <span *ngIf="isNeutral('skills')">Ferdighetene til de involverte hadde
          <strong>ingen</strong> innvirkning</span>
        <span *ngIf="isNegative('skills')">
          <strong class="text-danger">Mangelfulle</strong> ferdigheter hadde betydning</span>
      </li>
      <li>
        <span *ngIf="isPositive('attitude')">
          <strong class="text-success">Gode</strong> holdninger hadde innvirkning</span>
        <span *ngIf="isNeutral('attitude')">Holdningene til de involverte hadde
          <strong>ingen</strong> innvirkning</span>
        <span *ngIf="isNegative('attitude')">
          <strong class="text-danger">Dårlige</strong> holdninger hadde betydning</span>
      </li>
    </ul>
  </div>
</span>
