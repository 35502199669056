<span *ngIf="dataReady">
  <span *ngIf="!!link && activity.id !== ''">
    <a [routerLink]="['/activity', activity.id]">{{ activity.name }}</a>
  </span>
  <span *ngIf="!link || activity.id === ''">
    {{ activity.name }}
  </span>
</span>
<span *ngIf="!dataReady">
  <nlf-ui-component-spinner></nlf-ui-component-spinner>
</span>