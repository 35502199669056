<ng-container *ngIf="!!observation">

  <!--
<div class="bg-light">

  <div class="row">

      <div class="d-flex flex-row m-3 align-middle">
          <div class="bg-light rounded-start h1">{{ wx.wind.avg }}</div>
          <div class="d-flex flex-column">
              <div class="p-1 bg-danger small rounded-top">{{ wx.wind.max }}</div>
              <div class="p-1 bg-success small rounded-bottom">{{ wx.wind.min }}</div>
            </div>
      </div>


    Vind: {{ wx.wind.avg }} (max {{ wx.wind.max }} min {{ wx.wind.min }})
    Temperatur: {{ wx.temp.ground }}&deg;C Utsprangshøyde: {{ wx.temp.altitude }}
    Skybase: {{ wx.clouds.base }}Fot
    Mer:
    <wi *ngIf="wx.wind.turbulence" name="tornado"></wi>
    <wi *ngIf="wx.wind.gusting" name="strong-wind"></wi>
    <wi *ngIf="wx.clouds.rain" name="rain"></wi>
    <wi *ngIf="wx.clouds.snow" name="snow"></wi>
    <wi *ngIf="wx.clouds.fog" name="fog"></wi>
    <wi *ngIf="wx.clouds.hail" name="hail"></wi>
    <wi *ngIf="wx.clouds.thunder" name="lightning"></wi>
    Bekrivelse
    <div>{{ wx.description }}</div>
  </div>

  <div class="d-grid gap-2">
    <button (click)="openModal(weatherModal)" class="btn btn-primary my-2">
      <fa-icon [icon]="faCloud"></fa-icon>Beskriv været
    </button>
  </div>
  {{ wx | json }}
</div>
-->
  <div class="row">
    <!-- Wind -->
    <div class="col-xs-12 col-md-4">
      <p class="lead">Vind (knot)</p>
      <div class="form-group">
        <label for="windavg">Gjennomsnitt (kt)</label>
        <input [disabled]="!observation.acl_user.w" class="form-control" type="number" id="windavg"
          (ngModelChange)="debouncedUpdate()" [(ngModel)]="wx.wind.avg">
      </div>
      <div class="form-group">
        <label for="windmax">Max (kt)</label>
        <input [disabled]="!observation.acl_user.w" class="form-control" type="number" id="windmax"
          (ngModelChange)="debouncedUpdate()" [(ngModel)]="wx.wind.max">
      </div>
      <div class="form-group">
        <label for="windmin">Min (kt)</label>
        <input [disabled]="!observation.acl_user.w" class="form-control" type="number" id="windmin"
          (ngModelChange)="debouncedUpdate()" [(ngModel)]="wx.wind.min">
      </div>


      <div class="d-grid mt-2 gap-2">
        <button [disabled]="!observation.acl_user.w" (click)="wx.wind.turbulence=!wx.wind.turbulence;debouncedUpdate()"
          class="btn" [ngClass]="!!wx.wind.turbulence ? 'btn-secondary' : 'btn-outline-dark'" id="turbulence">
          <i class="wi wi-tornado"></i> Turbulens
        </button>
        <button [disabled]="!observation.acl_user.w" (click)="wx.wind.gusting=!wx.wind.gusting;debouncedUpdate()"
          class="btn" [ngClass]="!!wx.wind.gusting ? 'btn-secondary' : 'btn-outline-dark'" id="gusting">
          <i class="wi wi-strong-wind"></i> Gusting
        </button>
      </div>

    </div>
    <!-- Clouds -->
    <div class="col-xs-12 col-md-4">
      <p class="lead">Skybase (fot)</p>
      <div class="form-group">
        <label for="cloudbase">Min (fot)</label>
        <input [disabled]="!observation.acl_user.w" class="form-control" type="number" id="cloudbase"
          (ngModelChange)="debouncedUpdate()" [(ngModel)]="wx.clouds.base">
      </div>

      <div class="d-grid mt-2 gap-2">
        <button [disabled]="!observation.acl_user.w" (click)="wx.clouds.rain=!wx.clouds.rain;debouncedUpdate()"
          class="btn" [ngClass]="!!wx.clouds.rain ? 'btn-secondary' : 'btn-outline-dark'">
          <i class="wi wi-rain"></i> Regn
        </button>
        <button [disabled]="!observation.acl_user.w" (click)="wx.clouds.fog=!wx.clouds.fog;debouncedUpdate()"
          class="btn" [ngClass]="!!wx.clouds.fog ? 'btn-secondary' : 'btn-outline-dark'">
          <i class="wi wi-fog"></i> Tåke
        </button>
        <button [disabled]="!observation.acl_user.w" (click)="wx.clouds.hail=!wx.clouds.hail;debouncedUpdate()"
          class="btn" [ngClass]="!!wx.clouds.hail ? 'btn-secondary' : 'btn-outline-dark'">
          <i class="wi wi-hail"></i> Hagl
        </button>
        <button [disabled]="!observation.acl_user.w" (click)="wx.clouds.snow=!wx.clouds.snow;debouncedUpdate()"
          class="btn" [ngClass]="!!wx.clouds.snow ? 'btn-secondary' : 'btn-outline-dark'">
          <i class="wi wi-snow"></i> Snø
        </button>
        <button [disabled]="!observation.acl_user.w" (click)="wx.clouds.thunder=!wx.clouds.thunder;debouncedUpdate()"
          class="btn" [ngClass]="!!wx.clouds.thunder ? 'btn-secondary' : 'btn-outline-dark'">
          <i class="wi wi-lightning"></i> Torden
        </button>
      </div>
    </div>

    <!-- Temp -->
    <div class="col-xs-12 col-md-4">
      <p class="lead">Temperatur (&deg;C)</p>
      <div class="form-group">
        <label for="tempfground">Bakken</label>
        <input [disabled]="!observation.acl_user.w" class="form-control" type="number" id="tempground"
          (ngModelChange)="debouncedUpdate()" [(ngModel)]="wx.temp.ground">
      </div>
      <div *ngIf="activity=='fallskjerm'" class="form-group">
        <label for="tempexit">Utsprangshøyde</label>
        <input [disabled]="!observation.acl_user.w" class="form-control" type="number" id="tempexit"
          (ngModelChange)="debouncedUpdate()" [(ngModel)]="wx.temp.altitude">
      </div>
    </div>
  </div>

  <!-- Beskrivelse -->
  <div class="row mt-3">
    <div class="col-xs-12 col-md-12">
      <div class="form-group">
        <label class="lead" for="other">Kort beskrivelse av været</label>
        <textarea [disabled]="!observation.acl_user.w" class="form-control" type="text" id="other" name="description"
          (ngModelChange)="debouncedUpdate()" [(ngModel)]="wx.description"></textarea>
      </div>
    </div>
  </div>

</ng-container>

<!-- MODAL -->
<ng-template #weatherModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      Været
    </h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="modalRef.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <!-- Wind -->
      <div class="col-xs-12 col-md-4">
        <p class="lead">Vind (knot)</p>
        <div class="form-group">
          <label for="windavg">Gjennomsnitt (kt)</label>
          <input [disabled]="!observation.acl_user.w" class="form-control" type="number" id="windavg"
            [(ngModel)]="wx.wind.avg">
        </div>
        <div class="form-group">
          <label for="windmax">Max (kt)</label>
          <input [disabled]="!observation.acl_user.w" class="form-control" type="number" id="windmax"
            [(ngModel)]="wx.wind.max">
        </div>
        <div class="form-group">
          <label for="windmin">Min (kt)</label>
          <input [disabled]="!observation.acl_user.w" class="form-control" type="number" id="windmin"
            [(ngModel)]="wx.wind.min">
        </div>


        <div class="d-grid gap-2">
          <button [disabled]="!observation.acl_user.w" (click)="wx.wind.turbulence=!wx.wind.turbulence"
            class="btn" [ngClass]="!!wx.wind.turbulence ? 'btn-secondary' : 'btn-outline-dark'" id="turbulence">
            <i class="wi wi-tornado"></i> Turbulens
          </button>
          <button [disabled]="!observation.acl_user.w" (click)="wx.wind.gusting=!wx.wind.gusting" class="btn"
            [ngClass]="!!wx.wind.gusting ? 'btn-secondary' : 'btn-outline-dark'" id="gusting">
            <i class="wi wi-strong-wind"></i> Gusting
          </button>
        </div>
      </div>
      <!-- Clouds -->
      <div class="col-xs-12 col-md-4">
        <p class="lead">Skybase (fot)</p>
        <div class="form-group">
          <label for="cloudbase">Min (kt)</label>
          <input [disabled]="!observation.acl_user.w" class="form-control" type="number" id="cloudbase"
            [(ngModel)]="wx.clouds.base">
        </div>

        <div class="d-grid gap-2">
          <button [disabled]="!observation.acl_user.w" (click)="wx.clouds.rain=!wx.clouds.rain" class="btn"
            [ngClass]="!!wx.clouds.rain ? 'btn-secondary' : 'btn-outline-dark'">
            <i class="wi wi-rain"></i> Regn
          </button>
          <button [disabled]="!observation.acl_user.w" (click)="wx.clouds.fog=!wx.clouds.fog" class="btn"
            [ngClass]="!!wx.clouds.fog ? 'btn-secondary' : 'btn-outline-dark'">
            <i class="wi wi-fog"></i> Tåke
          </button>
          <button [disabled]="!observation.acl_user.w" (click)="wx.clouds.hail=!wx.clouds.hail" class="btn"
            [ngClass]="!!wx.clouds.hail ? 'btn-secondary' : 'btn-outline-dark'">
            <i class="wi wi-hail"></i> Hagl
          </button>
          <button [disabled]="!observation.acl_user.w" (click)="wx.clouds.snow=!wx.clouds.snow" class="btn"
            [ngClass]="!!wx.clouds.snow ? 'btn-secondary' : 'btn-outline-dark'">
            <i class="wi wi-snow"></i> Snø
          </button>
          <button [disabled]="!observation.acl_user.w" (click)="wx.clouds.thunder=!wx.clouds.thunder"
            class="btn" [ngClass]="!!wx.clouds.thunder ? 'btn-secondary' : 'btn-outline-dark'">
            <i name="wi wi-lightning"></i> Torden
          </button>
        </div>
      </div>

      <!-- Temp -->
      <div class="col-xs-12 col-md-4">
        <p class="lead">Temperatur (&deg;C)</p>
        <div class="form-group">
          <label for="tempfground">På bakken (&deg;C)</label>
          <input [disabled]="!observation.acl_user.w" class="form-control" type="number" id="tempground"
            [(ngModel)]="wx.temp.ground">
        </div>
        <div *ngIf="!!activity && activity==='fallskjerm'" class="form-group">
          <label for="tempexit">På utsprangshøyde (&deg;C)</label>
          <input [disabled]="!observation.acl_user.w" class="form-control" type="number" id="tempexit"
            [(ngModel)]="wx.temp.altitude">
        </div>
      </div>
    </div>

    <!-- Beskrivelse -->
    <div class="row mt-3">
      <div class="col-xs-12 col-md-12">
        <div class="form-group">
          <label for="other">Kort beskrivelse av været</label>
          <textarea [disabled]="!observation.acl_user.w" class="form-control" type="text" id="other" name="description"
            [(ngModel)]="wx.description"></textarea>
        </div>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-dark" (click)="modalRef.close()">
      <fa-icon [icon]="faClose"></fa-icon>Avbryt
    </button>
    <button [disabled]="!observation.acl_user.w" class="btn btn-secondary" (click)="closeModal()">
      <fa-icon [icon]="faCheck" [fixedWidth]="true"></fa-icon>Oppdater
    </button>
  </div>
</ng-template>
