<header class="nlf-sticky-toolbar">
  <div class="container lead">
    NLF OBSREG

    <!--
    <button [routerLink]="['/ors', 'fallskjerm', 'search']" type="button" class="btn btn-sm btn-primary float-end mx-1">
      <fa-icon [icon]="faSearch""></fa-icon> Søk
    </button>

    <button *ngIf="!!user_settings && user_settings?.settings?.default_activity==109" [routerLink]="['/ors', 'fallskjerm', 'dashboard', user_settings?.settings?.default_discipline]" type="button" class="btn btn-sm btn-primary float-end mx-1">
      <fa-icon [icon]="faDashboard""></fa-icon> Dashboard
    </button>

    <nlf-ors-go activity="fallskjerm" class="float-end mx-1" title="Hurtigvalg, skriv inn OBSREG nummer etterfulgt av enter for å gå til OBSREG"></nlf-ors-go>

    <nlf-help class="float-end mx-1" key="ors-fallskjerm"></nlf-help>

  -->

  </div>


</header>

<!-- CARD DECK -->
<div class="container">
  <div class="row mt-3">
    <div class="col-md-12">
      <div class="row mb-3">
        <!--<nlf-user-profile></nlf-user-profile>-->
        <!--
        <div class="card">
          <div class="card-header">Opprett Observasjon</div>

         <div class="card-body">
            <span class="text-center">
              <nlf-ors-create></nlf-ors-create>
            </span>
          </div>
          <div class="card-footer">
            <div class="d-grid gap-2">
              <a class="btn btn-primary" [routerLink]="['/user/profile']" role="button">Oppdater profil &raquo;</a>
            </div>
          </div>
        </div>-->
        <!-- END USER OR OBSREG -->
        <div class="col-md-3">
          <div class="card mb-3 obsreg-card">
            <div class="card-header"><i class="nlf nlf-motorfly nlf-fw"></i> Motorfly OBSREG</div>

            <nlf-ors-last activity="motorfly" number="8">Ingen registrerte</nlf-ors-last>

            <div class="card-footer">
              <div class="d-grid gap-2">
                <a class="btn btn-secondary" [routerLink]="['/ors', 'motorfly']" role="button">Motorfly OBSREG
                  &raquo;</a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card mb-3 obsreg-card">
            <div class="card-header">
              <i class="nlf nlf-fallskjerm nlf-fw"></i> Fallskjerm OBSREG
            </div>
            <nlf-ors-last activity="fallskjerm" number="8">Ingen registrerte</nlf-ors-last>
            <div class="card-footer">
              <div class="d-grid gap-2">
                <a class="btn btn-secondary" [routerLink]="['/ors', 'fallskjerm']" role="button">Fallskjerm OBSREG
                  &raquo;</a>
                </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card mb-3 obsreg-card">
            <div class="card-header">
              <i class="nlf nlf-mikrofly nlf-fw"></i> Sportsfly OBSREG
            </div>
            <nlf-ors-last activity="sportsfly" number="8">Ingen registrerte</nlf-ors-last>
            <div class="card-footer">
              <div class="d-grid gap-2">
                <a class="btn btn-secondary" [routerLink]="['/ors', 'sportsfly']" role="button">Sportsfly OBSREG
                  &raquo;</a>
                </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card mb-3 obsreg-card">
            <div class="card-header">
              <i class="nlf nlf-seilfly nlf-fw"></i> Seilfly OBSREG
            </div>
            <nlf-ors-last activity="seilfly" number="8">Ingen registrerte</nlf-ors-last>
            <div class="card-footer">
              <div class="d-grid gap-2">
                <a class="btn btn-secondary" [routerLink]="['/ors', 'seilfly']" role="button">Seilfly OBSREG
                  &raquo;</a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card mb-3 obsreg-card">
            <div class="card-header">
              <i class="nlf nlf-modell nlf-fw"></i> Modellfly OBSREG
            </div>
            <nlf-ors-last activity="modellfly" number="8">Ingen registrerte</nlf-ors-last>
            <div class="card-footer">
              <div class="d-grid gap-2">
                <a class="btn btn-secondary" [routerLink]="['/ors', 'modellfly']" role="button">Modellfly OBSREG
                  &raquo;</a>
              </div>
            </div>
          </div>
        </div>
        <!-- /CARD DECK -->

      </div>

    </div>
  </div>
</div>

