<div class="modal-header">
  <h4 class="modal-title">
    {{ observation._model.type | capitalize }} {{config[observation._model.type]['observation']['app_name']}} #{{ observation.id }}
    <span *ngIf="ENV._name!='prod'" class="badge bg-danger">{{ ENV._name }}</span>
  </h4>

  <button type="button" class="close float-end" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <dl class="row">
    <dt class="col-sm-3">Type</dt>
    <dd class="col-sm-9">
      <nlf-resolve-observation-types [activity]="observation._model.type" [type]="observation.type" badge="true"></nlf-resolve-observation-types>
    </dd>

    <dt class="col-sm-3">Status</dt>
    <dd class="col-sm-9">
      <nlf-resolve-observation-state [activity]="observation._model.type" [state]="observation.workflow.state" icon="true"></nlf-resolve-observation-state>
    </dd>

    <!-- Fra og med versjon 3 har vi disse for motor: -->
    <ng-container *ngIf="observation._model.type==='motorfly' && observation._model.version>=3">
      <dt class="col-sm-3">E5X Type</dt>
      <dd class="col-sm-9">
        <span *ngIf="observation.occurrence.entities.reportingHistory[0].attributes.reportingEntity.value===101311">PRIVAT</span>
        <span *ngIf="observation.occurrence.entities.reportingHistory[0].attributes.reportingEntity.value===100479">DTO</span>
      </dd>

      <dt class="col-sm-3">Annet</dt>
      <dd class="col-sm-9">
        <div *ngIf="observation.workflow.settings.do_not_process_in_club"><fa-icon [icon]="faBan" class="text-danger"></fa-icon> Behandles ikke i klubb</div>
        <div *ngIf="!observation.workflow.settings.do_not_process_in_club"><fa-icon [icon]="faCheck" class="text-success"></fa-icon> Kan behandles i klubb</div>
        <div *ngIf="observation.workflow.settings.do_not_publish"><fa-icon [icon]="faBan" class="text-danger"></fa-icon> Ikke offentlig når lukket</div>
        <div *ngIf="!observation.workflow.settings.do_not_publish"><fa-icon [icon]="faCheck" class="text-success"></fa-icon> Offentlig når lukket</div>
      </dd>
      <!--
      <span *ngIf="observation.workflow.settings.do_not_process_in_club">Behandles ikke i klubb</span>
    -->
    </ng-container>



    <dt class="col-sm-3">Opprettet</dt>
    <dd class="col-sm-9">
      <fa-icon [icon]="faClock" [fixedWidth]="true"></fa-icon> {{ observation._created | amTimeAgo }}, {{ observation._created | date:'yyyy-MM-dd HH:mm' }}
    </dd>

    <dt class="col-sm-3">Sist endret</dt>
    <dd class="col-sm-9">
      <fa-icon [icon]="faClock" [fixedWidth]="true"></fa-icon> {{ observation._updated | amTimeAgo }}, {{ observation._updated | date:'yyyy-MM-dd HH:mm' }}
    </dd>


    <dt class="col-sm-3">Observatør</dt>
    <dd class="col-sm-9">
      <nlf-resolve-lungo-person [person_id]="observation.reporter" [link]="true" [avatar]="true"></nlf-resolve-lungo-person>
    </dd>

    <ng-container *ngIf="observation.organization.hasOwnProperty('hi')">
      <dt class="col-sm-3">HI</dt>
      <dd class="col-sm-9">
        <nlf-resolve-lungo-person *ngFor="let hi of observation.organization.hi" [person_id]="hi" [link]="true" [avatar]="true"></nlf-resolve-lungo-person>
      </dd>
    </ng-container>

    <ng-container *ngIf="observation.organization.hasOwnProperty('fsj')">
      <dt class="col-sm-3">Fagsjef</dt>
      <dd class="col-sm-9">
        <div *ngFor="let fsj of observation.organization.fsj">
          <nlf-resolve-lungo-person [person_id]="fsj" [link]="true" [avatar]="true"></nlf-resolve-lungo-person>
        </div>
      </dd>
    </ng-container>
    <ng-container *ngIf="observation.organization.hasOwnProperty('club_president')">
      <dt class="col-sm-3">Leder i klubb</dt>
      <dd class="col-sm-9">
        <div *ngFor="let leder of observation.organization.club_president">
          <nlf-resolve-lungo-person [person_id]="leder" [link]="true" [avatar]="true"></nlf-resolve-lungo-person>
        </div>
      </dd>
    </ng-container>

    <ng-container *ngIf="observation.organization.hasOwnProperty('ftl')">
      <dt *ngIf="observation._model.type=='motorfly'" class="col-sm-3">FTL i klubb</dt>
      <dd *ngIf="observation._model.type=='motorfly'" class="col-sm-9">
        <div *ngFor="let ftl of observation.organization.ftl">
          <nlf-resolve-lungo-person [person_id]="ftl" [link]="true" [avatar]="true"></nlf-resolve-lungo-person>
        </div>
      </dd>
    </ng-container>
    <ng-container *ngIf="observation.organization.hasOwnProperty('dto')">
      <dt class="col-sm-3">DTO i klubb</dt>
      <dd class="col-sm-9">
        <div *ngFor="let dto of observation.organization.dto">
          <nlf-resolve-lungo-person [person_id]="dto" [link]="true" [avatar]="true"></nlf-resolve-lungo-person>
        </div>
      </dd>
    </ng-container>
    <ng-container *ngIf="observation.organization.hasOwnProperty('ors')">
      <dt class="col-sm-3">OBSREG-koordinator</dt>
      <dd class="col-sm-9">
        <div *ngFor="let ors of observation.organization.ors">
          <nlf-resolve-lungo-person [person_id]="ors" [link]="true" [avatar]="true"></nlf-resolve-lungo-person>
        </div>
      </dd>
    </ng-container>
    <dt class="col-sm-3">Version</dt>
    <dd class="col-sm-9">
      {{ observation._version }}
    </dd>
    <dt class="col-sm-3">Klubb</dt>
    <dd class="col-sm-9">
      <nlf-resolve-lungo-organization [organization_id]="observation.club" show_activity="true" link="true" long="true"></nlf-resolve-lungo-organization>
    </dd>
    <dt class="col-sm-3">Gren</dt>
    <dd class="col-sm-9">
      <nlf-resolve-lungo-organization [organization_id]="observation.discipline" show_activity="true" link="true" long="true"></nlf-resolve-lungo-organization>
    </dd>

    <dt class="col-sm-3">Tilgang</dt>
    <dd class="col-sm-9">

      <dl *ngIf="!!observation.acl_user" class="row">

        <dt class="col-sm-4">
          <fa-icon [icon]="observation.acl_user.r ? faCheck : faBan" [ngClass]="observation.acl_user.r ? 'text-success' : 'text-danger'"></fa-icon> Read
        </dt>
        <dd class="col-sm-8">
          <span *ngIf="!!acl_list">
            <nlf-resolve-avatars [persons]="acl_list['read']||[]" max="5"></nlf-resolve-avatars>
          </span>
        </dd>

        <dt class="col-sm-4">
          <fa-icon [icon]="observation.acl_user.w ? faCheck : faBan" [ngClass]="observation.acl_user.w ? 'text-success' : 'text-danger'"></fa-icon> Write
        </dt>
        <dd class="col-sm-8">
          <span *ngIf="!!acl_list">
            <nlf-resolve-avatars [persons]="acl_list['write']||[]" max="5"></nlf-resolve-avatars>
          </span>
        </dd>

        <dt class="col-sm-4">
          <fa-icon [icon]="observation.acl_user.x ? faCheck : faBan" [ngClass]="observation.acl_user.x ? 'text-success' : 'text-danger'"></fa-icon> Execute
        </dt>
        <dd class="col-sm-8">
          <span *ngIf="!!acl_list">
            <nlf-resolve-avatars [persons]="acl_list['execute']||[]" max="5"></nlf-resolve-avatars>
          </span>
        </dd>

        <dt class="col-sm-4">
          <fa-icon [icon]="observation.acl_user.d ? faCheck : faBan" [ngClass]="observation.acl_user.d ? 'text-success' : 'text-danger'"></fa-icon> Delete
        </dt>
        <dd class="col-sm-8">
          <span *ngIf="!!acl_list">
            <nlf-resolve-avatars [persons]="acl_list['delete']||[]" max="5"></nlf-resolve-avatars>
          </span>
        </dd>

      </dl>


    </dd>

    <dt class="col-sm-3">{{config[observation._model.type]['observation']['app_name']}} Modell</dt>
    <dd class="col-sm-9">
      <dl class="row">
        <dt class="col-sm-4">Type</dt>
        <dd class="col-sm-8">{{ observation._model.type}}</dd>
        <dt class="col-sm-4">Versjon</dt>
        <dd class="col-sm-8">{{ observation._model.version}}</dd>
        <dt class="col-sm-4">Id</dt>
        <dd class="col-sm-8">{{ observation._id}}</dd>
      </dl>
    </dd>

  </dl>

  <!--
  <div (click)="isWorkflowTimelineCollapsed = !isWorkflowTimelineCollapsed" class="card-header pointer">
    <fa-icon [icon]="faRandom"></fa-icon>
    Arbeidsflyt
    <i class="fa fa-fw" [ngClass]="{'fa-angle-down': isWorkflowTimelineCollapsed, 'fa-angle-up': !isWorkflowTimelineCollapsed}"></i>
  </div>
  <div [ngbCollapse]="!isWorkflowTimelineCollapsed">
    <nlf-ors-report-workflow-timeline [activity]="observation._model.type" [_id]="observation._id" [workflow]="observation.workflow"></nlf-ors-report-workflow-timeline>
  </div>
  -->

</div>
<div class="modal-footer">
  <button class="btn btn-outline-dark" (click)="activeModal.close()">Ferdig</button>
</div>
