<div class="row">
  <div class="col-md-12">
    <div class="d-grid gap-2">
      <button [disabled]="disabled" class="btn btn-primary" (click)="add();openModal(modal, modalIdx)">
        <fa-icon [icon]="faPlus"></fa-icon>ATM Involvement
      </button>
    </div>
  </div>


  <div class="col-md-9">
    <div *ngFor="let a of atm; let idx = index">
      <nlf-ors-editor-tag-e5x-render classes="badge bg-danger" [items]="a.attributes.anspName.value"
        path="E5X.Occurrence.AirNavigationService.ANSPName"></nlf-ors-editor-tag-e5x-render>
      &nbsp;
      <fa-icon class="mx-1" [icon]="faEdit" (click)="openModal(modal, idx)"></fa-icon>
      <fa-icon class="mx-1" *ngIf="!disabled" [icon]="faClose" (click)="delete(idx)"></fa-icon>
    </div>

  </div>
</div>

<!-- ATM MODAL -->
<ng-template #modal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      ATM involment
    </h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="modalRef.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">



    <div class="row">

      <p class="lead">Valgene er ikke aktiverte</p>
      <!-- TEST !DISABLED!
      NB Testing
      <div class="col-md-6">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" path="E5X.Occurrence.ATMContribution"></nlf-ors-editor-tag-e5x>
      </div>
      <div class="col-md-6">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" path="E5X.Occurrence.EffectOnATMService"></nlf-ors-editor-tag-e5x>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="modalValue.attributes.anspName.value"
          path="E5X.Occurrence.AirNavigationService.ANSPName" id="ANSPName"></nlf-ors-editor-tag-e5x>
      </div>
      -->

      <!--
                    <div class="col-md-6">
                        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="modalValue.attributes.atmContribution.value" path="E5X.Occurrence.ATMContribution"
                            id="ATMContribution"></nlf-ors-editor-tag-e5x>
                    </div>

                    <div class="col-md-6">
                        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="modalValue.attributes.effectOnATMService.value" path="E5X.Occurrence.EffectOnATMService"
                            id="EffectOnATMService"></nlf-ors-editor-tag-e5x>
                    </div>
                  -->

      <!-- !DISABLED!
    </div>

    <div class="row">
      <div class="col-md-4">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="modalValue.attributes.apwAlerting.value"
          path="E5X.Occurrence.AirNavigationService.APWAlerting" id="SectorName"></nlf-ors-editor-tag-e5x>
      </div>
      <div class="col-md-3">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="modalValue.attributes.rimcasAlerting.value"
          path="E5X.Occurrence.AirNavigationService.RIMCASAlerting" id="RTFFrequency"></nlf-ors-editor-tag-e5x>
      </div>
      <div class="col-md-5">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="modalValue.attributes.stcaCurrentAlerting.value"
          path="E5X.Occurrence.AirNavigationService.STCACurrentAlerting" id="WorkloadController">
        </nlf-ors-editor-tag-e5x>
      </div>

    </div>

    <div class="row">

      <div *ngIf="devDebug" class="row">
        AC: {{ atm | json }}
      </div>
-->
    </div>

  </div>


  <div class="modal-footer">
    <button class="btn btn-outline-dark" (click)="modalRef.close()">
      <fa-icon [icon]="faTimes" [fixedWidth]="true"></fa-icon>Avbryt
    </button>
    <!-- !DISABLED!
    <button [disabled]="disabled" [ngClass]="{'disabled': disabled}" class="btn btn-success" (click)="modalUpdate()">
      <fa-icon [icon]="faCheck" [fixedWidth]="true"></fa-icon>Oppdater
    </button>
    -->
  </div>
</ng-template>