<div *ngIf="!!weather" class="row px-2 pt-2">

  <div class="col-xs-4 col-md-4">
    <dl class="dl-horizontal">
      <dt>
        <i class="wi wi-fw wi-strong-wind"></i> Vind</dt>
      <dd></dd>
      <div *ngIf="!!weather.manual && !!weather.manual.wind">
        <div *ngIf="!!weather.manual?.wind?.max">
          <dt>Maks:</dt>
          <dd>{{ weather.manual.wind.max }} knop</dd>
        </div>
        <div *ngIf="!!weather.manual?.wind?.avg">
          <dt>Snitt:</dt>
          <dd>{{ weather.manual.wind.avg }} knop</dd>
        </div>
        <div *ngIf="!!weather.manual?.wind?.min">
          <dt>Min:</dt>
          <dd>{{ weather.manual.wind.min }} knop</dd>
        </div>
        <div *ngIf="!!weather.manual?.wind?.turbulence">
          <dt>Turbulens:</dt>
          <dd>{{ weather.manual.wind.tubulence ? 'JA' : 'NEI' }}</dd>
        </div>
        <div *ngIf="!!weather.manual?.wind?.gusting">
          <dt>Gusting:</dt>
          <dd>{{ weather.manual.wind.gusting ? 'JA' : 'NEI' }}</dd>
        </div>
      </div>
    </dl>
  </div>

  <div class="col-xs-4  col-md-4">
    <dl class="dl-horizontal">
      <dt>
        <i class="wi wi-fw wi-cloudy"></i> Skyer</dt>
      <dd></dd>
      <div *ngIf="!!weather.manual && !!weather.manual.clouds">
        <div *ngIf="!!weather.manual?.clouds?.base">
          <dt>Skybase:</dt>
          <dd>{{ weather.manual.clouds.base }} fot</dd>
        </div>
        <div *ngIf="!!weather.manual?.clouds?.rain">
          <dt>Regn:</dt>
          <dd>{{ weather.manual.clouds.rain ? 'JA' : 'NEI' }}</dd>
        </div>
        <div *ngIf="!!weather.manual?.clouds?.fog">
          <dt>Tåke:</dt>
          <dd>{{ weather.manual.clouds.fog ? 'JA' : 'NEI' }}</dd>
        </div>
        <div *ngIf="!!weather.manual?.clouds?.hail">
          <dt>Hagl:</dt>
          <dd>{{ weather.manual.clouds.hail ? 'JA' : 'NEI' }}</dd>
        </div>
        <div *ngIf="!!weather.manual?.clouds?.snow">
          <dt>Snø:</dt>
          <dd>{{ weather.manual.clouds.snow ? 'JA' : 'NEI' }}</dd>
        </div>
        <div *ngIf="!!weather.manual?.clouds?.thunder">
          <dt>Torden:</dt>
          <dd>{{ weather.manual.clouds.thunder ? 'JA' : 'NEI' }}</dd>
        </div>
      </div>
    </dl>
  </div>

  <div class="col-xs-4  col-md-4">
    <dl class="dl-horizontal">
      <dt>
        <i class="wi wi-fw wi-thermometer-exterior"></i> Temperatur</dt>
      <dd></dd>
      <div *ngIf="!!weather.manual && !!weather.manual?.temp">
        <div *ngIf="!!weather.manual?.temp?.ground">
          <dt>Bakken:</dt>
          <dd>{{ weather.manual.temp.ground }}&deg;C</dd>
        </div>
        <div *ngIf="!!weather.manual?.temp?.altitude">
          <dt>Høyden:</dt>
          <dd>{{ weather.manual.temp.altitude }}&deg;C</dd>
        </div>
      </div>

    </dl>
  </div>

  <div class="col-xs-12  col-md-12">
    <div *ngIf="!!weather.manual?.description">
      <strong>
        <fa-icon [icon]="faCommenting" class="me-2"></fa-icon>{{ weather.manual.description }}
      </strong>
    </div>
    <div class="clearfix"></div>
  </div>

  <!-- AUTO FROM HERE -->

  <div class="col-md-8 col-xs-12 p-3">
    <p class="lead">Auto</p>
    <div class="p-3" *ngIf="!!weather.auto?.metar_nearest?.parsed" [innerHTML]="weather.auto.metar_nearest.parsed | newlines"></div>
  </div>

  <div class="col-md-4 col-xs-12 p-3 align-text-bottom">
    <div class="d-grid gap-2">
      <button (click)="openModal(autoModal, 'metar')" [ngClass]="!!weather.auto?.metar ? 'btn-success': 'btn-secondary'" class="btn my-2">METAR</button>
    </div>
    <div class="d-grid gap-2">
      <button (click)="openModal(autoModal, 'taf')" [ngClass]="!!weather.auto?.taf ? 'btn-success': 'btn-secondary'" class="btn my-2">TAF</button>
    </div>
    <!--
      <div class="d-grid gap-2">
        <button (click)="openModal(autoModal, 'yr')" [ngClass]="!!weather.auto.yr ? 'btn-success': 'btn-secondary'" class="btn my-1">YR</button>
      </div>
    -->
  </div>

</div>

<ng-template #autoModal>

  <div class="modal-header">
    <h4 class="modal-title pull-left">
      {{ modal.title | upperfirst }}
    </h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="modalRef.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="col-xs-12 overflow-auto">
      <div *ngFor="let row of modal.data">
        {{ row }}
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button (click)="modalRef.dismiss()" class="btn btn-outline-dark">Ferdig</button>
  </div>
</ng-template>
