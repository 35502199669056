<div *ngIf="modal && !showOnlyBtn" class="row">
  <div class="col-md-3">
    <div class="d-grid gap-2">
      <button class="btn btn-primary" (click)="openModal(attrModal)">
        <fa-icon [icon]="faMapMarker"></fa-icon> Where
      </button>
    </div>
  </div>
  <div class="col-md-9">
    <div *ngFor="let a of atm; let idx = index">
      <nlf-ors-editor-tag-e5x-render classes="badge bg-danger" [items]="a.attributes.anspName.value"
        path="E5X.Occurrence.AirNavigationService.ANSPName"></nlf-ors-editor-tag-e5x-render>
      &nbsp;
      <fa-icon [icon]="faEdit" (click)="openModal(modal, idx)"></fa-icon>
      <fa-icon [icon]="faClose" (click)="delete(idx)"></fa-icon>
    </div>
  </div>
</div>

<div *ngIf="modal && showOnlyBtn" class="row d-grid gap-2">
  <button class="btn btn-secondary" (click)="openModal(attrModal)">
    <fa-icon [icon]="faMapMarker"></fa-icon> Where
  </button>
</div>

<ng-template #attrModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      Occurred Where
    </h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="modalRef.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <!--<ng-container *ngTemplateOutlet="attrTemplate"></ng-container>-->
    <!-- Modal content -->
    <div class="col-xs-12 position-relative">
      <div class="row" style="height: 400px">

        <nlf-ors-editor-e5x-where-map [aircraft]="observation.aircrafts"
          [(lat)]="observation.occurrence.attributes.latitudeOfOcc.value"
          [(lng)]="observation.occurrence.attributes.longitudeOfOcc.value" [disabled]="!observation.acl_user.w"
          (change)="debouncedUpdate()"></nlf-ors-editor-e5x-where-map>
      </div>

    </div>

    <div class="row">
      <div class="col-md-6">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="observation.occurrence.attributes.latitudeOfOcc.value" (change)="debouncedUpdate()"
          path="E5X.Occurrence.LatitudeOfOcc" id="latitudeOfOcc"></nlf-ors-editor-tag-e5x>
      </div>
      <div class="col-md-6">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="observation.occurrence.attributes.longitudeOfOcc.value" (change)="debouncedUpdate()"
          path="E5X.Occurrence.LongitudeOfOcc" id="longitudeOfOcc"></nlf-ors-editor-tag-e5x>
      </div>
      <!--
      <div class="col-md-4">
        <button *ngIf="!!userGeo" class="btn btn-primary" (click)="useMyLocation()"><fa-icon [icon]="faLocation" [fixedWidth]="true"></fa-icon> Bruk min posisjon</button>
      </div>
      -->

    </div>

    <div class="row">
      <div class="col-md-12">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" (change)="debouncedUpdate()" [(items)]="observation.occurrence.attributes.locationName.value"
          path="E5X.Occurrence.LocationName" id="locationName"></nlf-ors-editor-tag-e5x>
      </div>

      <div class="col-md-12">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" (change)="debouncedUpdate()" [(items)]="observation.occurrence.attributes.stateAreaOfOcc.value"
          path="E5X.Occurrence.StateAreaOfOcc" id="stateAreaOfOcc"></nlf-ors-editor-tag-e5x>
      </div>
    </div>
    <div class="row clearfix">
    </div>

    <div class="row">
      <div *ngIf="devDebug" class="row">
        AC: {{ atm | json }}
      </div>
    </div>
    <!-- //Modal content -->

  </div>

  <div class="modal-footer">


    <button class="btn btn-outline-dark" (click)="modalRef.close()">
      <fa-icon [icon]="faTimes" [fixedWidth]="true"></fa-icon>Avbryt
    </button>
    <button [disabled]="disabled" [ngClass]="{'disabled': disabled}" class="btn btn-success"
      (click)="modalUpdate()">
      <fa-icon [icon]="faCheck" [fixedWidth]="true"></fa-icon>Oppdater
    </button>
  </div>
</ng-template>
