<span class="badge bg-secondary pointer mx-1" (click)="openModal(modal)">
  <fa-icon [icon]="faLock"></fa-icon> Tilgang
</span>


<!-- ROUTE MODAL -->
<ng-template #modal>
  <div class="modal-header">
    <h4 class="modal-title">
      <fa-icon [icon]="faLock"></fa-icon> Tilgangskontroll
    </h4>

    <button type="button" class="close float-end" aria-label="Close" (click)="modalRef.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

  <ngb-alert *ngIf="!!message" [type]="message.type" (close)="message = undefined">{{ message.msg }}</ngb-alert>


    <div class="row">
      <div class="col-sm-6"></div>
      <div class="col-sm-3">read</div>
      <div class="col-sm-3">write</div>
    </div>

    <div class="row" *ngFor="let person_id of acl_persons; let idx = index; odd as isOdd"  [class.bg-light]="isEven">

      <div class="col-sm-6">
        <nlf-resolve-lungo-person [person_id]="person_id" [link]="true" [avatar]="true"></nlf-resolve-lungo-person><fa-icon *ngIf="acl_list['execute'].indexOf(person_id)>-1" class="ms-2 text-danger" [icon]="faLock"></fa-icon>
      </div>
      <div class="col-sm-3">
        <div class="form-check">
          <input (click)="toggleAcl(person_id, 'read', $event)" class="form-check-input" [disabled]="acl_list['execute'].indexOf(person_id)>-1" type="checkbox" [checked]="acl_list['read'].indexOf(person_id)>-1 ? true : false" id="eeew">
          <label class="form-check-label" for="eeew">
            read
          </label>
        </div>
      </div>

      <div class="col-sm-3">
        <div class="form-check">
          <input (click)="toggleAcl(person_id, 'write', $event)" class="form-check-input" [disabled]="acl_list['execute'].indexOf(person_id)>-1" type="checkbox" [checked]="acl_list['write'].indexOf(person_id)>-1 ? true : false" id="write">
          <label class="form-check-label" for="write">
            write
          </label>
        </div>
      </div>
    </div>

    <div class="clearfix my-3"></div>

    <div class="row my-3">
      <div class="col-sm-10">
        <nlf-ors-editor-tag-person [(person)]="new_person" [activity]="model" [fc]="true" [allowAdd]="false" placeholder="Søk etter person"></nlf-ors-editor-tag-person>
      </div>
      <div class="col-sm-2">
        <button (click)="addPerson()" class="btn btn-secondary">
          <fa-icon [icon]="faPlus"></fa-icon> Legg til
        </button>
      </div>
      <!--
        <div class="input-group m-3 me-auto col-sm-12">
          <nlf-ors-editor-tag-person [(person)]="new_person" [activity]="model" [fc]="true" [allowAdd]="false"></nlf-ors-editor-tag-person>
          <span class="input-group-text" id="basic-addon2"><fa-icon [icon]="faPlus"></fa-icon> Legg til</span>
        </div>
      -->
    </div>
  </div>
  <div class="modal-footer">
    <!--<button class="btn btn-outline-dark me-auto" (click)="modalRef.dismiss()">Avbryt</button>-->
    <button class="btn btn-outline-dark" (click)="modalRef.close()">
      <fa-icon [icon]="faSave"></fa-icon> Ferdig
    </button>
  </div>
</ng-template>
