<div *ngIf="!!dataReady && !!space && !error">
  <div class="nlf-sticky-toolbar">
    <div class="container">
      <fa-icon [icon]="faSitemap"></fa-icon>
      <a [routerLink]="['/content']">Spaces</a> / <a [routerLink]="['/content', space.space_key, space._id]">{{ space.title }}</a>
      <sup class="text-danger mx-1" title="Applikasjonen er ikke ferdig utviklet">beta</sup>
    </div>
  </div>
</div>
<div class="container overflow-auto">

  <ngx-table [id]="'content-space-table'" [configuration]="tableSpaces" [data]="spacelist" [columns]="columns" [(pagination)]="pagination" (event)="eventEmitted($event)">

    <ng-template let-row>
      <td class="ngx-easy-table-small">

        <span *ngIf="!!row.parent">
          <fa-icon [icon]="faFile"></fa-icon>
        </span>
        <span *ngIf="!row.parent">
          <fa-icon [icon]="faSitemap"></fa-icon>
        </span>
        <a [routerLink]="['/content','space', row.space_key, row.slug]">{{ row.title }}</a>

      </td>
      <td class="ngx-easy-table-small">
        {{ row._updated | amTimeAgo }}
      </td>
      <td class="ngx-easy-table-small">
        <nlf-resolve-lungo-person [person_id]="row.owner" [link]="true" [avatar]="true"></nlf-resolve-lungo-person>
      </td>
      <td>
        <span class="pointer" (click)="delete(row)">
          <fa-icon [icon]="faClose"></fa-icon>
        </span>
        <span class="pointer" [routerLink]="['/content/edit/', row._id]">
          <fa-icon [icon]="faEdit"></fa-icon>
        </span>
        <span class="pointer" [routerLink]="['/content/create/', row._id]">
          <fa-icon [icon]="faPlus"></fa-icon>
        </span>

        <span *ngIf="!row?.published" class="pointer" (click)="publish(row._id)">
          <fa-icon [icon]="faBan"></fa-icon>
        </span>
        <span *ngIf="!!row?.published" class="pointer text-success" (click)="unpublish(row._id)">
          <fa-icon [icon]="faCheck"></fa-icon>
        </span>

      </td>
    </ng-template>
  </ngx-table>

  <span *ngIf="!dataReady && !error">
    <p class="align-middle text-center  text-secondary">
      <nlf-ui-component-spinner size="3"></nlf-ui-component-spinner>
    </p>
  </span>

  <span *ngIf="error">
    <p class="align-middle text-center  text-secondary">
      ERROR, please go back to <a [routerLink]="['/content']">content home</a>
    </p>
  </span>

</div>
