<div class="row">
  <div class="col-md-3">
    <div class="d-grid gap-2">
      <button [disabled]="disabled" class="btn btn-primary" (click)="add();openModal(modal, modalIdx)">
        <fa-icon [icon]="faPlus" [fixedWidth]="true"></fa-icon>Add Event
      </button>
    </div>
  </div>
  <div class="col-md-9">
    <div *ngFor="let event of events; let idx = index">
      <nlf-ors-editor-tag-e5x-render classes="badge bg-danger" [items]="event.attributes.eventType.value" path="E5X.Occurrence.Events.EventType"></nlf-ors-editor-tag-e5x-render>
      &nbsp;
      <nlf-ors-editor-tag-e5x-render classes="badge bg-danger" [items]="event.attributes.phase.value" path="E5X.Occurrence.Events.Phase"></nlf-ors-editor-tag-e5x-render>

      <fa-icon class="mx-1" [icon]="faEdit" (click)="openModal(modal, idx)"></fa-icon>
      <fa-icon class="mx-1" *ngIf="!disabled" [icon]="faClose" (click)="delete(idx)"></fa-icon>
    </div>

  </div>
</div>

<!-- Event MODAL -->
<ng-template #modal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      Event
    </h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="modalRef.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">

    <div class="row">
      <div class="col-xs-12 col-md-12">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="modalValue.attributes.eventType.value" path="E5X.Occurrence.Events.EventType" id="EventType"></nlf-ors-editor-tag-e5x>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-md-12">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="modalValue.attributes.phase.value" path="E5X.Occurrence.Events.Phase" id="Phase"></nlf-ors-editor-tag-e5x>
      </div>
    </div>
    <div *ngIf="devDebug" class="row">
      AC: {{ events | json }}
    </div>

  </div>


  <div class="modal-footer">
    <button class="btn btn-outline-dark" (click)="modalRef.close()">
      <fa-icon [icon]="faTimes" [fixedWidth]="true"></fa-icon>Avbryt
    </button>
    <button [disabled]="disabled" [ngClass]="{'disabled': disabled}" class="btn btn-success" (click)="modalUpdate()">
      <fa-icon [icon]="faCheck" [fixedWidth]="true"></fa-icon>Oppdater
    </button>
  </div>
</ng-template>
