<span *ngIf="config">
  <span
  *ngIf="badge"
  class="badge bg-{{ config[activity].observation.state[state]['badge'] }}"
  placement="bottom"
  container="body"
  [ngbTooltip]="config[activity].observation.state[state]['descr']">
    <fa-icon *ngIf="!!icon" [icon]="icons[config[activity].observation.state[state]['icon']]"></fa-icon>
    {{ state | nlfOrsState: activity }}
  </span>
  <span *ngIf="!badge">
    {{ state | nlfOrsState: activity }}
  </span>
</span>
