<!-- STICKY TOP BAR -->
<span *ngIf="dataReady && !error">
  <header class="nlf-sticky-toolbar">

    <div class="container lead">
      <!-- TITLE -->

      <!-- <fa-icon [icon]="faFileAlt""></fa-icon>&nbsp;-->
      <!-- {{ data.tags | nlfOrsTags: ' / '}} -->
      <!-- TITLE OR TAGS -->
 

      <span class="overflow-hidden">
        <a [routerLink]="['/ors', observation._model.type]">OBSREG</a>#<a [routerLink]="['/ors', observation._model.type, 'edit', observation.id]">{{ observation.id }}
          <nlf-resolve-observation-tags *ngIf="(!!observation.tags||observation.tags.length>0)&&!observation.title" [tags]="observation.tags" [activity]="observation._model.type" [link]="false" seperator="/"></nlf-resolve-observation-tags>
          <ng-container *ngIf="(!observation.tags||observation.tags.length==0)&&!!observation.title">
            {{ observation.title }}
          </ng-container>
        </a>
        &nbsp;
        <span *ngIf="!!observation.rating" class="badge bg-{{observation.rating | nlfOrsRatingCalc | nlfDynamicColor}}">
          {{ observation.rating | nlfOrsRatingCalc }}</span>
      </span>
      <!-- WORKFLOW STATE -->
      <span class="float-end">

        <!-- HELP
      <span class="badge bg-light text-dark pointer text-info mx-1" (click)="openHelp()">
        <fa-icon [icon]="faQuestion"></fa-icon>
      </span>
      -->
       <nlf-help class="mx-1" key="ors-editor-activities"></nlf-help>

        <a [routerLink]="['/ors', observation._model.type, 'edit', observation.id]" class="badge bg-secondary mx-1">
          <fa-icon [icon]="faUndo"></fa-icon>Tilbake
        </a>
        <!-- ABOUT -->
        <span class="badge bg-secondary mx-1">
          <fa-icon [icon]="faInfoCircle"></fa-icon>
          <fa-icon *ngIf="!observation.acl_user.w" [icon]="faLock" class="text-danger" title="Ingen skrivetilgang"></fa-icon>
          {{ observation._updated | amTimeAgo }}
        </span>

        <!-- VERSION -->
        <span class="badge bg-success mx-1">v{{ observation._version }}</span>

        <!-- WORKFLOW -->
        <span class="mx-1" *ngIf="observation.acl_user.x">
          <nlf-resolve-observation-state [activity]="observation._model.type" [state]="observation.workflow.state" icon="true"></nlf-resolve-observation-state>
        </span>
        <span class="mx-1" *ngIf="!observation.acl_user.x">
          <nlf-resolve-observation-state [activity]="observation._model.type" [state]="observation.workflow.state" icon="true"></nlf-resolve-observation-state>
        </span>

        <a [routerLink]="['/ors', observation._model.type , 'report', observation.id]" *ngIf="observation.acl_user.x || observation.acl_user.w" class="badge bg-primary text-white mx-1">
          <fa-icon [icon]="faFile"></fa-icon> Report
        </a>

      </span>
      <!-- /WORKFLOW STATE -->
    </div>
  </header>


  <!-- EVENTS -->
  <div class="container">
    <div class="row my-3">
      <div class="col-md-10">
        <nlf-ors-activities-timeline [observation]="observation"></nlf-ors-activities-timeline>
        </div>
    </div>
  </div>
</span>

<nlf-ui-page-spinner *ngIf="!dataReady && !error"></nlf-ui-page-spinner>

<div *ngIf="!!events">
  <!--<pre>{{ events | json }}</pre>-->
</div>

<div *ngIf="!dataReady && !!error">
	<nlf-ors-error [id]="observation_id" [activity]="activity" [error]="error_message"></nlf-ors-error>
</div>
