<span *ngIf="dataReady">
  <span *ngIf="!!link && license.id !== ''">
    <a [routerLink]="['/license', license.id]">{{ license.type_name }}</a>
  </span>
  <span *ngIf="!link || license.id === ''">
    {{ license.type_name }}
  </span>
  <span>{{ license.period_name }}</span>
</span>
<span *ngIf="!dataReady">
  <nlf-ui-component-spinner></nlf-ui-component-spinner>
</span>