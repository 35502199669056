<!-- Header with Background Image
<header class="business-header">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h1 class="display-3 text-center text-white mt-4">Business Name or Tagline</h1>
        </div>
      </div>
    </div>
  </header>-->
<!-- Main jumbotron for a primary marketing message or call to action -->

<!--<h2 class="display-3">NLF Platform</h2>-->
<!-- NGX Charts! -->
<!-- [view]="view"  not defined to parent element size-->
<!--<header class="business-header bg-light">
  <div class="container">

    <p style="min-height: 300px;" class="text-center">
      <ngx-charts-advanced-pie-chart [activeEntries]="activeEntries" [scheme]="colorScheme" [label]="label" [results]="single"
        [gradient]="gradient" (select)="onSelect($event)">
      </ngx-charts-advanced-pie-chart>
    </p>
    <p class="text-center">
      <a (click)="openModal(template)" class="btn btn-primary btn-lg" href="#" role="button">Les mer &raquo;</a>
    </p>
  </div>

</header> -->

<!-- Heading Row -->
<div class="container">

  <div class="row mt-2 py-3">
    <div class="col-md-8 chart-wrapper">
      <!-- PIE CHART OBSREG -->
      <ng-container *ngIf="dataReady">

        <div class="col-md-3">
          <div echarts class="w-140 h-100 align-top" [loading]="true" [options]="typesChartOption">
          </div>
        </div>
        <div class="col-md-9">

          <div class="row">
            <h2 class="mt-4">{{ getTypesTotalValue() | number }}</h2>
            <h4 class="text-secondary">behandlede {{ getDefaultActivity() }}</h4>
          </div>

          <div class="row">

            <div class="col" *ngFor="let item of pieTypes">
              <div class="p-0 m-0">
                <div style="border-left: 3px solid {{ item.itemStyle.color }} !important">
                  <h4 class="my-1 mx-1 p-0 mt-o text-dark">{{ item.value | number }}</h4>
                  <p class="mb-0 mx-1 p-0 text-secondary small">{{ item.name }}</p>
                </div>
                <p class="h4 mb-0 mx-2 p-0 fs-5 mb-0 text-secondary">{{ item.value/getTypesTotalValue() | percent }}
                </p>
              </div>
            </div>

          </div>

        </div>
      </ng-container>
      <ng-container *ngIf="!default_activity_obsreg && !!config && default_activity">
        <div class="text-center">Ingen OBSREG registrert for {{ config.inv_mapping[default_activity] }}</div>
      </ng-container>

    </div>
    <!-- OLD
    <ng-container *ngIf="default_activity_obsreg">
      <ngx-charts-advanced-pie-chart *ngIf="dataReady" [scheme]="colorScheme" [label]="label" [results]="pie"
        (select)="onSelect($event)"></ngx-charts-advanced-pie-chart>
      <span *ngIf="!dataReady">
        <p class="align-middle text-center text-secondary">
          <nlf-ui-component-spinner></nlf-ui-component-spinner>
        </p>
      </span>
    </ng-container>
    <ng-container *ngIf="!default_activity_obsreg && !!config && default_activity">
      <div class="text-center">Ingen OBSREG registrert for {{ config.inv_mapping[default_activity] }}</div>
    </ng-container>
    -->

    <!-- //PIE CHART OBSREG -->

    <!-- TOP STORY -->
    <div class="col-md-4 d-none d-sm-block overflow-hidden">
      <nlf-content-summary *ngIf="!!config" [key]="config.apps.content.top_story || 'nlf-platform'">
      </nlf-content-summary>
    </div>
    <!-- //TOP STORY -->
  </div>
  <!-- /.row -->
</div>
<!-- /.container -->

<span *ngIf="!!activities">
  <div *ngIf="default_activity==109" class="py-5 nlf-bg-blue">
    <div class="container">
      <p class="m-0 text-center text-white">
        <nlf-ors-fallskjerm-create></nlf-ors-fallskjerm-create>
      </p>
    </div>
  </div>
  <div *ngIf="default_activity==238" class="py-5 nlf-bg-light">
    <div class="container">
      <p class="m-0 text-center text-white">
        <nlf-ors-motor-create></nlf-ors-motor-create>
      </p>
    </div>
  </div>

  <div *ngIf="default_activity==237" class="py-5 nlf-bg-light">
    <div class="container">
      <p class="m-0 text-center text-white">
        <nlf-ors-sportsfly-create></nlf-ors-sportsfly-create>
      </p>
    </div>
  </div>

  <div *ngIf="default_activity==111" class="py-5 nlf-bg-light">
    <div class="container">
      <p class="m-0 text-center text-white">
        <nlf-ors-seilfly-create></nlf-ors-seilfly-create>
      </p>
    </div>
  </div>

  <div *ngIf="default_activity==236" class="py-5 nlf-bg-light">
    <div class="container">
      <p class="m-0 text-center text-white">
        <nlf-ors-modellfly-create></nlf-ors-modellfly-create>
      </p>
    </div>
  </div>
</span>
<!-- TESTS
<div class="py-5 nlf-bg-purple">
  <div class="container">
    <span class="m-0 text-center text-white">
      <h2>Tests</h2>
      <button class="btn" [routerLink]="['/integration']">Integration</button><br />
      <button class="btn" [routerLink]="['/admin']">Admin</button><br />
      <button class="btn" [routerLink]="['/aip']">Aip</button><br />
      Person: <nlf-resolve-lungo-person person_id="301041"></nlf-resolve-lungo-person><br />
      Org: <nlf-resolve-lungo-organization organization_id="22976" show_activity="true"></nlf-resolve-lungo-organization><br />
      Lisens: <nlf-resolve-lungo-license license_id="10299159"></nlf-resolve-lungo-license><br />
      Function: <nlf-resolve-lungo-function function_id="28851410"></nlf-resolve-lungo-function><br />
      Activity: <nlf-resolve-lungo-activity activity_id="109"></nlf-resolve-lungo-activity><br />
      Org type: <nlf-resolve-lungo-organization-type type_id="8"></nlf-resolve-lungo-organization-type><br />
      Func type: <nlf-resolve-lungo-function-type type_id="7"></nlf-resolve-lungo-function-type><br />
    </span>
  </div>

</div>
-->

<!-- Page Content
<div class="container">

  <div class="row">
    <div class="col-sm-8">
      <h2 class="mt-4">Registrer OBSREG</h2>
      <p>
        <nlf-ors-create></nlf-ors-create>

      </p>
    </div>
    <div class="col-sm-4">
      <h2 class="mt-4">Kontakt oss</h2>
      <iframe src='https://sts.nif.no/Account/SignInMI?ReturnUrl=https%3a%2f%2fsts.nif.no%2f%252fissue%252fwsfed%253fwa%253dwsignin1.0%2526wtrealm%253dhttps%3a%2f%2fmi.nif.no%2f%2526wctx%253drm%25253d0%252526id%25253dpassive%252526ru%25253dhttps%3a%2f%2fmi.nif.no%2fHome%2fSetStartPage'
        height='380' width='310' frameborder='0'></iframe>
      <address>
        <abbr title="Phone">P:</abbr>
        (123) 456-7890
        <br>
        <abbr title="Email">E:</abbr>
        <a href="mailto:#">name@example.com</a>
      </address>
    </div>
  </div>
  -->
<!-- /.row -->

<!--
  <div class="row">
    <div class="col-sm-4 my-4">
      <div class="card">
        <img class="card-img-top" src="http://placehold.it/300x200" alt="">
        <div class="card-body">
          <h4 class="card-title">Card title</h4>
          <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente esse necessitatibus neque sequi doloribus.</p>
        </div>
        <div class="card-footer">
          <a href="#" class="btn btn-primary">Find Out More!</a>
        </div>
      </div>
    </div>
    <div class="col-sm-4 my-4">
      <div class="card">
        <img class="card-img-top" src="http://placehold.it/300x200" alt="">
        <div class="card-body">
          <h4 class="card-title">Card title</h4>
          <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente esse necessitatibus neque sequi doloribus totam
            ut praesentium aut.</p>
        </div>
        <div class="card-footer">
          <a href="#" class="btn btn-primary">Find Out More!</a>
        </div>
      </div>
    </div>
    <div class="col-sm-4 my-4">
      <div class="card">
        <img class="card-img-top" src="http://placehold.it/300x200" alt="">
        <div class="card-body">
          <h4 class="card-title">Card title</h4>
          <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente esse necessitatibus neque.</p>
        </div>
        <div class="card-footer">
          <a href="#" class="btn btn-primary">Find Out More!</a>
        </div>
      </div>
    </div>

  </div>


</div>-->
<!-- /.container -->

<!-- Footer -->
<!--
<footer class="py-5 bg-dark">
  <div class="container">
    <p class="m-0 text-center text-white">Copyright &copy; Your Website 2018</p>
  </div>
</footer>
-->


<ng-template #modalTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      NLF Platform</h4>

    <button type="button" class="close float-end" aria-label="Close" (click)="modalRef.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ngx-charts-advanced-pie-chart *ngIf="dataReady" [activeEntries]="activeEntries" [scheme]="colorScheme"
      [label]="label" [results]="pie" [gradient]="gradient" (select)="onSelect($event)"></ngx-charts-advanced-pie-chart>
    <nlf-ors-last activity="fallskjerm" number="3"></nlf-ors-last>
    <div class="clearfix"></div>
    <div>
      <ngx-charts-pie-grid [scheme]="colorScheme" [results]="multipie" (select)="onSelect($event)">
      </ngx-charts-pie-grid>
    </div>
  </div>
  <div class="modal-footer">
    <a class="btn btn-secondary" (click)="modalRef.close()">Close</a>
  </div>
</ng-template>

<!-- A ROW... -->
<div class="container">
  <div class="row">
    <p></p>
  </div>
</div>

<!-- CARD DECK -->
<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="row mb-3">
        <!--<nlf-user-profile></nlf-user-profile>-->
        <!--
        <div class="card">
          <div class="card-header">Opprett Observasjon</div>

         <div class="card-body">
            <span class="text-center">
              <nlf-ors-create></nlf-ors-create>
            </span>
          </div>
          <div class="card-footer">
            <div class="d-grid gap-2">
              <a class="btn btn-primary" [routerLink]="['/user/profile']" role="button">Oppdater profil &raquo;</a>
            </div>
          </div>
        </div>-->
        <!-- END USER OR OBSREG -->
        <div class="col-md-4 mt-2">
          <div class="card mb-3 obsreg-card">
            <div class="card-header"><i class="nlf nlf-motorfly nlf-fw"></i> Motorfly OBSREG</div>

            <nlf-ors-last activity="motorfly" number="8">Ingen registrerte</nlf-ors-last>

            <div class="card-footer">
              <div class="d-grid gap-2">
                <a class="btn btn-secondary" [routerLink]="['/ors', 'motorfly']" role="button">Motorfly OBSREG
                  &raquo;</a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4 mt-2">
          <div class="card mb-3 obsreg-card">
            <div class="card-header">
              <i class="nlf nlf-fallskjerm nlf-fw"></i> Fallskjerm OBSREG
            </div>
            <nlf-ors-last activity="fallskjerm" number="8">Ingen registrerte</nlf-ors-last>
            <div class="card-footer">
              <div class="d-grid gap-2">
                <a class="btn btn-secondary" [routerLink]="['/ors', 'fallskjerm']" role="button">Fallskjerm OBSREG
                  &raquo;</a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4 mt-2">
          <div class="card mb-3 obsreg-card">
            <div class="card-header">
              <i class="nlf nlf-mikrofly nlf-fw"></i> Sportsfly OBSREG
            </div>
            <nlf-ors-last activity="sportsfly" number="8">Ingen registrerte</nlf-ors-last>
            <div class="card-footer">
              <div class="d-grid gap-2">
                <a class="btn btn-secondary" [routerLink]="['/ors', 'sportsfly']" role="button">Sportsfly OBSREG
                  &raquo;</a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4 mt-2">
          <div class="card mb-3 obsreg-card">
            <div class="card-header">
              <i class="nlf nlf-seilfly nlf-fw"></i> Seilfly OBSREG
            </div>
            <nlf-ors-last activity="seilfly" number="8">Ingen registrerte</nlf-ors-last>
            <div class="card-footer">
              <div class="d-grid gap-2">
                <a class="btn btn-secondary" [routerLink]="['/ors', 'seilfly']" role="button">Seilfly OBSREG
                  &raquo;</a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4 mt-2">
          <div class="card mb-3 obsreg-card">
            <div class="card-header">
              <i class="nlf nlf-modell nlf-fw"></i> Modellfly OBSREG
            </div>
            <nlf-ors-last activity="modellfly" number="8">Ingen registrerte</nlf-ors-last>
            <div class="card-footer">
              <div class="d-grid gap-2">
                <a class="btn btn-secondary" [routerLink]="['/ors', 'modellfly']" role="button">Modellfly OBSREG
                  &raquo;</a>
              </div>
            </div>
          </div>
        </div>

        <!--
        <div class="card mb-4">
          <div class="card-header">Været</div>
          <div class="card-body">

            <p class="card-text">
              <nlf-met-metar></nlf-met-metar>
            </p>
          </div>

        </div>

      </div>
      -->


        <!--
      <div class="card-deck">
        < !-- Content -- >
        <div class="card mb-4">
          <div class="card-header lead"><i class="nlf nlf-fallskjerm nlf-fw"></i>Siste i Fallskjerm</div>
          <nlf-content-last space_key="fallskjerm" number="8"></nlf-content-last>
          <div class="card-footer">
            <div class="d-grid gap-2">
              <a class="btn btn-secondary" [routerLink]="['/content/space/fallskjerm']" role="button">Se mer
                &raquo;</a>
            </div>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-header lead"><i class="nlf nlf-motorfly nlf-fw"></i>Siste i Motor</div>
          <nlf-content-last space_key="motor" number="8"></nlf-content-last>
          <div class="card-footer">
            <div class="d-grid gap-2">
              <a class="btn btn-secondary" [routerLink]="['/content/space/motor']" role="button">Se mer &raquo;</a>
            </div>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-header lead">Siste i OBSREG</div>
          <nlf-content-last space_key="ors" number="8"></nlf-content-last>
          <div class="card-footer">
            <div class="d-grid gap-2">
              <a class="btn btn-secondary" [routerLink]="['/content/space/ors']" role="button">Se mer &raquo;</a>
            </div>
          </div>
        </div>

      </div>
    -->
        <!-- /CARD DECK -->



      </div>

    </div>
  </div>
</div>




<div class="py-5 bg-dark">
  <div class="container">
    <p class="m-0 text-center text-white">

      <!--<i class="nlf nlf-fw nlf-2x nlf-logo-original"></i>-->
      <i class="nlf nlf-fw nlf-5x nlf-fallskjerm" title="Fallskjerm"></i>
      <i class="nlf nlf-fw nlf-5x nlf-seilfly" title="Seilfly"></i>
      <i class="nlf nlf-fw nlf-5x nlf-motorfly" title="Motorfly"></i>
      <i class="nlf nlf-fw nlf-5x nlf-ballong" title="Ballong"></i>
      <i class="nlf nlf-fw nlf-5x nlf-mikrofly" title="Sportsfly"></i>
      <i class="nlf nlf-fw nlf-5x nlf-modellfly" title="Modellfly"></i>
      <i class="nlf nlf-fw nlf-5x nlf-hgpg" title="Hps"></i>
    </p>

  </div>
</div>

<!-- /.container -->
