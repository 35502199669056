<div class="d-grid gap-2">
  <button class="btn btn-secondary" (click)="openModal(modal)">
    <fa-icon [icon]="faPlane"></fa-icon> Aerodrome
  </button>
</div>

<!-- ROUTE MODAL -->
<ng-template #modal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      Aerodrome
    </h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="modalRef.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">

    <div class="row">
      <div class="col-md-12">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="aerodrome.attributes.locationIndicator.value" path="E5X.Occurrence.AerodromeGeneral.LocationIndicator" id="LocationIndicator"></nlf-ors-editor-tag-e5x>
        <span *ngIf="!aerodrome.attributes.locationIndicator.value">
          <span class="mx-1 small">+ENTO</span>
          <span class="mx-1 small">+ENJB</span>
        </span>
      </div>

    </div>
    <div class="row">
      <div class="col-md-6">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="aerodrome.attributes.aerodromeLatitude.value" path="E5X.Occurrence.AerodromeGeneral.AerodromeLatitude" id="aerodromeLatitude"></nlf-ors-editor-tag-e5x>
      </div>
      <div class="col-md-6">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="aerodrome.attributes.aerodromeLongitude.value" path="E5X.Occurrence.AerodromeGeneral.AerodromeLongitude" id="aerodromeLongitude"></nlf-ors-editor-tag-e5x>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="aerodrome.attributes.aerodromeStatus.value" path="E5X.Occurrence.AerodromeGeneral.AerodromeStatus" id="AerodromeStatus"></nlf-ors-editor-tag-e5x>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="aerodrome.attributes.aerodromeType.value" path="E5X.Occurrence.AerodromeGeneral.AerodromeType" id="AerodromeType"></nlf-ors-editor-tag-e5x>
      </div>
      <div class="col-md-6">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="aerodrome.attributes.elevationAboveMSL.value" path="E5X.Occurrence.AerodromeGeneral.ElevationAboveMSL" id="ElevationAboveMSL"></nlf-ors-editor-tag-e5x>
      </div>
    </div>
    <!--HELIPOT -->
    <div class="row" *ngIf="aerodrome.attributes.aerodromeType.value==3">
      <div class="col-md-4">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="aerodrome.attributes.landingAreaType.value" path="E5X.Occurrence.AerodromeGeneral.LandingAreaType" id="landingAreaType"></nlf-ors-editor-tag-e5x>
      </div>
      <div class="col-md-4">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="aerodrome.attributes.areaConfiguration.value" path="E5X.Occurrence.AerodromeGeneral.AreaConfiguration" id="areaConfiguration"></nlf-ors-editor-tag-e5x>
      </div>
      <div class="col-md-4">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="aerodrome.attributes.surfaceType.value" path="E5X.Occurrence.AerodromeGeneral.SurfaceType" id="surfaceType"></nlf-ors-editor-tag-e5x>
      </div>
    </div>
    <!--/HELIPORT -->
    <div class="row">
      <div class="col-md-12">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="aerodrome.attributes.locationOnNearAerodrome.value" path="E5X.Occurrence.AerodromeGeneral.LocationOnNearAerodrome" id="LocationOnNearAerodrome"></nlf-ors-editor-tag-e5x>
      </div>
    </div>


    <div class="row my-2"></div>

    <div class="row" *ngFor="let runway of aerodrome.entities.runway; let idx = index">
      <div class="col-md-5">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="aerodrome.entities.runway[idx].attributes.runwayIdentifier.value" path="E5X.Occurrence.AerodromeGeneral.Runway.RunwayIdentifier" id="RunwayIdentifier"></nlf-ors-editor-tag-e5x>
      </div>
      <div class="col-md-5">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="aerodrome.entities.runway[idx].attributes.surfaceType.value" path="E5X.Occurrence.AerodromeGeneral.Runway.SurfaceType" id="SurfaceType"></nlf-ors-editor-tag-e5x>
      </div>
      <div class="col-md-2">
        <fa-icon *ngIf="!disabled" (click)="deleteRunway(idx)" [icon]="faClose"></fa-icon>
      </div>
    </div>

    <div class="row my-2"></div>

    <div class="row" *ngFor="let vehicle of aerodrome.entities.vehicle; let idx = index">
      <div class="col-md-5">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="aerodrome.entities.vehicle[idx].attributes.typeOfVehicle.value" path="E5X.Occurrence.AerodromeGeneral.Vehicle.TypeOfVehicle" id="typeOfVehicle"></nlf-ors-editor-tag-e5x>
      </div>
      <div class="col-md-5">
        <nlf-ors-editor-tag-e5x [disabled]="disabled" [(items)]="aerodrome.entities.vehicle[idx].attributes.vehicleControlled.value" path="E5X.Occurrence.AerodromeGeneral.Vehicle.VehicleControlled" id="vehicleControlled"></nlf-ors-editor-tag-e5x>
      </div>
      <div class="col-md-2">
        <fa-icon *ngIf="!disabled" (click)="deleteVehicle(idx)" [icon]="faClose"></fa-icon>
      </div>
    </div>

    <div class="row my-2"></div>

    <div class="row" *ngIf="aerodrome.entities.narrative.length>0">
      <div class="col-md-10">
          <nlf-ors-editor-e5x-narrative [disabled]="disabled" [(narrative)]="aerodrome.entities.narrative"></nlf-ors-editor-e5x-narrative>
      </div>
      <div class="col-md-2">
        <fa-icon *ngIf="!disabled" (click)="deleteNarrative(0)" [icon]="faClose"></fa-icon>
      </div>
    </div>

    <div *ngIf="devDebug" class="row">
      AC: {{ aerodrome | json }}
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="d-grid gap-2">
          <button [disabled]="disabled" class="btn btn-primary" (click)="addRunway()">
            <fa-icon [icon]="faPlus"></fa-icon> Runway
          </button>
        </div>
      </div>
      <div class="col-md-4">
        <div class="d-grid gap-2">
          <button [disabled]="disabled" class="btn btn-primary" (click)="addVehicle()">
            <fa-icon [icon]="faPlus"></fa-icon> Vehicle
          </button>
        </div>
      </div>
      <div class="col-md-4">
        <div class="d-grid gap-2">
          <button [disabled]="aerodrome.entities.narrative.length>0 || disabled" class="btn btn-primary" (click)="addNarrative()">
            <fa-icon [icon]="faPlus"></fa-icon> Narrative
          </button>
        </div>
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button class="btn btn-outline-dark" (click)="modalRef.close()">
      <fa-icon [icon]="faTimes" [fixedWidth]="true"></fa-icon>Avbryt
    </button>
    <button [disabled]="disabled" [ngClass]="{'disabled': disabled}" class="btn btn-success" (click)="modalUpdate()">
      <fa-icon [icon]="faCheck" [fixedWidth]="true"></fa-icon>Oppdater
    </button>
  </div>
</ng-template>
