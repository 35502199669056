<nav class="navbar navbar-expand-sm bg-dark navbar-dark justify-content-end">
  <div class="container-fluid">
    <a class="navbar-brand pointer" [routerLink]="['/']">
      <!--<i class="nlf nlf-fw nlf-logo-plain nlf-2x"></i>-->NLF
    </a>

    <span *ngIf="!ENV.production" class="badge bg-danger">{{ ENV._name }}</span>

    <!-- CURRENT OR LAST OBSREG
    <span *ngIf="(loggedInObservable | async) && !!current_ors">
      <span *ngIf="router.url != '/ors/'+current_ors.type+'/'+current_ors.id">
        <span [routerLink]="['/ors', current_ors.type, 'edit', current_ors.id]"
          class="badge bg-info lead ms-1 pointer">
          <fa-icon [icon]="edit"></fa-icon>#{{ current_ors.id }}
        </span>
      </span>
    </span>
    -->

    <span class="ms-auto"></span>

    <!-- CREATE OBSREG BTN XS-->
    <button class="btn btn-success me-3 d-block d-sm-none"
      *ngIf="(loggedInObservable | async) && !!user_data && !!user_data.settings.default_discipline"
      (click)="navbarOpen=false;openCreateOrsModal()">
      <fa-icon [icon]="faPlus" [fixedWidth]="true" class="me-1"></fa-icon>OBSREG
    </button>

    <!--MENU TOGGLE-->
    <button (click)="navbarOpen=!navbarOpen" class="navbar-toggler navbar-toggler-right" type="button"
      aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <fa-icon [icon]="faBars"></fa-icon>
    </button>

    <div class="collapse navbar-collapse flex-grow-0" id="navbarSupportedContent2" [ngClass]="{ 'show': navbarOpen }">

      <ul *ngIf="!!user_data && loggedInObservable | async as authenticated" class="navbar-nav text-end">

        <!-- CREATE OBSREG BTN HIDDEN XS-->
        <button class="btn btn-success me-2 d-none d-sm-block"
          *ngIf="!!config && !!user_data && !!user_data.settings.default_discipline"
          (click)="navbarOpen=false;openCreateOrsModal()">
          <fa-icon [icon]="faPlus" [fixedWidth]="true" class="me-1"></fa-icon>Ny OBSREG
          <!--{{ config[config.inv_mapping[user_data.settings.default_activity]]['observation']['app_name'] || 'ORSX' }}-->

        </button>

        <!--
        <li *ngIf="isLoggedIn" ngbDropdown placement="bottom-right" class="nav-item">
          <span class="nav-link pointer" id="dropdownBasic1" ngbDropdownToggle routerLinkActive="active">OBSREG</span>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <a class="dropdown-item pointer">Action - 1</a>
            <a class="dropdown-item pointer">Another Action</a>
            <a class="dropdown-item pointer">Something else is here</a>
          </div>
        </li>
        -->


        <!-- ADMIN -->
        <li (click)="navbarOpen=false" *ngIf="authenticated && [1505923257, 1476506542].indexOf(userHash(user_data.person_id))>-1" class="nav-item"
          routerLinkActive="active">
          <a [routerLink]="['/admin']" class="nav-link">Admin</a>
        </li>


        <!-- CONTENT -->
        <li (click)="navbarOpen=false" *ngIf="authenticated && [1505923257, 1476506542].indexOf(userHash(user_data.person_id))>-1" class="nav-item"
          routerLinkActive="active">
          <a [routerLink]="['/content']" class="nav-link">Innhold</a>
        </li>

        <!-- Medlemssjekk -->
        <ng-container>
          <li (click)="navbarOpen=false" *ngIf="authenticated" class="nav-item" routerLinkActive="active">
            <a [routerLink]="['/medlem']" class="nav-link">Medlem</a>
          </li>
        </ng-container>


        <!-- ORGANIZATIONS -->
        <li (click)="navbarOpen=false" *ngIf="authenticated && !!user_data.settings.default_discipline" class="nav-item"
          routerLinkActive="active">
          <a [routerLink]="['/organizations', user_data.settings.default_discipline]" class="nav-link">Klubber</a>
        </li>

        <!-- Defaults to NLF -->
        <li (click)="navbarOpen=false" *ngIf="authenticated && !user_data.settings.default_discipline" class="nav-item"
          routerLinkActive="active">
          <a [routerLink]="['/organizations', 376]" class="nav-link">Klubber</a>
        </li>


        <!-- AIRCRAFT -->
        <li (click)="navbarOpen=false" *ngIf="authenticated" class="nav-item" routerLinkActive="active">
          <a [routerLink]="['/aircraft']" class="nav-link">Fly</a>
        </li>

        <!-- AIP -->
        <li (click)="navbarOpen=false" *ngIf="authenticated && [1505923257, 1476506542].indexOf(userHash(user_data.person_id))>-1" class="nav-item" routerLinkActive="active">
          <a [routerLink]="['/aip']" class="nav-link">AIP</a>
        </li>

        <!-- INTEGRATION -->
        <li (click)="navbarOpen=false"
          *ngIf="authenticated && [ 1505923257, -1957053861, 1476506542, -1133184971, 1356267023, 440141138, 559477991, 1536523365, 1567888414, 1538371429, -1069338048, -2130588421 ].indexOf(userHash(user_data.person_id))>-1"
          class="nav-item" routerLinkActive="active">
          <a [routerLink]="['/integration']" class="nav-link">Integrasjon</a>
        </li>

        <!-- OBSREG -->
        <ng-container *ngIf="dataReady">
          <li (click)="navbarOpen=false" *ngIf="authenticated" class="nav-item"
            routerLinkActive="active">
            <a [routerLink]="['/ors', getUserObsreg()]" class="nav-link">OBSREG</a>
            <!--<a  (click)="navigateORS()" class="nav-link">OBSREG</a>-->
          </li>
        </ng-container>


        <!-- NOTIFICATIONS
        <li ngbDropdown placement="bottom-right" (click)="navbarOpen=false" *ngIf="authenticated" class="nav-item dropdown pointer" routerLinkActive="active">
          <span ngbDropdownToggle href="#" class="nav-link">
            <fa-icon [icon]="bell" class="d-inline-block"></fa-icon><small><span class="small badge bg-danger align-top">4</span></small>
          </span>

          <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" href="#">OBSREG #343 created</a>
            <a class="dropdown-item" href="#">Placeholder</a>

            <div class="dropdown-divider"></div>
            <div class="d-grid gap-2">
              <button class="btn btn-secondary dropdown-item">
                <fa-icon [icon]="bell-slash"></fa-icon>Dismiss All
              </button>
            </div>
            <div class="dropdown-divider"></div>
            <div class="d-grid gap-2">
              <button class="btn btn-secondary dropdown-item">
                <fa-icon [icon]="list-ol"></fa-icon>More<span class="badge bg-danger float-end">2</span>
              </button>
            </div>
          </div>

        </li>
        -->


        <!-- USER/LOGOUT -->
        <li ngbDropdown display="dynamic" placement="bottom-right" *ngIf="authenticated" class="nav-item dropdown pointer"
          routerLinkActive="active">

          <a ngbDropdownToggle placement="bottom-right"
            class="btn btn-sm btn-secondary nav-link dropdown-toggle text-white" id="navbarDropdown" role="button"
            aria-haspopup="true" aria-expanded="false">
            <!--<span *ngIf="!!avatar" class="avatar avatar-xs me-1">
              <img [src]="domSanitizer.bypassSecurityTrustUrl(avatar)" alt="" class="avatar-img rounded-circle">
            </span>
          -->
            <span class="avatar avatar-xs me-1">
              <img [src]="avatar||avatar_missing" alt="user" class="avatar-img rounded-circle">
            </span>
            <nlf-resolve-lungo-person [person_id]="user_data.person_id" [only_first_name]="true" [avatar]="false"
              [link]="false"></nlf-resolve-lungo-person>
            <!--<span class="mx-1 badge bg-danger">2</span>-->
          </a>

          <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="navbarDropdown">
            <!--
            <a class="dropdown-item" href="#">Placeholder</a>
            <a class="dropdown-item" href="#">Placeholder</a>
            -->

            <a ngbDropdownItem class="dropdown-item" (click)="$event.preventDefault();navbarOpen=false"
              [routerLink]="['/user', 'profile']">Profil</a>

            <div class="dropdown-divider"></div>
            <button ngbDropdownItem (click)="$event.preventDefault();navbarOpen=false;authService.logout()"
              class="dropdown-item btn btn-secondary">
              <fa-icon [icon]="faPowerOff"></fa-icon>Logg ut
            </button>
          </div>

        </li>

      </ul>
    </div>
  </div>
</nav>

<!-- FAB https://medium.com/@aphlps/fab-speed-dial-with-angular-5-2-angular-material-be696fc14967
<div *ngIf="!!user_data && !!user_data.settings.default_discipline" (click)="navbarOpen=false;openCreateOrsModal()" class="fab bg-success">
  <span class="fab-icon"><fa-icon [icon]="plus"></fa-icon></span>
</div>
-->

<!-- popup template eg workflows -->
<!--
<ng-template #popTemplate>
  <div class="list-group row px-0 mx-0">
    <a href="#" class="list-group-item list-group-item-action">Dapibus ac facilisis in</a>


    <a href="#" class="list-group-item list-group-item-action list-group-item-primary">This is a primary list group
      item</a>
    <a href="#" class="list-group-item list-group-item-action list-group-item-secondary">This is a secondary list group
      item</a>
    <a href="#" class="list-group-item list-group-item-action list-group-item-success">This is a success list group
      item</a>
    <a href="#" class="list-group-item list-group-item-action list-group-item-danger">This is a danger list group item</a>
    <a href="#" class="list-group-item list-group-item-action list-group-item-warning">This is a warning list group
      item</a>
    <a href="#" class="list-group-item list-group-item-action list-group-item-info">This is a info list group item</a>
    <a href="#" class="list-group-item list-group-item-action list-group-item-light">This is a light list group item</a>
    <a href="#" class="list-group-item list-group-item-action list-group-item-dark">This is a dark list group item</a>
  </div>
  <button class="btn btn-success">Approve</button>
  <button class="btn btn-danger">Reject</button>
</ng-template>

< !ww-- Navbar itself -w- >
<nav class="navbar navbar-toggleable-sm navbar-dark bg-dark">
  <button (click)="isCollapsed = !isCollapsed" class="navbar-toggler navbar-toggler-right" type="button" aria-controls="navbarNavAltMarkup"
    aria-expanded="false" aria-label="Toggle navigation">
    <fa-icon [icon]="bars"></fa-icon>
  </button>
  <span class="navbar-brand pointer" [routerLink]="['/']">NLF</span>
  <div [ngbCollapse]="isCollapsed" class="collapse navbar-collapse justify-content-between" id="navbar">

    <ul class="navbar-nav" *ngIf="isLoggedIn">

      <li class="nav-item active">
        <a class="nav-item nav-link" routerLink="/users">Users</a>
      </li>


      <li ngbDropdown class="nav-item dropdown">

        <a ngbDropdownToggle class="nav-item nav-link dropdown-toggle" role="button" aria-haspopup="true" aria-expanded="false">
          OBSREG
        </a>
        <div *dropdownMenu class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="/ors/">OBSREG</a>
          < !ww-w-<a class="dropdown-item" routerLink="/ors/child">OBSREG Child</a> only from internal-w- >
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" routerLink="/ors/fallskjerm">OBSREG Fallskjerm</a>
        </div>
      </li>
    </ul>
    < !-w- Right side items -w- >
    <div class="navbar-nav" *ngIf="isLoggedIn">

      <div ngbDropdown class="d-inline-block">
        <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>Toggle dropdown</button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button class="dropdown-item">Action - 1</button>
          <button class="dropdown-item">Another Action</button>
          <button class="dropdown-item">Something else is here</button>
        </div>
      </div>

      <a class="nav-item nav-link" routerLink="/home">
        <fa-icon [name]="'home-o'" [fixed]="'true'"></fa-icon>
      </a>
      <a class="nav-item nav-link" routerLink="user/profile">
        <fa-icon [name]="'user-o'" [fixed]="'true'"></fa-icon>
      </a>
      <a class="nav-item nav-link" [ngbPopover]="popTemplate" placement="bottom" container="body" popoverTitle="Template ref content inside">
        <fa-icon [name]="'desktop'" [fixed]="'true'"></fa-icon>
      </a>
      <a class="nav-item nav-link" href="#">
        <fa-icon [name]="'commenting-o'" [fixed]="'true'"></fa-icon>
      </a>

      < !-w- Custom css for notifications -w- >
      <a class="nav-item nav-link" href="#">
        <span class="fa-fnix-container">
          <i class="fa fa-bell-o fa-fw"></i>
          <i class="fa text-danger fa-circle fa-fnix-sub-bg fa-fw fa-fnix-sub"></i>
          <i class="fa fa-exclamation fa-inverse fa-fw fa-fnix-sub"></i>
        </span>
      </a>
      <a class="nav-item nav-link text-danger" href="#">
        <fa-icon [name]="'bell-o'" [fixed]="'true'"></fa-icon>
      </a>

      <a *ngIf="isLoggedIn" (click)="authService.logout()" class="nav-item nav-link btn">Logout</a>
      < !-w-<a *ngIf="!(isLoggedIn | async)" class="nav-item nav-link" routerLink="/login">Login</a>-w- >
    </div>
  </div>
</nav>
-->
