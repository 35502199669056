<div *ngIf="dataReady">
  <div *ngFor="let key of comments | keys">

    <div *ngIf="(!excludes || excludes.indexOf(key)<0) && comments[key].length > 0" class="bg-light my-1">
      <div class="d-flex p-3">

        <span class="flex-shrink-0 align-self-start me-3 avatar avatar-lg">
          <nlf-resolve-avatar [person_id]="person_mappings[key]"></nlf-resolve-avatar>
        </span>

        <!--<img class="me-3 rounded-circle" src="https://dummyimage.com/64x64/595659/e3e4e8">-->

        <div class="flex-grow-1">

          <h5 class="mt-0">
            {{ roles[key] }}
          </h5>

          <p [innerHTML]="comments[key] | newlines | safe: 'html'">Ikke vurdert.</p>

        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!dataReady">
  laster ask....
</div>

<!--
<div *ngIf="!!comments.draft">
  <div class="bg-light">
    <div class="media" style="padding: 5px;">
      <img class="me-3 rounded-circle" src="https://dummyimage.com/64x64/595659/e3e4e8">
      <div class="media-body">
        <h5 class="mt-0">Observatør</h5>
        <p [innerHTML]="comments.draft | newlines | safe: 'html'"></p>
      </div>
    </div>
  </div>
</div>
<p></p>
<div *ngIf="!!comments.pending_review_hi">
  <div class="bg-light">
    <div class="media" style="padding: 5px;">
      <img class="me-3 rounded-circle" src="https://dummyimage.com/64x64/595659/e3e4e8">
      <div class="media-body">
        <h5 class="mt-0">Hovedinstruktør</h5>
        <p [innerHTML]="comments.pending_review_hi | newlines | safe: 'html'"></p>
      </div>
    </div>
  </div>
</div>

<p></p>
<div *ngIf="!!comments.pending_review_fs">
  <div class="bg-light">
    <div class="media" style="padding: 5px;">

      <img class="me-3 rounded-circle" src="https://dummyimage.com/64x64/595659/e3e4e8">
      <div class="media-body">
        <h5 class="mt-0">Fagsjef</h5>
        <p [innerHTML]="comments.pending_review_fs | newlines | safe: 'html'"></p>
      </div>
    </div>
  </div>
</div>
<p></p>
<div *ngIf="!!comments.pending_review_su">
  <div class="bg-light">
    <div class="media" style="padding: 5px;">
      <img class="me-3 rounded-circle" src="https://dummyimage.com/64x64/595659/e3e4e8">
      <div class="media-body">
        <h5 class="mt-0">SU</h5>
        <p [innerHTML]="comments.pending_review_su | newlines | safe: 'html'"></p>
      </div>
    </div>
  </div>
</div>

-->
