<!--<div class="nlf-page-center">-->
<div class="text-center">

  <div class="d-none d-sm-block">
    <div class="nlf-stack-wide nlf-5x my-2">
      <i [ngClass]="{'nlf-spin nlf-propeller': !!auth.loading}" class="nlf nlf-stack-2x text-dark"></i>
      <i class="nlf nlf-logo-plain nlf-stack-2x text-dark"></i>
    </div>
  </div>

  <div *ngIf="!!auth.loading">
    <p class="card-text">Authenticating, please wait...</p>
  </div>
</div>



<div *ngIf="!auth.loading">
  <div class="card mx-auto" style="max-width: 450px; width: 100%">

    <div class="card-header text-center">
      <h2>Login</h2>
    </div>

    <div class="card-body text-center">
      <!-- ALERT OAUTH ERROR-->
      <div *ngIf="!!oauth_error && !!oauth_error_descr" class="alert alert-danger" role="alert">
        <h4 class="alert-heading">ERROR {{ oauth_error | split: '_' | join: ' ' | upperfirst }}</h4>
        <p>{{ oauth_error_descr }}</p>
        <hr>
        <p *ngIf="oauth_error=='invalid_request'" class="mb-0">
          Ta kontakt med administrator, noe er galt med konfigurasjonen
        </p>
        <p *ngIf="oauth_error=='unauthorized_client'" class="mb-0">
          Ta kontakt med administrator, noe er galt med konfigurasjonen
        </p>
        <p *ngIf="oauth_error=='access_denied'" class="mb-0">
          Feil brukernavn og/eller passord eller
          du er ikke medlem av påkrevd seksjon(er). Forsøk å logge deg inn i https://mi.nif.no
        </p>
        <p *ngIf="oauth_error=='unsupported_response_type'" class="mb-0">
          Ta kontakt med administrator, noe er galt med konfigurasjonen
        </p>
        <p *ngIf="oauth_error=='invalid_scope'" class="mb-0">
          Ta kontakt med administrator, noe er galt med konfigurasjonen
        </p>
      </div>

      <!-- LOGIN BTN -->
      <div *ngIf="!!status && status._status && status.message.mongo>0" class="d-grid gap-2">
        <a (click)="loading=!loading" href="{{ ENV._auth_service }}?client_id={{ ENV._client_id }}&response_type={{ ENV._response_type }}&scope={{ ENV._scope }}&shebang={{ ENV._shebang }}&redirect_uri={{ _return_uri }}" class="btn btn-lg btn-primary" type="submit">
          <span *ngIf="!loading">
            <fa-icon [icon]="faLock"></fa-icon> Logg inn med NLF Auth
          </span>
          <span *ngIf="loading">
            <nlf-ui-component-spinner size="3"></nlf-ui-component-spinner><p>Authenticating, please wait....</p>
          </span>

        </a>
      </div>

      <ul class="list-group list-group-flush">

        <li class="list-group-item">
        </li>

        <li class="list-group-item">

          <!-- MESSAGES
          <div *ngIf="ENV.production" class="alert alert-warning" role="alert">
            <strong>Fallskjerm</strong> OBSREG er tilgjengelig via <a href="https://ors.nlf.no">ors.nlf.no</a>
          </div>
          -->

          <!-- DRIFTSSTATUS -->
          <div *ngIf="!!status">
            <div *ngIf=" status._status && status.message.mongo > 0" class="alert alert-success" role="alert">
              <strong>
                <fa-icon [icon]="faCheck"></fa-icon> Status
              </strong> alle systemer fungerer som normalt
            </div>
            <div *ngIf="!status._status || status.message.mongo < 1" class="alert alert-danger" role="alert">
              <strong>
                <fa-icon [icon]="faExclamationTriangle"></fa-icon>Status
              </strong> det er problemer med noen systemer og det jobbes for å løse det. Du kan forsøke å <a href="{{_return_uri}}">refreshe</a> om litt.
            </div>
          </div>
        </li>
        <li class="list-group-item">
          Innlogging skjer via <a href="https://auth.nlf.no" target="_blank">nlf-auth</a>, NLF's Oauth2 proxy til <a href="https://www.idrettsforbundet.no/digital/idrettens-id/" target="_blank">Idrettens Id</a>
        </li>
        <li *ngIf="ENV._name=='prod'" class="list-group-item">
            Test system finnes på <a href="https://dev.nlf.no">https://dev.nlf.no</a>
        </li>
        <li *ngIf="ENV._name!='prod'" class="list-group-item bg-warning">
            Dette er et testsystem, se <a href="https://app.nlf.no">https://app.nlf.no</a> for skarpt system.
        </li>
        <li class="list-group-item">
        </li>
        <li class="list-group-item">
          <nlf-ui-footer></nlf-ui-footer>
        </li>
      </ul>
    </div>
  </div>
</div>
