<div *ngIf="dataReady">
  <div class="row">
    <div class="col-md-3 col-lg-2">
      <img *ngIf="!!aircraft.image" [src]="domSanitizer.bypassSecurityTrustUrl(aircraft.image)" class="me-3 ml-0 p-0 img-fluid" alt="{{ callsign }}">
      <div *ngIf="!aircraft.image" class="me-3 ml-0 p-0 empty-image">
        <fa-icon *ngIf="aircraft.type !== 'HELICOPTER'" [icon]="faPlane" [fixedWidth]="true" size="3x"></fa-icon>
        <fa-icon *ngIf="aircraft.type === 'HELICOPTER'" [icon]="faHelicopter" [fixedWidth]="true" size="3x"></fa-icon>
      </div>
    </div>
    <div class="col-md-9">
      <h5 class="mt-0">{{ callsign }}</h5>
      {{ aircraft.model || model || 'Ukjent modell'}} {{ aircraft.manufacturer || manufacturer || 'Ukjent produsent' }}
    </div>
  </div>
</div>
<nlf-ui-component-spinner *ngIf="!dataReady"></nlf-ui-component-spinner>
