<span *ngIf="dataReady && !popover">
  <span *ngIf="!!link && organization.id !== ''">
    <a [routerLink]="['/organizations', organization.id]" [routerLinkActive]="'active'">{{ organization.name }}</a>
  </span>
  <span *ngIf="!link || organization.id === ''">
    {{ organization.name }}
  </span>
  <nlf-resolve-lungo-activity *ngIf="show_activity && !!organization.main_activity.id" [activity_id]="organization.main_activity.id"></nlf-resolve-lungo-activity>
</span>

<!-- POPOVER VIEW -->
<span *ngIf="dataReady && popover">

  <ng-template #popContent>
    <p class="lead">
      <nlf-resolve-lungo-activity *ngIf="!!organization.main_activity.id" [activity_id]="organization.main_activity.id"></nlf-resolve-lungo-activity>
    </p>
    <div class="d-grid gap-2">
      <a class="btn btn-secondary" [routerLink]="['/organizations', organization.id]" [routerLinkActive]="'active'">
        <fa-icon [icon]="faLink"></fa-icon>{{ organization.name }}
      </a>
    </div>
  </ng-template>

  <ng-template #popTitle>
    {{ organization.name }}
  </ng-template>

  <a class="pointer link" [ngbPopover]="popContent" [popoverTitle]="popTitle">{{ organization.name }}</a>
</span>

<span *ngIf="!dataReady">
  <nlf-ui-component-spinner></nlf-ui-component-spinner>
</span>
