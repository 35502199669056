<span *ngIf="observation.hasOwnProperty('e5x') && observation.e5x.audit.length>0" title="Observasjonen er sendt til LT"
    (click)="openModal(auditModal)" class="badge bg-success pointer">
    <fa-icon [icon]="faCheck"></fa-icon>LT
</span>
<span *ngIf="observation.hasOwnProperty('e5x') && observation.e5x.audit.length==0" title="ikke sendt LT"
    class="badge bg-secondary">LT</span>

<!-- AUDIT MODAL --->
<ng-template #auditModal>

    <div class="modal-header">
        <h4 class="modal-title pull-left">
            #{{ observation.id }} innsending til Luftfartstilsynet
            <!--
            <nlf-resolve-observation-tags *ngIf="(!!observation.tags||observation.tags.length>0)&&!observation.title"
                [tags]="modalObservation.tags" [activity]="modalObservation._model.type"
                seperator="/"></nlf-resolve-observation-tags>
              <ng-container *ngIf="(!observation.tags||observation.tags.length==0)&&!!observation.title">
                {{ observation.title }}
              </ng-container>
              -->
        </h4>
        <button type="button" class="close float-end" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <p class="lead">
            <fa-icon [icon]="faHistory"></fa-icon>Historikk
        </p>

        <div *ngIf="observation.e5x.audit.length==0" class="alert alert-info">
            <strong>Ingen historikk</strong> hvilket betyr at denne observasjonen ikke har blitt sendt LT
            tidligere.
        </div>

        <table *ngIf="observation.e5x.audit.length>0" class="my-3 table table-striped table-sm">
            <thead>
                <th></th>
                <th>Dato</th>
                <th>Sent av</th>
                <th>Sent som</th>
                <th>Versjon</th>
                <th>Innsending</th>
                <th>Prossesering</th>
                <th>Status</th>
                <th></th>
            </thead>
            <tbody>
                <tr *ngFor="let audit of observation.e5x.audit"
                    [ngClass]="{'bg-info':observation._version==audit.version}">
                    <td>
                    </td>
                    <td>
                        {{ audit.date | date:'yyyy-MM-dd HH:mm'}}
                    </td>
                    <td>
                        <nlf-resolve-lungo-person [person_id]="audit.person_id"></nlf-resolve-lungo-person>
                    </td>
                    <td>
                        <nlf-ors-editor-tag-e5x-render *ngIf="observation._model.version<4"
                            classes="badge badge-secondary" [showLabel]="false" [items]="audit.status"
                            path="E5X.Occurrence.ReportingHistory.ReportStatus"></nlf-ors-editor-tag-e5x-render>

                        <nlf-ors-editor-tag-e5x-render-version *ngIf="observation._model.version>=4"
                            [_id]="observation['_id']" [version]="audit.version"
                            path="eccairs2.entities.reportingHistory.0.attributes.reportStatus"
                            e5xPath="E5X.Occurrence.ReportingHistory.ReportStatus" classes="badge bg-info">
                        </nlf-ors-editor-tag-e5x-render-version>

                    </td>
                    <td>
                        v{{ audit.version }}
                    </td>
                    <td>
                        <span *ngIf="audit.sent" class="badge bg-success"><fa-icon [icon]="faPaperPlane"></fa-icon>
                            Sendt</span>
                        <span *ngIf="!audit.sent" class="badge bg-info"><fa-icon [icon]="faBan"></fa-icon>
                            Ikke sendt</span>
                    </td>
                    <!-- Prossesering-->
                    <td>
                        <span *ngIf="!!audit?.eccairs2">
                            <span *ngIf="!!audit?.eccairs2?.result" class="badge bg-success"
                                title="Filen har blitt prosessert ferdig og er mottatt hos LT">
                                <fa-icon [icon]="faCogs"></fa-icon> {{
                                audit?.eccairs2?.result?.migrationStatus || "?" }}
                            </span>
                            <span *ngIf="audit?.sent===true && audit?.eccairs2?.id>0 && !audit?.eccairs2?.result"
                                class="badge bg-warning" title="Prossesserer filen">
                                <fa-icon [icon]="faCogs"></fa-icon> {{ audit?.status }}
                            </span>
                            <span
                                *ngIf="audit?.sent===true && audit?.eccairs2?.id>0 && !!audit?.eccairs2?.result && audit?.eccairs2?.result?.migrationStatus !='Processed OK'"
                                class="badge bg-danger" title="Det var problemer med å prossessere filen">
                                <fa-icon [icon]="faCogs"></fa-icon> {{
                                audit?.eccairs2?.result?.migrationStatus || "Feilet" }}
                            </span>
                        </span>
                    </td>
                    <!--Status -->
                    <td>
                        <span *ngIf="audit?.eccairs2?.result?.migrationStatus==='Processed OK'&&audit.sent===true">
                            <fa-icon [icon]="faCheck" class="text-success" title="Filen er levert LT"></fa-icon>
                        </span>
                        <span
                            *ngIf="!!audit?.eccairs2?.result?.migrationStatus && (audit?.eccairs2?.result?.migrationStatus!='Processed OK'||audit.sent!=true)">
                            <fa-icon [icon]="faBan" class="text-danger"
                                title="Det har skjedd en feil under sending"></fa-icon>
                        </span>
                    </td>
                    <td class="text-end">
                        <a *ngIf="!!token" target="_blank"
                            href="/api/v1/e5x/download/{{observation._model.type}}/{{observation.id}}/{{audit.version}}?token={{token}}">
                            <fa-icon [icon]="faDownload"></fa-icon>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-dark" (click)="closeModal()">
            <fa-icon [icon]="faTimes"></fa-icon> Lukk
        </button>

    </div>
</ng-template>