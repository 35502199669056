import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nlf-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class NlfContentComponent implements OnInit {

  ngOnInit() {}
  
}

