<div class="clearfix"></div>
<footer class="mt-2">
  <div class="container text-center">
    <span class="text-muted">NLF Platform &copy; {{ date.getFullYear() }}</span>
    <div class="mb-1 text-secondary">v{{ version.version }}-{{ version.hash }}@{{ ENV._name }} / {{ version.buildtime | date:'yyyy-MM-dd HH:mm' }}</div>
    <div class="mb-1 text-secondary"> {{ version.branch }} </div>
    <div class="text-secondary">released {{ version.buildtime | amTimeAgo }}</div>
    <div class="text-secondary"><fa-icon [icon]="faGithub" [fixedWidth]="true"></fa-icon> <a class="text-secondary" target="_blank" href="https://github.com/luftsport">Luftsport</a></div>
  </div>
</footer>
