<ng-container *ngIf="!!observation">
  <div *ngIf="!onlyFilepicker">
    <!-- THUMBNAILS -->
    <div class="row text-center text-lg-left mb-2">

      <div *ngFor="let thumb of filelist; let i = index" class="col-lg-3 col-md-3 col-xs-6">
        <div *ngIf="thumb.isImage">
          <!-- <div class="d-block mb-4 h-100">-->

          <div class="card pointer" (click)="openModal(modalEditorImage, i)">
            <img class="card-img-top object-fit-cover" [src]="domSanitizer.bypassSecurityTrustUrl(thumb.src)" alt="{{ thumb.filename }}">
            <fa-icon [icon]="faLock" *ngIf="thumb.r===true" class="text-danger fa fa-3x card-img-overlay opacity-50"></fa-icon>
            <fa-icon [icon]="faUnlock" *ngIf="thumb.r===false" class="text-success fa fa-3x card-img-overlay opacity-50"></fa-icon>
          </div>

          <!--
          <span (click)="openModal(modalEditorImage, i)" class="pointer fa-thumbnail text-center">
            <img class="img-fluid img-thumbnail" [src]="domSanitizer.bypassSecurityTrustUrl(thumb.src)"
              alt="{{ thumb.filename }}">
          </span>
        -->
          <!--</div>-->
        </div>
      </div>

    </div>
    <!-- /THUMBNAILS -->

    <!-- FILE LIST -->
    <div *ngIf="dataReady">
      <div class="table-responsive">
        <table class="table table-sm table-striped table-hover">
          <tbody>

            <tr *ngFor="let f of filelist; let i = index">

              <td>
                <fa-icon (click)="setRestricted(false, f._id, i)" *ngIf="!!f.r" class="text-danger pointer"
                  [icon]="faLock"></fa-icon>
                <fa-icon (click)="setRestricted(true, f._id, i)" *ngIf="!f.r" class="text-success pointer"
                  [icon]="faUnlock"></fa-icon>

                <a target="_blank" *ngIf="!f.isImage" href="{{ f.download }}">{{f.name}}</a>
                <a *ngIf="!!f.isImage" (click)="openModal(modalEditorImage, i)" class="pointer">{{f.name}}</a>
              </td>
              <td>{{ f.size | bytes : 2 }}</td>
              <td>{{f.content_type}}&nbsp;</td>
              <td>
                <!--<fa-icon (click)="download(f)" name="download"></fa-icon>-->
                <a target="_blank" href="{{ f.download }}" class="mx-1">
                  <fa-icon [icon]="faDownload"></fa-icon>
                </a>
                <fa-icon *ngIf="observation.acl_user.w" class="pointer mx-1" (click)="removeFromFilelist(f._id, i)"
                  [icon]="faTimes"></fa-icon>
              </td>
              <td>&nbsp;</td>

            </tr>

          </tbody>
        </table>
      </div>
    </div>
    <!-- /FILE LIST -->
  </div>
  <!-- / -->

  <!-- FILE DROP -->
  <div *ngIf="observation.acl_user.w">

    <div *ngIf="dropzone || (onlyFilepicker && dropzone)">
      <div class="drop-container" ngFileDrop [options]="options" (uploadOutput)="onUploadOutput($event)"
        [uploadInput]="uploadInput" [ngClass]="{ 'dragover': dragOver }">
        <p class="lead">Dra og slipp filer her</p>
        <p></p>

        <div class="btn-toolbar d-flex justify-content-center" role="toolbar" aria-label="Upload toolbar">
          <!-- UPLOAD BUTTON -->

          <div class="btn-group me-2" role="group" aria-label="First group">
            <label class="btn btn-primary btn-file">
              <fa-icon [icon]="faPlus" [fixedWidth]="true"></fa-icon>Legg til filer
              <input type="file" style="display: none;" ngFileSelect [options]="options"
                (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" multiple>
            </label>
          </div>
          <!-- /UPLOAD BUTTON -->

          <!-- CAMERA BUTTON only mobile! -->
          <div class="btn-group me-2" role="group" aria-label="Second group">
            <label class="btn btn-primary btn-file d-sm-none">
              <fa-icon [icon]="faCamera"></fa-icon>Ta bilde
              <input type="file" accept="image/*" capture="camera" style="display: none;" ngFileSelect
                [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
            </label>
          </div>
          <!-- /CAMERA BUTTON-->
        </div>


        <!-- UPLOAD PROGRESS -->
        <div class="upload-items">
          <div class="upload-item" *ngFor="let f of ngxfiles; let i = index;">
            <span class="filename">
              <span>{{ f.name }} ({{ f.id }}) - {{ f.progress?.data?.percentage }}%</span>
              <span>uploading with {{ f.progress?.data?.speedHuman }}</span>
              <span>ETA {{ f.progress?.data?.etaHuman }}</span>
            </span>
            <div class="progress">
              <span class="bar" [style.width]="f?.progress?.data?.percentage + '%'"></span>
            </div>
          </div>
        </div>
        <!-- /UPLOAD PROGRESS -->
      </div>

    </div>
    <!-- /FILE DROP -->

    <div *ngIf="!dropzone || (onlyFilepicker && !dropzone)">
      <!-- UPLOAD BUTTON -->
      <div class="d-grid gap-2">
        <label class="btn btn-primary btn-file">
          <fa-icon [icon]="faPlus"></fa-icon>Legg til filer
          <input type="file" style="display: none;" ngFileSelect [options]="options"
            (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" multiple>
        </label>
      </div>
      <!-- /UPLOAD BUTTON -->
      <!-- UPLOAD PROGRESS -->
      <div class="upload-items">
        <div *ngIf="uploading">
          <div class="upload-item" *ngFor="let f of ngxfiles; let i = index;">
            <span class="filename">
              <span>{{ f.name }} ({{ f.id }}) - {{ f.progress?.data?.percentage }}%</span>
              <span>uploading with {{ f.progress?.data?.speedHuman }}</span>
              <span>ETA {{ f.progress?.data?.etaHuman }}</span>
            </span>
            <div class="progress">
              <span class="bar" [style.width]="f?.progress?.data?.percentage + '%'"></span>
            </div>
          </div>
        </div>
      </div>
      <!-- /UPLOAD PROGRESS -->
    </div>
  </div>
  <!-- VIDEO PLAYER
<vg-player>
  <video [vgMedia]="media" #media id="singleVideo" preload="auto" controls>
    <source src="http://localhost:4200/api/v1/download/5a8d29eaa01ed249e486c9dc?token=ZGViM2QxMmNjNTU4NGFjNjhhZWQyOTY0NTJkYzQ5OGY6"
      type="video/mp4">
  </video>
</vg-player>
-->
  <div *ngFor="let f of imagePreview">
    <img class="img-fluid img-thumbnail" [src]="f">
  </div>

</ng-container>

<!-- IMAGE MODAL -->
<ng-template #modalEditorImage>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      {{ viewImageName }}</h4>

    <button type="button" class="close float-end" aria-label="Close" (click)="modalRef.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <div *ngIf="!viewImageLoading">
      <img class="img-fluid" [src]="domSanitizer.bypassSecurityTrustUrl(viewimage.src)" alt="file">

      <div class="clearfix"></div>

      <span *ngIf="observation.acl_user.w">
        <fa-icon (click)="setRestricted(false, filelist[viewimage.index]['_id'], viewimage.index)"
          *ngIf="!!filelist[viewimage.index]['r']" class="text-danger pointer" [icon]="faLock">
        </fa-icon>
        <fa-icon (click)="setRestricted(true, filelist[viewimage.index]['_id'], viewimage.index)"
          *ngIf="!filelist[viewimage.index]['r']" class="text-success pointer" [icon]="faUnlock">
        </fa-icon>
      </span>
      <span *ngIf="!observation.acl_user.w">
        <fa-icon
          [ngClass]="{'text-success': !filelist[viewimage.index]['r'], 'text-danger': !!filelist[viewimage.index]['r']}"
          class="text-success" [icon]="faUnlock"
          [title]="{'Bildet vises for alle som kan se rapporten': !filelist[viewimage.index]['r'], 'Bildet vises IKKE offentlig': !!filelist[viewimage.index]['r']}">
        </fa-icon>
      </span>

      {{ filelist[viewimage.index]['name'] }} {{ filelist[viewimage.index]['size'] | bytes : 2 }}
      {{ filelist[viewimage.index]['content_type'] }}
      {{ filelist[viewimage.index]['_created'] | date:'yyyy-MM-dd HH:mm' }}

      <fa-icon *ngIf="!!filelist[viewimage.index-1]" [icon]="faChevronLeft" class="pointer"
        (click)="getModalImage(viewimage.index-1)"></fa-icon>
      <fa-icon *ngIf="!!filelist[viewimage.index+1]" [icon]="faChevronRight" class="pointer"
        (click)="getModalImage(viewimage.index+1)"></fa-icon>

      <nlf-resolve-lungo-person [person_id]="filelist[viewimage.index]['owner']"></nlf-resolve-lungo-person>

      <fa-icon *ngIf="observation.acl_user.w" class="pointer"
        (click)="removeFromFilelist(filelist[viewimage.index]['_id'], viewimage.index);modalRef.close()"
        [icon]="faTimes"></fa-icon>
    </div>
    <span *ngIf="!!viewImageLoading" class="text-center">
      <nlf-ui-component-spinner></nlf-ui-component-spinner>
    </span>

  </div>
  <div class="modal-footer">
    <a class="btn btn-secondary" (click)="modalRef.close()">Close</a>
  </div>
</ng-template>
<!-- /MODAL -->
