<ng-container *ngIf="!!observation">
  <!--
<div>
  <input id="typeahead-http" type="text" class="form-control" [class.is-invalid]="searchFailed" [(ngModel)]="model"
    [ngbTypeahead]="searchTAH" placeholder="Finn tagger" [editable]="true" [focusFirst]="false" [inputFormatter]="formatter" [resultTemplate]="typeaheadResults"
    (selectItem)="selectedItem($event)" />


</div>

<ng-template #typeaheadResults let-r="result" let-t="term">
  <ngb-highlight [result]="r.tag" [term]="t"></ngb-highlight>
</ng-template>
-->
  <!-- ADD CAUSE -->
  <div class="d-flex">
    <div class="ms-auto p-2 mb-1">
      <button (click)="addCause()" class="btn btn-warning">
        <fa-icon [icon]="faPlus" size="lg"></fa-icon> Årsak
      </button>
    </div>
  </div>


  <!-- tag input as https://github.com/LironHazan/angular-tagify-module/tree/master/src instead?? -->

  <!--
<div>
  testing tagify wrapper
  <stagify [settings]="settings"
          (add)="onTAdd($event)"
          (remove)="onTRemove($event)"
          (input)="tagInput($event.detail)"
          (click)="tagInput($event)"
          (edit)="tagInput($event)"
          (invalid)="tagInput($event)"></stagify>
  <button (click)="clearTTags()">clear</button>
  <button (click)="addTTags()">add Tags</button>
</div>
-->

  <!-- CAUSES -->
  <span cdkDropList (cdkDropListDropped)="dropCause($event)">

    <div cdkDrag [cdkDragDisabled]="!observation.acl_user.w" *ngFor="let cause of causes; let i = index">

      <div class="input-group mb-3 w-100" id="causes{{i}}">

        <span cdkDragHandle class="input-group-text drag-handle" id="basic-addon2">
          <fa-icon [ngClass]="{'text-muted': !observation.acl_user.w}" [icon]="faArrowsAlt"></fa-icon>
        </span>

        <span class="input-group-text fw-bold bg-warning lead pointer"
          [ngClass]="{'component-barrier': cause.flags.barrier}" id="basic-addon2" (click)="toggleBarrier('causes', i)">
          Å
        </span>

        <ng-select #select [disabled]="!observation.acl_user.w" class="form-control w-auto ms-auto me-auto mw-100"
          [id]="i===0 ? 'focuscause' : ''" [items]="tagsCauses$ | async" bindLabel="tag" [addTag]="true"
          [multiple]="false" [hideSelected]="true" [loading]="tagsCausesLoading" [typeahead]="tagsCausesInput$"
          [ngModel]="cause.what" (add)="onAdd($event, 'cause', i)" (remove)="onRemove($event, 'cause', i)"
          (change)="onChange($event, 'cause', i)" autoCapitalize="on" addTagText="+Opprett som ny"
          placeholder="Skriv for å søke" notFoundText="Fant ikke noe">
          <ng-template *ngIf="details" ng-option-tmp let-item="item" let-search="searchTerm">
            <div>
              <span [ngOptionHighlight]="search">{{ item.tag }}</span>
            </div>
            <small><b>Freq:</b> {{item.freq}} | <b>Group</b> {{ item.group }} | <b>Activity</b> {{ item.activity
              }}</small>
          </ng-template>
        </ng-select>

        <!--
      <nlf-ors-editor-tag [elementId]="i===0 ? 'focuscause' : ''" [fc]="true" limit="1" preload="true" details="true" group="component.what.cause" activity="fallskjerm"
      [initialTags]="[cause.what]" (change)="onAddWhat($event, 'cause', i)"></nlf-ors-editor-tag>
      -->
        <!--
      <div class="form-control">


        <tag-input class="align-ngx-input" [editable]="true" [ngModel]="!!cause.what ? [cause.what] : []" [modelAsStrings]="true"
          [inputId]="i===0 ? 'focuscause' : ''" maxItems="1" theme='nothing-theme' (onAdd)="onAddWhat($event, 'cause', i)"
          (onRemove)="onRemoveWhat($event, 'cause', i)" [addOnBlur]="true" [animationDuration]="{enter: '0ms', leave: '0ms'}"
          secondaryPlaceholder='Kort beskrivelse av årsak' [editable]="true" [ripple]="false" #input>
          <ng-template let-item="item" let-index="index">
            <span style="width: 100%">{{ item }}
            <delete-icon (click)="input.removeItem(item, index)"></delete-icon>
            </span>
          </ng-template>
        </tag-input>


      </div>-->

        <span (click)="openModal(componentModal, 'cause', i)" class="input-group-text pointer" id="basic-addon2">
          <fa-icon [ngClass]="!cause.hasOwnProperty('what') || cause['what'] =='' ? 'text-danger' : 'text-success'"
            [icon]="faExclamation"></fa-icon>
          <fa-icon
            [ngClass]="!cause.hasOwnProperty('involved') || cause['involved'].length==0 ? 'text-danger' : 'text-success'"
            [icon]="faUser"></fa-icon>
          <fa-icon
            [ngClass]="!cause.hasOwnProperty('where') || !cause['where'].hasOwnProperty('at') || cause['where']['at']=='' ? 'text-danger' : 'text-success'"
            [icon]="faMapMarker"></fa-icon>
          <fa-icon [ngClass]="!cause.hasOwnProperty('how') || cause['how'] =='' ? 'text-danger' : 'text-success'"
            [icon]="faComment"></fa-icon>
        </span>

        <span (click)="removeCause(i)" class="input-group-text pointer" id="basic-addon2">
          <fa-icon [ngClass]="{'text-muted': !observation.acl_user.w}" [icon]="faTrash"></fa-icon>
        </span>

      </div>
      <!-- //END input group -->

      <div class="drag-custom-placeholder" *cdkDragPlaceholder></div>
    </div>
  </span>

  <!-- /CAUSES -->

  <!-- INCIDENT -->
  <div *ngFor="let incident of incidents; let i = index">
    <div class="input-group mb-3">
      <span class="input-group-text not-allowed" id="basic-addon2">
        <fa-icon [icon]="faBolt"></fa-icon>
      </span>
      <span class="input-group-text fw-bold bg-info lead pointer"
        [ngClass]="{'component-barrier': incident.flags.barrier}" id="basic-addon2" (click)="toggleBarrier('incidents', i)">
        H
      </span>


      <ng-select #select [disabled]="!observation.acl_user.w" class="form-control" [items]="tagsIncidents$ | async"
        bindLabel="tag" [addTag]="true" [multiple]="false" [hideSelected]="true" [loading]="tagsIncidentsLoading"
        [typeahead]="tagsIncidentsInput$" [ngModel]="incident.what" (add)="onAdd($event, 'incident', i)"
        (remove)="onRemove($event, 'incident', i)" (change)="onChange($event, 'incident', i)" autoCapitalize="on"
        addTagText="+Opprett som ny" placeholder="Skriv for å søke" notFoundText="Fant ikke noe">
        <ng-template *ngIf="details" ng-option-tmp let-item="item" let-search="searchTerm">
          <div>
            <span [ngOptionHighlight]="search">{{ item.tag }}</span>
          </div>
          <small><b>Freq:</b> {{item.freq}} | <b>Group</b> {{ item.group }} | <b>Activity</b> {{ item.activity
            }}</small>
        </ng-template>
      </ng-select>
      <!--
    <div class="form-control">
      <tag-input class="align-ngx-input" [ngModel]="!!incidents[i].what ? [incidents[i].what] : []" [modelAsStrings]="true"
        inputId="incident" maxItems="1" theme='nothing-theme' (onAdd)="onAddWhat($event, 'incident', 0)" (onRemove)="onRemoveWhat($event, 'incident', 0)"
        [addOnBlur]="true" [animationDuration]="{enter: '0ms', leave: '0ms'}" secondaryPlaceholder='Kort beskrivelse av hendelsen'
        [ripple]="false" #input>
        <ng-template let-item="item" let-index="index">
          {{ item }}
          <delete-icon (click)="input.removeItem(item, index)"></delete-icon>
        </ng-template>
      </tag-input>
    </div>
-->

      <span (click)="openModal(componentModal, 'incident', i)" class="input-group-text pointer" id="basic-addon2">
        <fa-icon
          [ngClass]="!incident.hasOwnProperty('what') || !incident['what'] || incident['what']=='' ? 'text-danger' : 'text-success'"
          [icon]="faExclamation"></fa-icon>
        <fa-icon
          [ngClass]="!incident.hasOwnProperty('involved') || incident['involved'].length==0 ? 'text-danger' : 'text-success'"
          [icon]="faUser"></fa-icon>
        <fa-icon
          [ngClass]="!incident.hasOwnProperty('where') || !incident['where'].hasOwnProperty('at') || incident['where']['at']=='' ? 'text-danger' : 'text-success'"
          [icon]="faMapMarker"></fa-icon>
        <fa-icon
          [ngClass]="!incident.hasOwnProperty('how') || !incident['how'] || incident['how']=='' ? 'text-danger' : 'text-success'"
          [icon]="faComment"></fa-icon>
      </span>

      <span class="input-group-text" id="basic-addon2">
        <fa-icon [icon]="faBan"></fa-icon>
      </span>
    </div>
  </div>
  <!-- /INCIDENT -->

  <!-- CONSEQUENCES -->
  <span cdkDropList (cdkDropListDropped)="dropConsequence($event)">
    <div cdkDrag [cdkDragDisabled]="!observation.acl_user.w" *ngFor="let consequence of consequences; let i = index">
      <div class="input-group mb-3">
        <span cdkDragHandle class="input-group-text drag-handle" id="basic-addon2">
          <fa-icon [ngClass]="{'text-muted': !observation.acl_user.w}" [icon]="faArrowsAlt"></fa-icon>
        </span>
        <span class="input-group-text fw-bold bg-danger lead pointer"
          [ngClass]="{'component-barrier': consequence.flags.barrier}" id="basic-addon2" (click)="toggleBarrier('consequences', i)">
          K
        </span>

        <ng-select #select [disabled]="!observation.acl_user.w" class="form-control"
          [id]="i===(consequences.length-1) ? 'focusconsequence' : ''" [items]="tagsConsequences$ | async"
          bindLabel="tag" [addTag]="true" [multiple]="false" [hideSelected]="true" [loading]="tagsConsequencesLoading"
          [typeahead]="tagsConsequencesInput$" [ngModel]="consequence.what" (add)="onAdd($event, 'consequence', i)"
          (remove)="onRemove($event, 'consequence', i)" (change)="onChange($event, 'consequence', i)"
          autoCapitalize="on" addTagText="+Opprett som ny" placeholder="Skriv for å søke" notFoundText="Fant ikke noe">
          <ng-template *ngIf="details" ng-option-tmp let-item="item" let-search="searchTerm">
            <div>
              <span [ngOptionHighlight]="search">{{ item.tag }}</span>
            </div>
            <small><b>Freq:</b> {{item.freq}} | <b>Group</b> {{ item.group }} | <b>Activity</b> {{ item.activity
              }}</small>
          </ng-template>
        </ng-select>

        <!--
      <div class="form-control">
        <tag-input class="align-ngx-input" [ngModel]="!!consequence.what ? [consequence.what] : []" [modelAsStrings]="true"
          [inputId]="consequences.length===(i+1) ? 'focusconsequence' : ''" maxItems="1" theme='nothing-theme' (onAdd)="onAddWhat($event, 'consequence', i)"
          (onRemove)="onRemoveWhat($event, 'consequence', i)" [addOnBlur]="true" [animationDuration]="{enter: '0ms', leave: '0ms'}"
          secondaryPlaceholder='Kort beskrivelse av konsekvens' [ripple]="false" #input>
          <ng-template let-item="item" let-index="index">
            {{ item }}
            <delete-icon (click)="input.removeItem(item, index)"></delete-icon>
          </ng-template>
        </tag-input>
      </div>
    -->


        <span (click)="openModal(componentModal, 'consequence', i)" class="input-group-text pointer"
          id="basic-addon2">
          <fa-icon
            [ngClass]="!consequence.hasOwnProperty('what') || !consequence['what'] || consequence['what']=='' ? 'text-danger' : 'text-success'"
            [icon]="faExclamation"></fa-icon>
          <fa-icon
            [ngClass]="!consequence.hasOwnProperty('involved') || consequence['involved'].length==0 ? 'text-danger' : 'text-success'"
            [icon]="faUser"></fa-icon>
          <fa-icon
            [ngClass]="!consequence.hasOwnProperty('where') || !consequence['where'].hasOwnProperty('at') || consequence['where']['at']=='' ? 'text-danger' : 'text-success'"
            [icon]="faMapMarker"></fa-icon>
          <fa-icon
            [ngClass]="!consequence.hasOwnProperty('how')  || consequence['how']=='' ? 'text-danger' : 'text-success'"
            [icon]="faComment"></fa-icon>
        </span>
        <span (click)="removeConsequence(i)" class="input-group-text pointer" id="basic-addon2">
          <fa-icon [ngClass]="{'text-muted': !observation.acl_user.w}" [icon]="faTrash"></fa-icon>
        </span>

      </div>
      <div class="drag-custom-placeholder" *cdkDragPlaceholder></div>
    </div>
  </span>
  <!-- /CONSEQUENCES -->

  <!-- ADD CUSE / CONSEQUENCE -->

  <div class="d-flex">
    <div class="ms-auto p-2 mb-1">
      <button (click)="addConsequence()" class="btn btn-danger">
        <fa-icon [icon]="faPlus" size="lg"></fa-icon>Konsekvens
      </button>
    </div>
  </div>


  <!-- MODAL COMPONENT -->
  <ng-template #componentModal>
    <div class="modal-header">
      <h4 class="modal-title pull-left">
        <span *ngIf="modalComponent.data.flags.cause" class="badge bg-warning">Årsak</span>
        <span *ngIf="modalComponent.data.flags.incident" class="badge bg-info">Hendelse</span>
        <span *ngIf="modalComponent.data.flags.consequence" class="badge bg-danger">Konsekvens</span>
        &nbsp;
        <span *ngIf="!!modalComponent.data.what">{{ modalComponent.data.what }}</span>
        <span *ngIf="!modalComponent.data.what" class="text-muted">Mangler hva</span>
      </h4>

      <!--<button [disabled]="!observation.acl_user.w" (click)="modalToggleBarrier(!modalComponent.data.flags.barrier)" class="btn btn-outline-secondary ms-auto">Barriære</button>-->
      <button type="button" class="close float-end" aria-label="Close" (click)="modalRef.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">

      <div class="row">
        <div class="col-md-8">
          <div class="form-group">
            <label for="whereAt">Hvor</label>
            <!--<input class="form-control" type="text" id="whereAt" [(ngModel)]="modalComponent.data.where.at">-->
            <ng-select #select [disabled]="!observation.acl_user.w" class="form-control" [items]="tagsWhereAt$ | async"
              bindLabel="tag" [addTag]="true" [multiple]="false" [hideSelected]="true" [loading]="tagsWhereAtLoading"
              [typeahead]="tagsWhereAtInput$" [(ngModel)]="modalComponent.data['where']['at']"
              (change)="modalWhereAtUpdate($event)" autoCapitalize="on" addTagText="+Opprett som ny"
              placeholder="Skriv for å søke" notFoundText="Fant ikke noe">
              <ng-template *ngIf="details" ng-option-tmp let-item="item" let-search="searchTerm">
                <div>
                  <span [ngOptionHighlight]="search">{{ item.tag }}</span>
                </div>
                <small><b>Freq:</b> {{item.freq}} | <b>Group</b> {{ item.group }} | <b>Activity</b> {{ item.activity
                  }}</small>
              </ng-template>
            </ng-select>
          </div>
        </div>



        <div class="col-md-4">
          <div class="form-group">
            <label for="whereAltitude">Høyde</label>
            <input [disabled]="!observation.acl_user.w" class="form-control" type="number" id="whereAltitude"
              [(ngModel)]="modalComponent.data['where']['altitude']">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-md-12">
          <div class="form-group">
            <label for="how">Hvordan</label>
            <textarea [disabled]="!observation.acl_user.w" id="how" class="form-control" rows="3"
              [(ngModel)]="modalComponent.data.how"></textarea>
          </div>
        </div>
      </div>
      Hvem
      <div class="row">
        <div class="col-xs-12 col-md-12">
          <span *ngIf="involved.length==0">
            <div class="alert alert-info">Ingen involverte lagt til ennå</div>
          </span>
          <span *ngFor="let person of involved">

            <button [disabled]="!observation.acl_user.w" *ngIf="getModalInvolvedIndex(person.id) > -1"
              (click)="modalToggleInvolved(person)" class="btn btn-success mx-1 my-1">
              <span *ngIf="person.id > 0">
                <nlf-resolve-lungo-person [person_id]="person.id"></nlf-resolve-lungo-person>
              </span>
              <span *ngIf="person.id < 0">{{ person.full_name }}</span>
            </button>
            <button [disabled]="!observation.acl_user.w" *ngIf="getModalInvolvedIndex(person.id) < 0"
              (click)="modalToggleInvolved(person)" class="btn btn-outline-secondary mx-1 my-1">
              <span *ngIf="person.id > 0">
                <nlf-resolve-lungo-person [person_id]="person.id"></nlf-resolve-lungo-person>
              </span>
              <span *ngIf="person.id < 0">{{ person.full_name }}</span>
            </button>
          </span>
        </div>
      </div>

      Attributter:
      <div class="row">
        <div
          *ngFor="let key of config[observation._model.type].observation.components.attributes | keys; let idx = index"
          class="col-sm-12 col-md-4 mb-1">
          <div class="d-grid gap-2">
            <button [disabled]="!observation.acl_user.w" *ngIf="modalComponent.data.attributes[key]"
              (click)="modalToggleAttributes(key, false)"
              class="btn btn-{{ config[observation._model.type].observation.components.attributes[key]['badge'] }}">
              {{ config[observation._model.type].observation.components.attributes[key]['label'] }}</button>

            <button [disabled]="!observation.acl_user.w" *ngIf="!modalComponent.data.attributes[key]"
              (click)="modalToggleAttributes(key, true)" class="btn btn-outline-secondary">
              {{ config[observation._model.type].observation.components.attributes[key]['label'] }}</button>
          </div>
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button class="btn btn-outline-dark" (click)="modalRef.close()">
        <fa-icon [icon]="faClose" [fixedWidth]="true"></fa-icon>Avbryt
      </button>
      <button [disabled]="!observation.acl_user.w" class="btn btn-success" (click)="modalComponentUpdate()">
        <fa-icon [icon]="faCheck" [fixedWidth]="true"></fa-icon>Oppdater
      </button>
    </div>
  </ng-template>
  <!-- /MODAL COMPONENT -->

  <!-- NGB TYPEAHEAD NOT GOOD ENOUGH!!!
<input (keyup.enter)="onSelect($event)" id="typeahead-incident" type="text" class="form-control" [(ngModel)]="incident[0].what"
  [ngbTypeahead]="search" [resultTemplate]="resultTemplate" [inputFormatter]="formatter" [showHint]="true" [focusFirst]="false"
  (selectItem)="onSelect($event)" /> {{ incident[0].what | json }}
<ng-template #resultTemplate let-r="result" let-t="term">
  {{ r.tag}}
</ng-template>
-->

</ng-container>
