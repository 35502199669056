<!-- NARRATIVE -->
<div class="form-group">

  <textarea [disabled]="disabled"
    placeholder="Beskriv med egne ord hva som skjedde eller kunne ha skjedd - kortfattet og presist hendelsesforløp med konsekvenser"
    class="form-control mt-2"
    id="E5X.Narrative.NarrativeText" rows="5"
    (blur)="update()"
    (ngModelChange)="debouncedUpdate()"
    [(ngModel)]="narrative.plainText">
  </textarea>
  <nlf-ors-editor-tag-e5x *ngIf="showLang" [disabled]="disabled"
    [allowed]="languages" [(items)]="language.value"
    customLabel="Språk benyttet"
    path="E5X.Occurrence.ReportingHistory.ReporterSLanguage">
  </nlf-ors-editor-tag-e5x>

</div>
