<div *ngIf="dataReady && !error">

  <!-- STICKY TOP BAR -->
  <header class="nlf-sticky-toolbar">

    <div class="container lead">
      <!-- TITLE -->

      <!-- <fa-icon [icon]="faFileAlt"></fa-icon>&nbsp;-->
      <!-- {{ data.tags | nlfOrsTags: ' / '}} -->
      <span class="overflow-hidden">
        <a [routerLink]="['/ors', 'seilfly']">OBSREG</a>#{{ observation.id }}
        <nlf-resolve-observation-tags [tags]="observation.tags" [activity]="observation._model.type" link="true"
          seperator="/"></nlf-resolve-observation-tags>&nbsp;
        <span ngbTooltip="{{ observation.rating.actual }} av {{ observation.rating.potential }}" container="body"
          *ngIf="!!observation.rating" class="badge bg-{{observation.rating | nlfOrsRatingCalc | nlfDynamicColor}}">{{
          observation.rating | nlfOrsRatingCalc }}</span>
      </span>
      <!-- WORKFLOW STATE -->
      <span class="float-end">

        <!-- SAVE -->
        <span *ngIf="!!observation">
          <span (click)="saveIfChanges()" *ngIf="!!changes && observation.acl_user.w"
            class="pointer badge bg-warning mx-1">
            <fa-icon [icon]="faSave"></fa-icon>
          </span>
        </span>

        <!-- HELP -->
        <a class="badge bg-light text-dark pointer text-info mx-1" (click)="openHelp()">
          <fa-icon [icon]="faQuestion"></fa-icon>
        </a>

        <!-- FLAGS -->
        <span *ngIf="hasFlag()" class="text-danger mx-1">
          <fa-icon [icon]="faFlag"></fa-icon>
        </span>

        <!-- ABOUT -->
        <a (click)="openAbout()" class="badge bg-secondary text-white pointer mx-1">
          <fa-icon [icon]="faInfoCircle"></fa-icon>
          <fa-icon *ngIf="!observation.acl_user.w" [icon]="faLock" class="text-danger"
            title="Ingen skrivetilgang"></fa-icon>
          {{ observation._updated | amTimeAgo }}
        </a>

        <!-- VERSION -->
        <a class="badge bg-success mx-1 pointer" (click)="openDiff(diffModal)">v{{ observation._version }}</a>

        <!-- WORKFLOW -->
        <a class="pointer mx-1" *ngIf="observation.acl_user.x" (click)="openWorkflow()">
          <nlf-resolve-observation-state [activity]="observation._model.type" [state]="observation.workflow.state"
            [icon]="true" [badge]="true"></nlf-resolve-observation-state>
        </a>
        <a class="mx-1" *ngIf="!observation.acl_user.x">
          <nlf-resolve-observation-state [activity]="observation._model.type" [state]="observation.workflow.state"
            [icon]="true" [badge]="true"></nlf-resolve-observation-state>
        </a>

        <!-- E5X on change get data again!-->
        <nlf-ors-e5x (click)="saveIfChanges()" (change)="getData()"></nlf-ors-e5x>

        <!-- ACTIVITY
        <a *ngIf="observation.workflow.state!='closed'" class="badge bg-info mx-1" [routerLink]="['/ors', observation._model.type , 'activities', observation.id]">
          <fa-icon [icon]="faHistory"></fa-icon> Aktivitet
        </a>-->
        <a *ngIf="observation.workflow.state!='closed'" class="badge bg-info mx-1 pointer"
          title="Denne knappen aktiverer aktivitetsvinduet som viser all aktiviteten vedrørende denne observasjonen. Her kan det også sendes meldinger"
          (click)="openActivities(activityModal)">
          <fa-icon [icon]="faHistory"></fa-icon> Aktivitet
        </a>

        <!-- ACCESS -->
        <nlf-ors-editor-users *ngIf="observation.acl_user.x" [model]="observation._model.type" [_id]="observation._id"
          [observation]="observation"></nlf-ors-editor-users>

        <a [routerLink]="['/ors', observation._model.type , 'report', observation.id]" class="badge bg-primary text-white mx-1">
          <fa-icon [icon]="faFile"></fa-icon> Report
        </a>

      </span>
      <!-- /WORKFLOW STATE -->
    </div>
  </header>

  <!-- CONTAINER -->
  <div class="container">

    <!-- TITLE/TAGS-->
    <div class="row my-3">
      <div class="col-sm-12 col-md-12">

        <!-- WELCOME FIRST TIME -->
        <nlf-ors-editor-first *ngIf="observation.acl_user.w"></nlf-ors-editor-first>

        <nlf-ors-editor-testclub-alert></nlf-ors-editor-testclub-alert>

        <!-- TITLE -->
        <div class="nlf-ors-toolbar">
          Tittel <nlf-help class="float-end" key="ors-editor-seilfly-title"></nlf-help>
        </div>
      </div>

      <div class="col-sm-12 col-md-12 my-3 lead">

        <nlf-ors-editor-tag [disabled]="!observation.acl_user.w" limit="15" preload="true" details="true"
          group="observation" [activity]="observation._model.type" [(initialTags)]="observation.tags"
          (change)="update($event)"></nlf-ors-editor-tag>
        <!--<nlf-ors-editor-title></nlf-ors-editor-title>-->
        <div *ngIf="!!devDebug">
          <code>{{ observation.tags | json}} </code>
        </div>

      </div>
    </div>


    <!-- FILES mobnile only -->
    <div class="row my-3 d-block d-sm-none">
      <div class="col-xs-12 col-md-12">
        <div class="nlf-ors-toolbar">
          Vedlegg ({{ observation.files.length }}) <nlf-help class="float-end" key="ors-editor-files"></nlf-help>
        </div>
      </div>
      <div class="col-xs-12 col-md-12 py-3 bg-light table-responsive overflow-auto">
        <nlf-ors-editor-files [dropzone]="true" (fileChange)="save($event)"></nlf-ors-editor-files>
        <div *ngIf="!!devDebug">{{ observation.files | json }}</div>
      </div>
    </div>
    <!--
    <div>

        @Input() items: any; //numbernumber[] = [];
  @Output() itemsChange: EventEmitter<any> = new EventEmitter();

  @Input() path: string; //Occurrence.DewPoint

  @Input() classes: string = '';
  @Input() disable: boolean = false;
  @Input() element_id: string = '';

  OccurrenceCategory multiple


      <nlf-ors-editor-tag-e5x path="E5X.Occurrence.DetectionPhase" [(items)]="observation.aircrafts[0].flight[0].incident.classification.DetectionPhase"></nlf-ors-editor-tag-e5x>
      {{ observation.aircrafts[0].flight[0].incident.classification.DetectionPhase | json }}

      <nlf-ors-editor-tag-e5x path="E5X.Occurrence.ReportingHistory.ReportingEntity" [(items)]="observation.aircrafts[0].flight[0].incident.classification.ReportingEntity"></nlf-ors-editor-tag-e5x>
      {{ observation.aircrafts[0].flight[0].incident.classification.ReportingEntity | json}}

       <nlf-ors-editor-tag-e5x path="E5X.Occurrence.DewPoint" [(items)]="observation.aircrafts[0].flight[0].incident.classification.DewPoint"></nlf-ors-editor-tag-e5x>
       Decimal {{ observation.aircrafts[0].flight[0].incident.classification.DewPoint | json }}

       <nlf-ors-editor-tag-e5x path="E5X.Occurrence.OccurrenceCategory" [(items)]="observation.aircrafts[0].flight[0].incident.classification.OccurrenceCategory"></nlf-ors-editor-tag-e5x>
       Multiple {{ observation.aircrafts[0].flight[0].incident.classification.OccurrenceCategory | json }}

       <nlf-ors-editor-tag-e5x path="E5X.Occurrence.Aircraft.PartsDamaged" [(items)]="observation.aircrafts[0].flight[0].incident.classification.PartsDamaged"></nlf-ors-editor-tag-e5x>
       String {{ observation.aircrafts[0].flight[0].incident.classification.PartsDamaged | json }}

      </div>
    -->

    <!--Type, When & Flags-->
    <div class="row my-3">
      <!-- WHEN -->
      <div class="col-sm-12 col-md-4">
        <div class="card h-100">
          <div class="card-header">
            <h5>Tidspunkt [UTC] <nlf-help class="float-end" key="ors-editor-when"></nlf-help>
            </h5>
          </div>
          <div class="card-body">
            <nlf-ors-editor-when tz="utc"></nlf-ors-editor-when>
            <div *ngIf="!!devDebug">{{ observation.when }}</div>
          </div>
        </div>
      </div>

      <!-- TYPE -->
      <div class="col-sm-12 col-md-4">
        <div class="card h-100">
          <div class="card-header">
            <h5>Type <nlf-help class="float-end" key="ors-editor-seilfly-type"></nlf-help>
            </h5>
          </div>
          <div class="card-body">
            <nlf-ors-editor-type [title]="true"></nlf-ors-editor-type>
            <div *ngIf="!!devDebug">{{ observation.type }}</div>
          </div>
        </div>
      </div>

      <!-- FLAGS-->
      <div class="col-sm-12 col-md-4">
        <div class="card h-100">
          <div class="card-header">
            <h5>Flagg <nlf-help class="float-end" key="ors-editor-seilfly-flags"></nlf-help>
            </h5>
          </div>
          <div class="card-body">
            <nlf-ors-editor-flags></nlf-ors-editor-flags>
            <div *ngIf="!!devDebug">{{ observation.flags | json }}</div>
          </div>
        </div>
      </div>
    </div>

    <nlf-ors-editor-alert></nlf-ors-editor-alert>

    <!-- LOCATION: Removed  -->

    <!-- NARRATIVE -->
    <div class="row mt-3">
      <div class="nlf-ors-toolbar">
        Hva skjedde? <nlf-help class="float-end" key="ors-e5x-narrative"></nlf-help>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-md-12 bg-light py-3">
        <ng-container *ngIf="observation._model.version>2">
          <nlf-ors-editor-e5x-reportinghistory-reporters-description
            *ngIf="!!observation.occurrence.entities.reportingHistory[0].attributes"
            [disabled]="!observation.acl_user.w" (change)="update()"
            [(narrative)]="observation.occurrence.entities.reportingHistory[0].attributes.reporterSDescription"
            customLabel="Språk benyttet"
            [(language)]="observation.occurrence.entities.reportingHistory[0].attributes.reporterSLanguage">
          </nlf-ors-editor-e5x-reportinghistory-reporters-description>
        </ng-container>
        <ng-container *ngIf="observation._model.version<3">
          <nlf-ors-editor-e5x-narrative [disabled]="!observation.acl_user.w" (change)="update()"
            [(narrative)]="observation.occurrence.entities.narrative"></nlf-ors-editor-e5x-narrative>
        </ng-container>
      </div>
    </div>

    <!-- FILES ALT 1 -->

    <!-- FILES MD only -->
    <div class="row my-3 d-none d-sm-block">
      <div class="nlf-ors-toolbar">
        Vedlegg ({{ observation.files.length }}) <nlf-help class="float-end" key="ors-editor-files"></nlf-help>
      </div>
      <div class="col-xs-12 col-md-12 py-3 bg-light table-responsive overflow-auto">
        <nlf-ors-editor-files [dropzone]="true" (fileChange)="save($event)"></nlf-ors-editor-files>
        <div *ngIf="!!devDebug">{{ observation.files | json }}</div>
      </div>
    </div>
    <!-- FILES ALT 2 -->
    <!--
    <div class="row my-3">
      <div class="col-sm-12 col-md-12">
        <div class="card">
          <div class="card-header">
            <h5>Vedlegg ({{ observation.files.length }})
              <nlf-help class="float-end" key="ors-editor-files"></nlf-help>
            </h5>
          </div>
          <div class="card-body table-responsive">
            <nlf-ors-editor-files [dropzone]="true" (fileChange)="save($event)"></nlf-ors-editor-files>
            <div *ngIf="!!devDebug">
              {{ observation.files | json }}
            </div>
          </div>
        </div>
      </div>
    </div>
  -->

    <ng-container>
      <!-- AIRCRAFTS / FLIGHT / INCIDENTS-->
      <div class="row">
        <div class="nlf-ors-toolbar">
          Fartøy
          <nlf-help class="float-end" key="ors-e5x-aircrafts"></nlf-help>
          <!-- @TODO show modal w/ac information/summary <button class="float-end btn btn-link">preview</button> -->
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-12 mt-3">
          <nlf-ors-editor-e5x-aircraft [showSimpleView]="showSimpleView()"></nlf-ors-editor-e5x-aircraft>
        </div>
      </div>
      <!-- OCCURRENCE TEST -->
      <!--
    <div class="nlf-ors-toolbar">
      Occurrence
      <nlf-help class="float-end" key="ors-editor-e5x-occurrence"></nlf-help>
    </div>
    <div class="col-md-12 col-sm-12">
      <nlf-ors-editor-e5x-occurrence [disabled]="!observation.acl_user.w" [modal]="false"></nlf-ors-editor-e5x-occurrence>
    </div>
    -->
      <nlf-ors-editor-e5x-occurrence [showSimpleView]="showSimpleView()" [disabled]="!observation.acl_user.w"
        [modal]="false"></nlf-ors-editor-e5x-occurrence>
      <!-- CLASSIFICATION -->
      <!--
    <div class="nlf-ors-toolbar">
      Klassifisering
      <nlf-help class="float-end" key="ors-editor-e5x-classification"></nlf-help>
    </div>
    <div class="col-md-12 col-sm-12 bg-danger">
      <nlf-ors-editor-e5x-classification [disabled]="!observation.acl_user.w" (change)="update()" [(attr)]="observation.occurrence.attributes" [modal]="false"></nlf-ors-editor-e5x-classification>
    </div>
  -->
      <!-- E5X ATTRIBUTES -->
      <!--
    <div class="nlf-ors-toolbar">
      Legg til relevante attributter
    </div>
    <div class="my-3">
      <nlf-ors-editor-e5x-where [disabled]="!observation.acl_user.w" [modal]="true" (change)="update()" [(attr)]="observation.occurrence.attributes"></nlf-ors-editor-e5x-where>
    </div>
    <div class="my-3">
      <nlf-ors-editor-e5x-weather [disabled]="!observation.acl_user.w" (change)="update()" [(attr)]="observation.occurrence.attributes"></nlf-ors-editor-e5x-weather>
    </div>
    <div class="my-3">
      <nlf-ors-editor-e5x-injuries [disabled]="!observation.acl_user.w" (change)="update()" [(attr)]="observation.occurrence.attributes"></nlf-ors-editor-e5x-injuries>
    </div>
    <div class="my-3">
      <nlf-ors-editor-e5x-damage [disabled]="!observation.acl_user.w" (change)="update()" [(attr)]="observation.occurrence.attributes"></nlf-ors-editor-e5x-damage>
    </div>
    -->

      <!-- E5X OCCURRENCE ENTITIES -->
      <!--
    <div class="nlf-ors-toolbar">
      Legg til relevante enheter
    </div>
    <nlf-ors-editor-e5x-entities></nlf-ors-editor-e5x-entities>
    -->

      <!-- RISK ASSESMENT I REPORTING HISTORY
    <nlf-ors-editor-e5x-assessment (change)="update()" [(reportingHistory)]="observation.occurrence.entities.reportingHistory"></nlf-ors-editor-e5x-assessment>
    -->

      <!-- REPORTING HISTORY -->
      <nlf-ors-editor-e5x-reportinghistory [disabled]="!observation.acl_user.w" (change)="update()"
        [(reportingHistory)]="observation.occurrence.entities.reportingHistory"></nlf-ors-editor-e5x-reportinghistory>
      <!-- RISK ASSESSMENT -->
      <nlf-ors-editor-e5x-risk *ngIf="!showSimpleView()" [disabled]="!observation.acl_user.w" (change)="update()"
        [(occurrence)]="observation.occurrence"></nlf-ors-editor-e5x-risk>
      <!-- NARRATIVE Moved top
    <div class="row my-3">
      <div class="col-xs-12 col-md-12">
        <div class="nlf-ors-toolbar">
          Narrative <nlf-help class="float-end" key="ors-e5x-narrative"></nlf-help>
        </div>
      </div>
      <div class="col-xs-12 col-md-12 bg-light">
        <nlf-ors-editor-e5x-narrative [disabled]="!observation.acl_user.w" (change)="update()" [(narrative)]="observation.occurrence.entities.narrative"></nlf-ors-editor-e5x-narrative>
      </div>
    </div>
  -->
      <!-- ORGANIZATION: Removed -->


      <!-- INVOLVERTE
    <div class="row my-3">
      <div class="col-xs-12 col-md-12">
        <div class="nlf-ors-toolbar">
          Andre Involverte <nlf-help class="float-end" key="ors-editor-involved"></nlf-help>
        </div>
      </div>
      <div class="col-xs-12 col-md-12">
        <nlf-ors-editor-people-seilfly [disabled]="!observation.acl_user.w" [who]="observation.involved" path="involved"></nlf-ors-editor-people-seilfly>
        <div *ngIf="!!devDebug">{{ observation.involved | json }}</div>
      </div>
    </div>
    -->

      <!-- COMPONENTS
    <div class="row my-3">
      <div class="col-xs-12 col-md-12">
        <div class="nlf-ors-toolbar nlf-ors-block-bottom">
          Forløpet
          <nlf-help class="float-end" key="ors-editor-components"></nlf-help>
          <button (click)="openPreview(modalPreview, 'components', 'Forløpet')" class="float-end btn btn-link">
            preview
          </button>
        </div>
        <nlf-ors-editor-components></nlf-ors-editor-components>
        <div *ngIf="devDebug" class="col-md-12 bg-light">
          {{ observation.components | json }}
        </div>
      </div>
    </div>
    -->

      <!-- WEATHER
    <div class="row my-3">
      <div class="nlf-ors-toolbar nlf-ors-block-bottom">
        Været
        <nlf-help class="float-end" key="ors-editor-weather"></nlf-help>
      </div>
      <div class="col-xs-12 col-md-6">
        <nlf-ors-editor-met></nlf-ors-editor-met>
      </div>
      <div class="col-xs-12 col-md-6">
        <nlf-ors-editor-weather></nlf-ors-editor-weather>
      </div>
      <div class="col-xs-12 col-md-12">
        <div *ngIf="devDebug">
          {{ observation.weather.auto | json }}
        </div>
      </div>
    </div>
    -->
      <!-- NARRATIVE -->

    </ng-container>


    <!-- RATING -->
    <div class="row mt-3">
      <div class="nlf-ors-toolbar">
        Alvorlighetsgrad <nlf-help class="float-end" key="ors-editor-seilfly-rating"></nlf-help>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-md-12 bg-light py-1">
        <nlf-ors-editor-rating></nlf-ors-editor-rating>
        <div *ngIf="!!devDebug">Rating for observation {{ observation.rating | json }}</div>
      </div>
    </div>

    <!-- VURDERINGER-->
    <div class="row">
      <div class="nlf-ors-toolbar">
        Vurdering
        <nlf-help class="float-end" key="ors-editor-ask"></nlf-help>
      </div>
    </div>

    <!-- ASK -->
    <div class="row bg-light py-2">

      <!-- ASK TOGGLE -->
      <div class="col-md-4 col-sm-12">
        <p class="mt-3">
          <nlf-ors-editor-ask></nlf-ors-editor-ask>
        </p>
      </div>
      <!-- ASK TEXT -->
      <div class="col-md-8 col-sm-12">
        <p class="mt-1">
          <nlf-ors-editor-ask-text></nlf-ors-editor-ask-text>
        </p>
      </div>

    </div>

    <!-- ACTIONS -->
    <ng-container *ngIf="!showSimpleView()">
      <div class="row">
        <div class="nlf-ors-toolbar nlf-ors-block-bottom">
          Tiltak
          <nlf-help class="float-end" key="ors-editor-seilfly-actions"></nlf-help>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-sm-12">
          <nlf-ors-editor-actions></nlf-ors-editor-actions>
        </div>
        <div *ngIf="!!devDebug">{{ observation.actions | json }}</div>
      </div>
    </ng-container>

    <!-- /// END FORM -->

    <span class="d-md-none">
      <!-- SIMPLE VIEW -->
      <div class="row">
        <div class="nlf-ors-toolbar">
          Visning av felter
          <nlf-help class="float-end" key="ors-editor-ask"></nlf-help>
        </div>
      </div>
      <div class="col-xs-12 col-md-12 py-2">
        <p>
          Du kan selv velge visning av felter i observasjonen. Normalt vil en nyopprettet observasjon vises med
          forenklet
          skjema. Du kan velge &quot;Vis alle felter&quot; for å vise alle felter eller &quot;Vis forenklet skjema&quot;
          for å vise
          forenklet og redusert antall felter. Endringene er persistente for din bruker også om du har en annen rolle i
          arbeidsflyten eller logger inn fra en annen enhet.
        </p>
        <ng-container *ngIf="!showSimpleView()">
          <div class="btn-toolbar float-end">
            <button (click)="toggleSimpleView()" class="btn btn-primary">Vis forenklet skjema</button>
          </div>
        </ng-container>
        <ng-container *ngIf="showSimpleView()">
          <div class="btn-toolbar float-end">
            <button (click)="toggleSimpleView()" class="btn btn-primary">Vis alle felter</button>
          </div>
        </ng-container>
      </div>
      <!-- WORKFLOW ACTIONS -->
      <ng-container *ngIf="observation.acl_user.x">

        <div class="nlf-ors-toolbar">
          <ng-container *ngIf="observation.workflow.state=='withdrawn'">Gjenåpning</ng-container>
          <ng-container *ngIf="observation.workflow.state=='draft'">Innsending</ng-container>
          <ng-container *ngIf="['draft', 'withdrawn'].indexOf(observation.workflow.state)==-1">Behandling</ng-container>

          <nlf-help class="float-end" key="ors-editor-workflow"></nlf-help>
        </div>
        <!-- bg-secondary text-white-->
        <div class="col-xs-12 col-md-12 py-2">
          <ng-container *ngIf="['withdrawn', 'closed'].indexOf(observation.workflow.state)>-1">
            <p>
              Observasjonen er lukket. Du kan velge å gjenåpne observasjonen om du ønsker fortsette å behandle den.
            </p>
            <p>
              Les mer om arbeidsflyten ved å trykke på <span>
                <nlf-help key="ors-editor-workflow"></nlf-help>
              </span>
            </p>
            <div class="btn-toolbar float-end">
              <button (click)="openWorkflow()" class="btn btn-danger me-2">Gjenåpne</button>
            </div>
          </ng-container>

          <ng-container *ngIf="observation.workflow.state=='draft'">
            <p>Når du er ferdig med å redigere observasjonen er det på tide å sende den videre til behandling hos OBSREG
              koordinator.</p>
            <p>Du kan alltid aktivere arbeidsflyten ved å trykke på statusknappen for arbeidsflyten <span><a
                  class="pointer mx-1" (click)="openWorkflow()">
                  <nlf-resolve-observation-state [activity]="observation._model.type"
                    [state]="observation.workflow.state" icon="true"></nlf-resolve-observation-state>
                </a></span> som du finner øverst på siden i &quot;sidemenyen&quot;
            </p>
            <p>Behandlingen skjer i en forhåndsdefinert arbeidsflyt som du kan lese mer om ved å trykke på <span>
                <nlf-help key="ors-editor-workflow"></nlf-help>
              </span>
            </p>
            <p>Ønsker du slette/fjerne observasjonen velger du å &quot;trekke tilbake&quot;. Da forsvinner den ute av
              syne
              for alle enn deg. Ingen kan forresten slette observasjoner fra systemet, og du kan når som helst komme
              tilbake og gjenåpne
              den skulle du ombestemme deg.</p>
            <!-- Knapper for innsending -->
            <div class="btn-toolbar float-end">
              <button (click)="openWorkflow()" class="btn btn-success me-2">Send til OBSREG-koordinator</button>
              <button (click)="openWorkflow()" class="btn btn-danger me-2">Trekk tilbake</button>
            </div>

          </ng-container>

          <ng-container *ngIf="['draft', 'withdrawn', 'closed'].indexOf(observation.workflow.state)==-1">
            <p>Observasjonen har status
              <span><a class="pointer mx-1" (click)="openWorkflow()">
                  <nlf-resolve-observation-state [activity]="observation._model.type"
                    [state]="observation.workflow.state" icon="true"></nlf-resolve-observation-state>
                </a></span>.
            </p>
            <p>
              Når du har gjennomgått observasjonen skal du aktivere arbeidsflyten for å behandle observasjonen.
            </p>
            <p>
              Les mer om arbeidsflyten ved å trykke på <span>
                <nlf-help key="ors-editor-workflow"></nlf-help>
              </span>
            </p>

            <div class="btn-toolbar float-end">
              <button (click)="openWorkflow()" class="btn btn-primary me-2">
                <fa-icon [icon]="faExchange"></fa-icon> Behandle #{{observation.id}}
              </button>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <!-- //WORKFLOW ACTIONS -->
    </span>

    <!-- BOTTOM TOOLBAR  DESKTOP ONLY -->
    <div *ngIf="observation.acl_user.x" class="nlf-toolbar fixed-bottom bg-dark d-none d-md-block">
      <div class="container">

        <span class="float-start" text="Her velger du visning om du vil ha forenklet eller alle felter"
          class="nav-item">
          <ng-container *ngIf="!showSimpleView()">
            <button (click)="toggleSimpleView()" class="btn btn-primary">Vis forenklet skjema</button>
          </ng-container>
          <ng-container *ngIf="showSimpleView()">
            <button (click)="toggleSimpleView()" class="btn btn-primary">Vis alle felter</button>
          </ng-container>
        </span>

        <span class="float-end" text="Når du er ferdig med observasjonen så skal du sende den inn.">

          <span class="me-4 lead">
            <nlf-help key="ors-editor-workflow"></nlf-help>
          </span>

          <ng-container *ngIf="observation.workflow.state==='draft'">

            <button class="btn btn-success me-2" (click)="openWorkflow()" title="Send"
              text="Når du er ferdig med observasjonen så sender du den til neste i arbeidsflyten som vil behandle observasjonen videre">
              <fa-icon [icon]="faPaperPlane"></fa-icon> Send til OBSREG-koordinator
            </button>


            <button class="btn btn-danger me-2" (click)="openWorkflow()" title="Trekk tilbake"
              text="Om du ikke ønsker sende inn rapporten så velger du trekk tilbake. Da blir rapporten kun tilgjengelig for deg og du kan når som helst gjenåpne den igjen. Dette er OBSREGs måte å slette på.">
              <fa-icon [icon]="faReply"></fa-icon> Trekk tilbake
            </button>

          </ng-container>

          <ng-container *ngIf="observation.workflow.state==='withdrawn'">
            <button class="btn btn-success me-2" (click)="openWorkflow()" title="Gjenåpne" text="Gjenåpne ">
              <fa-icon [icon]="faRepeat"></fa-icon> Gjenåpne
            </button>
          </ng-container>

          <ng-container *ngIf="['withdrawn','draft'].indexOf(observation.workflow.state)<0">
            <button class="btn btn-primary me-2" (click)="openWorkflow()" title="Behandle" text="Behandle ">
              <fa-icon [icon]="faRandom"></fa-icon> Arbeidsflyt
            </button>
          </ng-container>
        </span>
      </div>
    </div>
    <!-- //BOTTOM TOOLBAR -->


    <!-- SEND BTN
    <div class="row my-3">
      <span class="pointer mx-1 float-end" *ngIf="observation.acl_user.x && observation.workflow.state=='draft'" (click)="openWorkflow()">
        <button class="btn btn-success">
          <fa-icon [icon]="faUpload"></fa-icon>Send OBSREG-koordinator
        </button>
      </span>
    </div>
-->
    <div *ngIf="!!devDebug">
      {{ observation.ask | json }}
    </div>

  </div><!-- /CONTAINER-->

  <div *ngIf="!!devDebug" class="container">
    <div>{{ observation |json }}</div>
  </div>

</div>

<!-- PAGE SPINNER -->
<div *ngIf="!dataReady">
  <nlf-ui-page-spinner size="5"></nlf-ui-page-spinner>
</div>

<!-- PAGE ERROR -->
<div *ngIf="dataReady && !!error">
  <nlf-ors-error [id]="id" activity="seilfly" [error]="error"></nlf-ors-error>
</div>

<!-- MODAL VERSIONS -->
<ng-template #modalVersions>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      OBSREG#{{ observation.id }} version {{ observation._version }}</h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="modalRef.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Not implemented yet
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-dark" (click)="modalRef.close()">Ferdig</button>
  </div>
</ng-template>
<!-- /MODAL VERSIONS -->

<!-- MODAL PREVIEW -->
<ng-template #modalPreview>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      {{ preview.title }}</h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="modalRef.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="preview.what==='components'">
      <nlf-report-components-timeline [components]="observation.components"
        [activity]="observation._model.type"></nlf-report-components-timeline>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-dark" (click)="modalRef.close()">Ferdig</button>
  </div>
</ng-template>
<!-- /MODAL PREVIEW -->

<!-- ACTIVITY MODAL --->
<ng-template #activityModal>

  <div class="modal-header">
    <h4 class="modal-title pull-left">
      #{{ observation.id }}
      <nlf-resolve-observation-tags [tags]="observation.tags" [activity]="observation._model.type"
        seperator="/"></nlf-resolve-observation-tags>
    </h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="closeActivities()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <nlf-ors-activities-timeline [observation]="observation"></nlf-ors-activities-timeline>
  </div>

  <div class="modal-footer">
    <button class="btn btn-outline-dark" (click)="closeActivities()">
      <fa-icon [icon]="faTimes"></fa-icon> Lukk
    </button>

  </div>
</ng-template>
<!-- //ACTIVITY -->

<!-- DIFF MODAL --->
<ng-template #diffModal>

  <div class="modal-header">
    <h4 class="modal-title pull-left">
      #{{ observation.id }}
      <nlf-resolve-observation-tags [tags]="observation.tags" [activity]="observation._model.type"
        seperator="/"></nlf-resolve-observation-tags>
    </h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="closeActivities()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <pre>
    {{ getDiff() | json }}
    </pre>
    {{ this.shadow.when }} || {{ this.observation.when }}
  </div>

  <div class="modal-footer">
    <button class="btn btn-outline-dark" (click)="closeActivities()">
      <fa-icon [icon]="faTimes"></fa-icon> Lukk
    </button>

  </div>
</ng-template>

<div *ngIf="!!devDebug">
  <pre>{{observation | json}}</pre>
</div>
