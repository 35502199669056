<span *ngIf="!!observation">

  <nlf-ors-editor-tag-persons *ngIf="!!involved" [disabled]="!observation.acl_user.w" [(persons)]="involved"
    [activity]="observation._model.type" [(external)]="deleteExternal"
    (change)="onChange($event)"></nlf-ors-editor-tag-persons>
  <!-- {{ involved | json }} -->

  <table class="table table-striped table-hover mt-2">

    <tr *ngFor="let p of involved; let idx = index">

      <td class="">
        <fa-icon *ngIf="p.id>0" [icon]="faCheck" class="text-success me-2" title="Hentet fra medlemssystemet"></fa-icon>
        <fa-icon *ngIf="p.id<1" [icon]="faExclamation" class="text-danger me-2" title="Ukjent person"></fa-icon>
        <strong>
          <nlf-resolve-lungo-person [person_id]="p.id" [tmp_name]="p.tmp_name" [link]="false"
            [avatar]="true"></nlf-resolve-lungo-person>
        </strong>

      </td>
      <td>

        <fa-icon *ngIf="!!p.ph" [icon]="faBan" title="Permanent Hoppforbud" [fixedWidth]="true"
          class="text-danger mx-1"></fa-icon>
        <fa-icon *ngIf="!!p.fu" [icon]="faExclamation" title="Farlig Ukontrollert" [fixedWidth]="true"
          class="text-warning mx-1"></fa-icon>

      </td>
      <td>

        <div>
          <span *ngFor="let license of p?.data?.licenses" class="mx-1">
            <nlf-resolve-lungo-license [license_id]="license.id"></nlf-resolve-lungo-license>
            <!--{{license.type_name}}-->
          </span>
        </div>
        <div>
          <span *ngFor="let competence of p?.data?.competences" class="mx-1">
            {{competence._code}}
          </span>
        </div>
        <div>
          <span *ngIf="!!p?.data?.years_of_experience" class="mx-1 badge bg-secondary">{{p.data.years_of_experience}}
            år</span>
          <span *ngIf="!!p?.data?.total_jumps" class="mx-1 badge bg-secondary">{{p.data.total_jumps}} hopp</span>
          <span *ngIf="!!p?.data?.jump_type" class="mx-1 badge bg-secondary">{{p.data.jump_type}} hopp</span>
          <span *ngIf="!!p?.data?.activity && p.data.activity.length>0"
            class="mx-1 badge bg-secondary">{{p.data.activity | join: '/'}}</span>
        </div>
      </td>
      <td>
        <span *ngIf="!!p?.data && 1==9" class="text-small">
          <nlf-resolve-lungo-license *ngFor="let l of p.data?.licenses" [license_id]="l.id" link="false"
            long="false"></nlf-resolve-lungo-license>
          <nlf-resolve-lungo-function *ngFor="let fid of p.data?.functions" [function_id]="fid" show_org="true"
            link="false" long="false"></nlf-resolve-lungo-function>
        </span>
      </td>
      <td class="text-end">
        <button (click)="openPersonModal(involvedModal, idx)" type="button" class="btn btn-sm btn-warning"><fa-icon
            [icon]="faEdit"></fa-icon>Edit</button>
        <fa-icon *ngIf="!disabled" class="pointer ms-3" title="Slett person" (click)="onRemove(idx)"
          [icon]="faTimes"></fa-icon>
      </td>
    </tr>

  </table>

  <!--<fa-icon (click)="openPersonModal(involvedModal, idx)" [icon]="id-card-o" [fixedWidth]="true"></fa-icon>-->

  <!-- /dataReady -->
</span>

<!-- MODAL -->
<ng-template #involvedModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      <nlf-resolve-lungo-person [person_id]="modalPerson.id" [tmp_name]="modalPerson.tmp_name" link="false"
        avatar="false"></nlf-resolve-lungo-person>
    </h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="modalRef.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">

    <div class="row mb-3">
      <div class="col-xs-12 col-md-12">
        <div class="form-check">
          <input [disabled]="disabled" class="form-check-input" type="checkbox" [(ngModel)]="modalPerson.ph" id="ph">
          <label class="form-check-label" for="ph">
            Permanent Hoppforbud
          </label>
        </div>
        <div class="form-check">
          <input [disabled]="disabled" class="form-check-input" type="checkbox" [(ngModel)]="modalPerson.fu" id="fu">
          <label class="form-check-label" for="fu">
            Farlig Ukontrollert (FU)
          </label>
        </div>
      </div>
    </div>
    <!--Hopptype, år i sporten og antall hopp-->
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="whereAt">Hopptypete</label>
          <nlf-ors-editor-tag-string [fc]="true" [allowAdd]="false" [preload]="true" group="jump_types" activity="fallskjerm"
            [(tagString)]="modalPerson.data.jump_type" [disabled]="disabled">
          </nlf-ors-editor-tag-string>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <label for="activity">Aktivitet</label>
          <nlf-ors-editor-tag [fc]="true" [preload]="true" group="jump_activity" activity="fallskjerm"
            [(initialTags)]="modalPerson.data.activity" [disabled]="disabled">
          </nlf-ors-editor-tag>
        </div>
      </div>
    </div>

    <!--Aktivitet, Flytype, utsprangshøyde -->
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="years_of_experience">År i sporten</label>
          <input [disabled]="disabled" class="form-control" type="number" min="0" id="years_of_experience"
            [(ngModel)]="modalPerson.data.years_of_experience">
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="jups">Antall hopp</label>
          <input [disabled]="disabled" class="form-control" type="number" min="0" id="jups"
            [(ngModel)]="modalPerson.data.total_jumps">
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="aircraft">Flytype</label>
          <nlf-ors-editor-tag-string fc="true" preload="true" group="aircraft_types" activity="fallskjerm"
            [(tagString)]="modalPerson.data.aircraft" [disabled]="disabled">
          </nlf-ors-editor-tag-string>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="altitude">Utsprangshøyde</label>
          <input [disabled]="disabled" class="form-control" type="number" min="0" id="altitude"
            [(ngModel)]="modalPerson.data.altitude">
        </div>
      </div>
    </div>

    <!-- Hovedskjerm, størrelse, erfaring -->
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="mainCanopy">Hovedskjerm</label>
          <nlf-ors-editor-tag-string fc="true" preload="true" group="main_canopies" activity="fallskjerm"
            [(tagString)]="modalPerson.data.gear.main_canopy" [disabled]="disabled">
          </nlf-ors-editor-tag-string>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="mainCanopySize">Størrelse</label>
          <input [disabled]="disabled" class="form-control" type="number" min="0" id="mainCanopySize"
            [(ngModel)]="modalPerson.data.gear.main_canopy_size">
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="mainCanopyExperience">Erfaring Hovedskjerm</label>
          <input [disabled]="disabled" class="form-control" type="number" min="0" id="mainCanopyExperience"
            [(ngModel)]="modalPerson.data.gear.main_canopy_experience">
        </div>
      </div>
    </div>

    <!-- Reserve, størrelse mk, pakkedato -->
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="reserveCanopy">Reserveskjerm</label>
          <nlf-ors-editor-tag-string fc="true" preload="true" group="reserve_canopies" activity="fallskjerm"
            [disabled]="disabled" [(tagString)]="modalPerson.data.gear.reserve_canopy">
          </nlf-ors-editor-tag-string>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="reserveCanopySize">Størrelse</label>
          <input [disabled]="disabled" class="form-control" type="number" min="0" id="reserveCanopySize"
            [(ngModel)]="modalPerson.data.gear.reserve_canopy_size">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label for="rigger">MK</label>
        <!--<input class="form-control" type="text" id="rigger" [(ngModel)]="modalPerson.data.gear.rigger">-->
        <!--<nlf-ors-editor-people id="rigger" [maxItems]="1" [who]="modalPerson.data.gear.rigger" [details]="false" path="involved.{{modalIndex}}.data.gear.rigger" [disabled]="disabled"></nlf-ors-editor-people>-->
        <nlf-ors-editor-tag-persons [disabled]="!observation.acl_user.w" [(persons)]="modalPerson.data.gear.rigger"
          [activity]="observation._model.type" (change)="onChange($event)"></nlf-ors-editor-tag-persons>

      </div>
      <div class="col-md-3">

        <div class="form-group">
          <label for="riggerDate">Pakkedato</label>
          <div class="input-group">
            <!--<nlf-ors-editor-date [disabled]="disabled" id="riggerDate" [(date)]="modalPerson.data.gear.rigger_date"></nlf-ors-editor-date>-->
            <input class="form-control" placeholder="yyyy-mm-dd" name="dp"
              [(ngModel)]="modalPerson.data.gear.riggerDate" ngbDatepicker #d="ngbDatepicker" [maxDate]="today">
            <button [disabled]="disabled" class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
              <fa-icon [icon]="faCalendar"></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Seletøy, erfaring, nødåpner -->
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="harness">Seletøy</label>
          <nlf-ors-editor-tag-string fc="true" preload="true" group="harness_types" activity="fallskjerm"
            [(tagString)]="modalPerson.data.gear.harness" [disabled]="disabled">
          </nlf-ors-editor-tag-string>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="harnessExperience">Erfaring seletøy</label>
          <input [disabled]="disabled" class="form-control" type="number" min="0" id="harnessExperience"
            [(ngModel)]="modalPerson.data.gear.harness_experience">
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="aad">Nødåpner</label>
          <nlf-ors-editor-tag-string fc="true" preload="true" group="aad_types" activity="fallskjerm"
            [(tagString)]="modalPerson.data.gear.aad" [disabled]="disabled">
          </nlf-ors-editor-tag-string>
        </div>
      </div>
    </div>

    <!-- Annet -->
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="other">Annet utstyr</label>
          <nlf-ors-editor-tag fc="true" preload="true" group="other_equipment" activity="fallskjerm"
            [(initialTags)]="modalPerson.data.gear.other" [disabled]="disabled"></nlf-ors-editor-tag>
        </div>
      </div>
    </div>
    <div *ngIf="devDebug" class="row">
      Person: {{ modalPerson | json }}
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-dark" (click)="modalRef.close()">
      <fa-icon [icon]="faTimes" [fixedWidth]="true"></fa-icon>Avbryt
    </button>
    <button [ngClass]="{'disabled': disabled}" class="btn btn-success" (click)="modalInvolvedUpdate(modalIndex)">
      <fa-icon [icon]="faCheck" [fixedWidth]="true"></fa-icon>Oppdater
    </button>
  </div>
</ng-template>