<!--
<tag-input [disabled]="!observation.acl_user.w"
          [(ngModel)]="populated"
          (onAdd)="onAdd($event)"
          (onRemove)="onRemove($event)"
          theme='bootstrap'
          [onlyFromAutocomplete]="false"
          [displayBy]="'callsign'"
          [identifyBy]="'callsign'">

  <tag-input-dropdown
    [showDropdownIfEmpty]="false"
    keepOpen="false"
    [displayBy]="'callsign'"
    [identifyBy]="'callsign'"
    [autocompleteItems]="apidata">
  </tag-input-dropdown>
</tag-input>

(add)="onAdd($event)"
(remove)="onRemove($event)"
(clear)="onClear($event)"
-->

<!-- Select aircraft from register -->
<div class="row">
  <div class="col-md-10 col-sm-8">
    <ng-select [disabled]="!observation.acl_user.w" class="w-auto mw-100 form-control" [items]="aircrafts$ | async" [clearable]="false" [clearOnBackspace]="false" [addTag]="false" [multiple]="true" [hideSelected]="true" [loading]="tagsLoading"
      [typeahead]="aircraftInput$" [(ngModel)]="selectedAircrafts" (add)="onAdd($event)" (remove)="onRemove($event)" bindLabel="callsign" labelForId="callsign" autoCapitalize="on" addTagText="+Opprett nytt fly" placeholder="Skriv for å søke"
      notFoundText="Fant ikke noe">
      <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
        <div>
          <span [ngOptionHighlight]="search">{{item.callsign}}</span>
        </div>
        <small><b>Model:</b> {{item.model}} | <b>Manuf:</b> {{ item.manufacturer }} | <b>Msn:</b> {{ item.msn }}</small>
      </ng-template>
    </ng-select>
  </div>
  <!-- ADD new aircraft -->
  <div *ngIf="observation.acl_user.w" class="col-md-2 col-sm-4 text-end">
    <nlf-aircrafts-add></nlf-aircrafts-add>
  </div>
</div>
<!-- Aircraft TABLE -->
<table *ngIf="!!observation.aircrafts" class="table table-striped table-hover mt-2">
  <tbody>
    <tr *ngFor="let aircraft of observation.aircrafts; let idx = index">
      <td>

        <fa-icon *ngIf="!!aircraft.aircraft._id" [icon]="faCheck" [fixedWidth]="true" class="text-success me-2" title="Hentet fra flyregisteret"></fa-icon>
        <fa-icon *ngIf="!aircraft.aircraft.hasOwnProperty('_id')" [icon]="faExclamation" [fixedWidth]="true" class="text-danger me-2" title="Ukjent fly"></fa-icon>

        <fa-icon *ngIf="aircraft.aircraft.type !== 'HELICOPTER'" [icon]="faPlane" [fixedWidth]="true"></fa-icon>
        <fa-icon *ngIf="aircraft.aircraft.type === 'HELICOPTER'" [icon]="faHelicopter" [fixedWidth]="true"></fa-icon>
        <strong>
          {{ aircraft.aircraft.callsign }}
        </strong>
        <div class="small p-0">{{ aircraft.aircraft.model }} / {{ aircraft.aircraft.manufacturer }}</div>
        <div class="small p-0">MSN: {{ aircraft.aircraft.msn }}</div>
        <div class="small p-0">{{ aircraft.aircraft.type | capitalize }}</div>
      </td>
      <td>
        <div *ngIf="!aircraft.aircraft.e5x">
          <button class="btn btn-secondary" (click)="openAircraftModal(aircraftModal, idx)">
            <fa-icon [icon]="faWarning"></fa-icon> Aircraft Data
          </button>
        </div>
        <div *ngIf="!!aircraft.aircraft.e5x">
          <button class="btn btn-success mb-3" (click)="openAircraftModal(aircraftModal, idx)">
            <fa-icon [icon]="faCheck"></fa-icon> Aircraft Data
          </button>
        </div>

      </td>
      <!--
      <td>
        <div><strong>Gangtider</strong></div>
        <div>Motor: No engine</div>
        <div>Prop: No Props</div>
        <div>AC:</div>
      </td>
      -->
      <td>
        <nlf-ors-editor-aircraft-person [disabled]="!observation.acl_user.w" (change)="updateObservation()" [callsign]="aircraft.aircraft.callsign" [(crew)]="observation.aircrafts[idx].crew" [activity]="observation._model.type"></nlf-ors-editor-aircraft-person>
      </td>
      <!--
      <td>
        <nlf-ors-editor-e5x-aircraft-partinformation [disabled]="!observation.acl_user.w" (change)="updateObservation()" [callsign]="aircraft.aircraft.callsign" [(partinformation)]="observation.aircrafts[idx].parts">
        </nlf-ors-editor-e5x-aircraft-partinformation>
      </td>
      -->
    </tr>
  </tbody>
</table>
<!-- /AC TABLE -->

<!-- FLIGHT -->
<div *ngIf="!!observation.aircrafts && observation.aircrafts.length > 0">
  <div class="nlf-ors-toolbar">
    Planlagt<span *ngIf="observation.aircrafts.length>1">e</span> Rute<span *ngIf="observation.aircrafts.length>1">r</span>
    <nlf-help class="float-end" key="ors-editor-motorfly-routes"></nlf-help>
  </div>

  <ul ngbNav #nav="ngbNav" class="nav-tabs mt-2">
    <!-- AIRCRAFTS IN TABS-->
    <li ngbNavItem *ngFor="let aircraft of observation.aircrafts; let ix = index">
      <a ngbNavLink>{{ aircraft.aircraft.callsign }}</a>
      <ng-template ngbNavContent>
        <div class="py-3">
          <nlf-ors-editor-flight (change)="updateObservation()" [(aircraft)]="observation.aircrafts[ix]" [showSimpleView]="showSimpleView"></nlf-ors-editor-flight>
          <!--{{ observation.aircrafts[ix].flight | json }}-->
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>

</div>
<!-- /FLIGHT-->

<!-- AIRCRAFT MODAL -->
<!-- MODAL -->
<ng-template #aircraftModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      {{ modalAircraft.callsign }}
    </h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="modalRef.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <nlf-aircrafts-edit [disabled]="!observation.acl_user.w" #nlfaircraftedit [(e5x)]="modalAircraft.e5x" [callsign]="modalAircraft.callsign" [showSave]="false" [showImage]="true"></nlf-aircrafts-edit>

    <div *ngIf="devDebug" class="row">
      AC: {{ modalAircraft | json }}
    </div>
  </div>

  <div class="modal-footer">
    <a [routerLink]="['/aircraft', 'edit', (modalAircraft._id || modalAircraft.callsign)]" class="btn btn-outline-dark me-auto" (click)="modalRef.close()">
      <fa-icon [icon]="faEdit" [fixedWidth]="true"></fa-icon>{{ modalAircraft.callsign }} i registeret
    </a>
    <button class="btn btn-outline-dark" (click)="modalRef.close()">
      <fa-icon [icon]="faTimes" [fixedWidth]="true"></fa-icon>Avbryt
    </button>
    <button [disabled]="!observation.acl_user.w" [ngClass]="{'disabled': disabled}" class="btn btn-success" (click)="nlfaircraftedit.saveAircraft();modalAircraftUpdate(modalIndex)">
      <fa-icon [icon]="faCheck" [fixedWidth]="true"></fa-icon>Oppdater
    </button>
  </div>
</ng-template>
