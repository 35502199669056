<!-- FILTER -->
<div class="my-3 text-end">
  <span class="pointer mx-1" [ngClass]="show.reminders ? 'text-info' : 'text-secondary'"
    (click)="show.reminders=!show.reminders">
    <fa-icon [icon]="faBell"></fa-icon> purringer
  </span>
  <span class="pointer mx-1" [ngClass]="show.messages ? 'text-info' : 'text-secondary'"
    (click)="show.messages=!show.messages">
    <fa-icon [icon]="faComments"></fa-icon> meldinger
  </span>
  <span class="pointer mx-1" [ngClass]="show.workflow ? 'text-info' : 'text-secondary'"
    (click)="show.workflow=!show.workflow">
    <fa-icon [icon]="faExchange"></fa-icon> arbeidsflyt
  </span>
  <!-- ONLY motorfly shows E5X *ngIf="activity=='motorfly'" -->
  <span class="pointer mx-1" [ngClass]="show.e5x ? 'text-info' : 'text-secondary'" (click)="show.e5x=!show.e5x">
    <fa-icon [icon]="faPaperPlaneRegular"></fa-icon> LT
  </span>

  <span class="pointer mx-1" [ngClass]="show.save ? 'text-info' : 'text-secondary'" (click)="show.save=!show.save">
    <fa-icon [icon]="faFloppyDisk"></fa-icon> lagring
  </span>
  <span class="pointer mx-1" [ngClass]="show.persons ? 'text-info' : 'text-secondary'"
    (click)="show.persons=!show.persons">
    <fa-icon [icon]="faUserPlus"></fa-icon> personer
  </span>
</div>

<ul *ngIf="!error" class="cbp_tmtimeline">

  <!-- NEW -->
  <li>
    <time class="cbp_tmtime" dateTime="{{ now | date:'y-M-dTHH:mm' }}"><span class="hidden">{{ now | date:'y-M-d'
        }}</span>
      <span class="large">

        <!--{{ now | date:'HH:mm' }}-->now
      </span>
    </time>

    <!--
    <fa-icon [icon]="faComment"></fa-icon>

      <div class="cbp_tmicon avatar">
      <img src="https://www.bootdey.com/img/Content/avatar/avatar2.png" style="margin-top: -3px !important" alt="user" class="avatar-img rounded-circle">
      </div>
    -->

    <div class="cbp_tmicon bg-light">
      <fa-icon [icon]="faCommenting"></fa-icon>
    </div>

    <div *ngIf="observation.workflow.state!='closed'" class="cbp_tmlabel empty">

      <!-- observation.ask.text[observation.workflow.state]" (ngModelChange)="debouncedUpdateText()" (paste)="onPaste($event) -->
      <div [ngClass]="{'bg-secondary': !observation.acl_user.r}" [disabled]="!observation.acl_user.r"
        class="bg-white border mt-2"
        style="min-height:100px; width: 100%;display: inline-block;overflow: hidden;padding:2px;" contenteditable="true"
        propValueAccessor="innerHTML" name="myAskDiv" [(ngModel)]="message_text" (paste)="onPaste($event)">
        Send noe tekst her
      </div>

      <div>

        <!-- PURRINGER -->

        <button *ngIf="is_reminding" class="btn btn-link text-danger mx-1">
          <!--<fa-icon [name]="is_reminding ? 'commenting' : 'bell'"></fa-icon> Purring-->
          <nlf-ui-component-spinner size="3"></nlf-ui-component-spinner> Sender...
        </button>
        <button [disabled]="!observation.acl_user.r" *ngIf="!is_reminding" class="btn btn-link text-danger mx-1"
          (click)="notify()">
          <fa-icon [icon]="faBell"></fa-icon> Purring
        </button>


        <!--
      <button class="btn btn-add-gray">
        <fa-icon [icon]="faPlus"></fa-icon>
      </button>
    -->

        <span class="float-end">
          <!--<fa-icon class="pointer" (click)="openReceipentsModal(recepientModalTemplate, current_acl_users||[])" [icon]="envelope-open-o" [fw]="true"></fa-icon> -->
          <!--<nlf-resolve-avatars [persons]="row.recepients||[]" max="2"></nlf-resolve-avatars>-->
          <span>
            <nlf-resolve-avatars [persons]="current_acl_users||[]" modal_title="Mottakere" max="5">
            </nlf-resolve-avatars>
          </span>




          <!-- ADD RECEPIENT
              <div class="avatar">
              <a href="#">
                <span class="add-new-plus">
                  <fa-icon [icon]="faPlus"></fa-icon>
                </span>
              </a>
              </div>
              -->


          <!-- /ADD RECEPIENT -->
          <!-- /AVATARS -->

          <!-- SEND BTN -->
          <button *ngIf="!is_sending_msg" [disabled]="message_text.length<4" class="btn btn-primary ms-3"
            (click)="sendMessage()">
            <fa-icon [icon]="faComment"></fa-icon> Send
            <!--Melding-->
          </button>
          <button *ngIf="is_sending_msg" [disabled]="message_text.length<4" class="btn btn-primary ms-3">
            <nlf-ui-component-spinner size="3"></nlf-ui-component-spinner> Sending
          </button>

        </span>

      </div>
      <!--
      <div>
        <span class="pointer link me-1 float-end" (click)="openReceipentsModal(recepientModalTemplate, current_acl_users||[])" title="Vis liste over mottakere">vis mottakere</span>
      </div>
    -->

    </div><!-- /btns filter -->
    <div *ngIf="observation.workflow.state=='closed'" class="cbp_tmlabel">
      <h2>Observasjonen er lukket.</h2>
    </div>

  </li>

  <!-- ITERATE -->
  <li *ngFor="let row of events; trackBy: trackByRowId; let idx = index">



    <!-- MESSAGE -->
    <!-- NOTIFICATION -->
    <!-- PERSON -->

    <!-- WORKFLOW -->
    <span *ngIf="row.type=='ors_workflow'">

      <!-- INIT -->
      <span *ngIf="row.data.action=='init' && show.workflow" [title]="row.event_created | date:'yyyy-MM-dd HH:mm'">
        <time class="cbp_tmtime" [dateTime]="row.event_created"><span>{{ row.event_created | date:'HH:mm' }}</span>
          <span>{{ row.event_created | amTimeAgo }}</span></time>
        <div class="cbp_tmicon bg-green">
          <fa-icon [icon]="faPlus"></fa-icon>
        </div>
        <div class="cbp_tmlabel">
          <h2>
            <nlf-resolve-lungo-person [person_id]="row.sender" [link]="true" [avatar]="true"></nlf-resolve-lungo-person>
            <span> opprettet observasjonen </span> <a
              [routerLink]="['/ors', observation._model.type, 'report', observation._id, 'version', 1]"></a>
            <nlf-resolve-observation-state class="float-end" [activity]="observation._model.type"
              [state]="row.data.destination" icon="true"></nlf-resolve-observation-state>
          </h2>

          <!-- RECEPIENTS AVATARS -->
          <div>
            <p *ngIf="!!row.data.comment" class="blockquote blockquote-primary">
              {{ row.data.comment }}
            </p>

            <!--
            <div class="avatar-group avatar-group-sm avatar-group-overlapped mt-10 text-end">
              <div *ngFor="let user of row.recepients; let idx=index" class="avatar">
                <img src="https://www.bootdey.com/img/Content/avatar/avatar{{ idx + 1 }}.png" alt="user" class="avatar-img rounded-circle">
              </div>

              <div class="avatar">
                <span class="avatar-text avatar-text-green rounded-circle"><span class="initial-wrap"><span>1+</span></span>
                </span>
              </div>
            </div>
            -->
            <nlf-resolve-avatars [persons]="row.recepients||[]" modal_title="Mottakere" max="2"></nlf-resolve-avatars>
          </div>
          <!-- /AVATARS -->

        </div>
      </span>

      <!-- NORMAL WORKFLOWS -->
      <span *ngIf="row.data.action!='init' && show.workflow" [title]="row.event_created | date:'yyyy-MM-dd HH:mm'">
        <time class="cbp_tmtime" [dateTime]="row.event_created"><span>{{ row.event_created | date:'HH:mm' }}</span>
          <span>{{ row.event_created | amTimeAgo }}</span>
        </time>

        <div *ngIf="row.data.action.indexOf('approve')>-1" class="cbp_tmicon bg-green">
          <fa-icon *ngIf="row.data.destination!='closed'" [icon]="faExchange"></fa-icon>
          <fa-icon *ngIf="row.data.destination=='closed'" [icon]="faCheck"></fa-icon>
        </div>

        <div *ngIf="row.data.action=='reject'" class="cbp_tmicon bg-blush">
          <fa-icon [icon]="faRefresh"></fa-icon>
        </div>

        <div *ngIf="row.data.action=='withdraw'" class="cbp_tmicon bg-dark">
          <fa-icon [icon]="faBan"></fa-icon>
        </div>

        <div *ngIf="row.data.action=='reopen'" class="cbp_tmicon bg-green">
          <fa-icon [icon]="faRepeat"></fa-icon>
        </div>

        <div class="cbp_tmlabel">
          <h2>
            <nlf-resolve-lungo-person [person_id]="row.sender" [link]="true" [avatar]="true"></nlf-resolve-lungo-person>
            <span *ngIf="row.data.action=='reject'"> sendte observasjonen tilbake </span>
            <span *ngIf="row.data.action.indexOf('approve')>-1"> godkjente observasjonen </span>
            <span *ngIf="row.data.action=='reopen'"> gjenåpnet observasjonen </span>
            <span *ngIf="row.data.action=='withdraw'"> trakk tilbake observasjonen </span>
            <span class="float-end">
              <nlf-resolve-observation-state [activity]="observation._model.type" [state]="row.data.source" icon="true">
              </nlf-resolve-observation-state>
              <fa-icon class="text-secondary mx-1" [icon]="faLongArrowRight"></fa-icon>
              <nlf-resolve-observation-state [activity]="observation._model.type" [state]="row.data.destination"
                icon="true"></nlf-resolve-observation-state>
            </span>
          </h2>
          <!-- RECEPIENTS AVATARS -->
          <div>
            <p *ngIf="!!row.data.comment" class="blockquote blockquote-primary message-text">
              {{ row.data.comment }}
            </p>

            <nlf-resolve-avatars [persons]="row.recepients||[]" modal_title="Mottakere" max="2"></nlf-resolve-avatars>

            <!--
            <div class="avatar-group avatar-group-sm avatar-group-overlapped mt-10 text-end">
              <div *ngFor="let user of row.recepients; let idx=index" class="avatar">
                <img src="https://www.bootdey.com/img/Content/avatar/avatar{{ idx + 1 }}.png" alt="user" class="avatar-img rounded-circle">
              </div>

              <div class="avatar">
                <span class="avatar-text avatar-text-green rounded-circle"><span class="initial-wrap"><span>1+</span></span>
                </span>
              </div>
            </div>
            -->
          </div>
          <!-- /AVATARS -->
        </div>
      </span>
    </span>

    <!-- SAVE -->
    <span *ngIf="row.type=='ors_save' && show.save" [title]="row.event_created | date:'yyyy-MM-dd HH:mm'">
      <time class="cbp_tmtime" [dateTime]="row.event_created"><span>{{ row.event_created | date:'HH:mm' }}</span>
        <span>{{ row.event_created | amTimeAgo }}</span></time>
      <div class="cbp_tmicon bg-info">
        <fa-icon [icon]="faFloppyDisk">{{ row._version }}</fa-icon>
      </div>
      <div class="cbp_tmlabel">
        <h2>
          <nlf-resolve-lungo-person [person_id]="row.sender" [link]="true" [avatar]="true"></nlf-resolve-lungo-person>
          lagret observasjonen
          <span class="float-end">
            <a
              [routerLink]="['/ors', observation._model.type, 'report', observation._id, 'version', row.data.source]">v{{row.data.source}}</a>
            <fa-icon [icon]="faLongArrowRight" class="text-secondary mx-1"></fa-icon>
            <a
              [routerLink]="['/ors', observation._model.type, 'report', observation._id, 'version', row.data.source]">v{{row.data.destination}}</a>
          </span>
        </h2>
        <!--<p>You have a meeting at <strong>Laborator Office</strong> Today.</p>-->
      </div>
    </span>

    <!-- PURRING ULEST -->
    <span *ngIf="row.type=='ors_reminder' && show.reminders" [title]="row.event_created | date:'yyyy-MM-dd HH:mm'">
      <time class="cbp_tmtime" [dateTime]="row.event_created"><span>{{ row.event_created | date:'HH:mm' }}</span>
        <span>{{ row.event_created | amTimeAgo }}</span></time>
      <div class="cbp_tmicon bg-danger">
        <fa-icon [icon]="faBellRegular"></fa-icon>
      </div>
      <div class="cbp_tmlabel">
        <h2>
          <nlf-resolve-lungo-person [person_id]="row.sender" [link]="true" [avatar]="true"></nlf-resolve-lungo-person>
          sendte en purring
          <span class="float-end">

            <span class="badge bg-light text-dark mx-1">{{ row.transport }}</span>
          </span>
        </h2>

        <nlf-resolve-avatars [persons]="row.recepients||[]" modal_title="Mottakere"></nlf-resolve-avatars>
        <!--
        <div *ngFor="let person_id of row.recepients">
          <fa-icon [icon]="faBellRegular" class="text-danger"></fa-icon>
          <nlf-resolve-lungo-person [person_id]="person_id" [link]="true" [avatar]="true"></nlf-resolve-lungo-person>
        </div>
        -->
        <div class="row clearfix">

          <div *ngIf="1==9" class="col-lg-12">
            <button class="btn btn-dark btn-outline">
              <fa-icon [icon]="faBellRegular"></fa-icon> Purr Jan-Erik Wang igjen
            </button>
          </div>

        </div>
      </div>
    </span>

    <!-- HOUSEKEEPING varsel 1-->
    <span *ngIf="row.type=='ors_housekeeping_first_warning' && show.reminders" [title]="row.event_created | date:'yyyy-MM-dd HH:mm'">
      <time class="cbp_tmtime" [dateTime]="row.event_created"><span>{{ row.event_created | date:'HH:mm' }}</span>
        <span>{{ row.event_created | amTimeAgo }}</span></time>
      <div class="cbp_tmicon bg-warning">
        <fa-icon [icon]="faClock"></fa-icon>
      </div>
      <div class="cbp_tmlabel">
        <h2>
          <nlf-resolve-lungo-person [person_id]="row.sender" [link]="false" [avatar]="false"></nlf-resolve-lungo-person>
          sendte <strong>første purring</strong> om tidsfrist
          <span class="float-end">

            <span class="badge bg-light text-dark  mx-1">{{ row.transport }}</span>
          </span>
        </h2>

        <p class="blockquote blockquote-primary" [innerHTML]="row.data?.message | newlines">
        </p>

        <nlf-resolve-avatars [persons]="row.recepients||[]" modal_title="Mottakere"></nlf-resolve-avatars>

        <div class="row clearfix">

        </div>
      </div>
    </span>

    <!-- HOUSEKEEPING varsel 2-->
    <span *ngIf="row.type=='ors_housekeeping_second_warning' && show.reminders" [title]="row.event_created | date:'yyyy-MM-dd HH:mm'">
      <time class="cbp_tmtime" [dateTime]="row.event_created"><span>{{ row.event_created | date:'HH:mm' }}</span>
        <span>{{ row.event_created | amTimeAgo }}</span></time>
      <div class="cbp_tmicon bg-danger">
        <fa-icon [icon]="faClock"></fa-icon>
      </div>
      <div class="cbp_tmlabel">
        <h2>
          <nlf-resolve-lungo-person [person_id]="row.sender" [link]="false" [avatar]="false"></nlf-resolve-lungo-person>
          sendte <strong>andre purring</strong> om tidsfrist
          <span class="float-end">

            <span class="badge bg-light text-dark mx-1">{{ row.transport }}</span>
          </span>
        </h2>

        <p class="blockquote blockquote-primary" [innerHTML]="row.data?.message | newlines">
        </p>

        <nlf-resolve-avatars [persons]="row.recepients||[]" modal_title="Mottakere"></nlf-resolve-avatars>

        <div class="row clearfix">

        </div>
      </div>
    </span>

    <!-- MESSAGES -->
    <span *ngIf="row.type=='ors_message' && show.messages" [title]="row.event_created | date:'yyyy-MM-dd HH:mm'">
      <time class="cbp_tmtime" [dateTime]="row.event_created"><span>{{ row.event_created | date:'HH:mm' }}</span>
        <span>{{ row.event_created | amTimeAgo }}</span></time>
      <div class="cbp_tmicon bg-info">
        <!-- comments-o commenting-o -->
        <fa-icon [icon]="faComment"></fa-icon>
      </div>
      <div class="cbp_tmlabel">
        <h2>
          <nlf-resolve-lungo-person [person_id]="row.sender" [link]="true" [avatar]="true"></nlf-resolve-lungo-person>
          sendte en melding
          <span class="float-end">
            <span class="badge bg-light text-dark mx-1">{{ row.transport }}</span>
          </span>
        </h2>

        <p class="blockquote blockquote-primary" [innerHTML]="row.data?.message | newlines">
        </p>

        <nlf-resolve-avatars [persons]="row.recepients||[]" modal_title="Mottakere"></nlf-resolve-avatars>
        <!--
        <div *ngFor="let person_id of row.recepients">
          <nlf-resolve-lungo-person [person_id]="person_id" [link]="true" [avatar]="true"></nlf-resolve-lungo-person>
        </div>
        -->
      </div>
    </span>


    <!-- E5X -->
    <span *ngIf="row.type=='ors_e5x' && show.e5x" [title]="row.event_created | date:'yyyy-MM-dd HH:mm'">
      <time class="cbp_tmtime" [dateTime]="row.event_created"><span>{{ row.event_created | date:'HH:mm' }}</span>
        <span>{{ row.event_created | amTimeAgo }}</span></time>

      <div *ngIf="row.data.context!='sent'" class="cbp_tmicon bg-orange">
        <fa-icon [icon]="faPaperPlane"></fa-icon>
      </div>
      <div *ngIf="row.data.context=='sent'" class="cbp_tmicon bg-green">
        <fa-icon [icon]="faPaperPlaneRegular"></fa-icon>
      </div>
      <div class="cbp_tmlabel">
        <h2>
          <nlf-resolve-lungo-person [person_id]="row.sender" [link]="true" [avatar]="true"></nlf-resolve-lungo-person>
          sendte observasjonen til LT
          <span class="float-end">
            <span class="badge bg-light text-dark mx-1">{{ row.transport }}</span>
          </span>
        </h2>

        <p>
          Rapport status: <nlf-ors-editor-tag-e5x-render classes="badge bg-secondary" [showLabel]="false"
            [items]="row.data.status" path="E5X.Occurrence.ReportingHistory.ReportStatus">
          </nlf-ors-editor-tag-e5x-render>
        </p>
        <p *ngIf="row.data.context=='sent'">
          Observasjonen ble sendt som version {{ row.data.source }} {{ row.data.context }} via {{ row.data.transport }}
          as {{ row.data.file_name }}
        </p>
        <p *ngIf="row.data.context!='sent'">
          Observasjonen <strong>feilet</strong> under innsendelse!
        </p>

        <nlf-resolve-avatars [persons]="row.recepients||[]" modal_title="Mottakere"></nlf-resolve-avatars>
        <!--
        <div *ngFor="let person_id of row.recepients">
          <nlf-resolve-lungo-person [person_id]="person_id" [link]="true" [avatar]="true"></nlf-resolve-lungo-person>
        </div>
        -->
      </div>
    </span>

    <!-- ACCESS -->
    <span *ngIf="row.type=='ors_acl' && show.persons" [title]="row.event_created | date:'yyyy-MM-dd HH:mm'">
      <time class="cbp_tmtime" [dateTime]="row.event_created"><span>{{ row.event_created | date:'HH:mm' }}</span>
        <span>{{ row.event_created | amTimeAgo }}</span></time>

      <div *ngIf="row.data.verb=='add'" class="cbp_tmicon bg-green">
        <fa-icon [icon]="faUserPlus"></fa-icon>
      </div>

      <div *ngIf="row.data.verb=='remove'" class="cbp_tmicon bg-blush">
        <fa-icon [icon]="faUserTimes"></fa-icon>
      </div>

      <div class="cbp_tmlabel">
        <h2>
          <!-- KEEP legacy data.message format -->
          <nlf-resolve-lungo-person [person_id]="row.sender" [link]="true" [avatar]="true"></nlf-resolve-lungo-person>
          &nbsp;<span *ngIf="!!row.data?.message">{{ row.data.message }}</span><span *ngIf="!row.data?.message">{{
            row.data.verb }} <strong>{{ row.data.right }}</strong></span> for
          <span class="float-end">
            <span class="badge bg-light text-dark mx-1">{{ row.transport }}</span>
          </span>
        </h2>

        <div *ngFor="let person_id of row.recepients">
          <nlf-resolve-lungo-person [person_id]="person_id" [link]="true" [avatar]="true"></nlf-resolve-lungo-person>
        </div>
      </div>
    </span>
  </li>

</ul>


<ng-template #recepientModalTemplate>

  <div class="modal-header">
    <h4 class="modal-title pull-left">
      Mottakere
    </h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="modalRef.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div *ngFor="let person_id of this.modalRecepients">
      <nlf-resolve-lungo-person [person_id]="person_id" [link]="true" [avatar]="true"></nlf-resolve-lungo-person>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-outline-dark" (click)="modalRef.dismiss()">Lukk</button>
  </div>
</ng-template>
