<div *ngIf="dataReady && !canCreate()" class="alert alert-primary" role="alert">
  Sportsfly er nede for vedlikehold
</div>

<!-- QUICKSELECT DEFAULT DISCIPLINE -->
<div *ngIf="dataReady && canCreate() && defaultBtn && userData.settings.default_activity==237" class="d-grid gap-2">
  <button *ngIf="!!userData.settings && canCreate() && !loading"
    (click)="createObservation(userData.settings.default_discipline, userData.settings.default_club, userData.settings.default_activity)"
    class="btn btn-success btn-lg my-1">
    <fa-icon [icon]="faPlus"></fa-icon>
    <nlf-resolve-lungo-organization [organization_id]="userData.settings.default_discipline" show_activity="false">
    </nlf-resolve-lungo-organization>
  </button>
</div>

<!-- NORMAL SELECT DROPDOWN -->
<span *ngIf="dataReady && !defaultBtn && canCreate()">

  <span *ngIf="!loading">

    <div class="row">
      <div class="col">
        <select class="form-control form-control-lg" id="sportsflyklubb" [(ngModel)]="selected">
          <option *ngFor="let club of clubs" [ngValue]="club.id">
            {{ club.name }}
          </option>
        </select>
      </div>
      <div class="col">
        <div class="d-grid gap-2">
          <button [disabled]="!selected" (click)="createObservation()" class="btn btn-success btn-lg">
            <fa-icon [icon]="faPlus"></fa-icon> Opprett ny OBSREG
          </button>
        </div>
      </div>
    </div>
  </span>
</span>


<span *ngIf="loading">
  <nlf-ui-component-spinner size="3"></nlf-ui-component-spinner>
  <p class="lead">Oppretter OBSREG...</p>
</span>


<ngb-alert *ngIf="!!error" type="danger" [dismissible]="true">
  <fa-icon [icon]="faExclamationTriangle"></fa-icon>
  Kunne ikke opprette OBSREG
</ngb-alert>


<!-- End page -->
<span *ngIf="!dataReady">
  <nlf-ui-component-spinner size="3"></nlf-ui-component-spinner>
</span>
