<div class="modal-header">
  <h4 class="modal-title pull-left">
    Debug for #{{ observation.id }}</h4>
  <button type="button" class="close float-end" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="py-3">
    <pre>{{observation | json}}</pre>
  </div>

</div>
<div class="modal-footer">
  <button class="btn btn-outline-dark" (click)="activeModal.close()">Ferdig</button>
</div>
