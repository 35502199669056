<header class="nlf-sticky-toolbar">
	<div class="container">

		<span class="breadcrumb">
			<li class="breadcrumb-item">
				<span class="avatar avatar-xs me-1">
					<img [src]="avatar||avatar_missing" alt="user" class="avatar-img rounded-circle">
				</span>
				<span class="hidden-sm-down">
					<nlf-resolve-lungo-person [person_id]="user.person_id" [avatar]="false"></nlf-resolve-lungo-person>
				</span>
			</li>

			<li class="breadcrumb-item active">
				<a>profil</a>
				<!--[routerLink]="['/organizations', '376']"-->
			</li>
		</span>

		<!--
		<button *ngIf="dataReady" class="btn btn-primary btn-sm float-end mx-1" (click)="openModal(saveTemplate)" title="">
			<fa-icon [icon]="faCog"></fa-icon> Settings
		</button>
		-->

		<a [routerLink]="['/user', person_id]" class="btn btn-sm btn-primary mx-1 float-end">
			<fa-icon [icon]="faUser"></fa-icon> View
		</a>

		<nlf-help class="float-end mx-1" key="user-profile"></nlf-help>

	</div>
</header>

<div *ngIf="dataReady" class="mt-3">
	<div class="container">
		<div class="row">

			<div class="col-md-3 col-xs-12 text-center">

				<img class="img-fluid img-thumbnail rounded" [src]="avatar||avatar_missing">


				<!-- UPLOAD BUTTON -->
        <div class="d-grid gap-2">
          <label class="btn btn-primary btn-file my-1">
            <fa-icon [icon]="faUserCircle"></fa-icon>Browse
            <input type="file" (change)="fileChangeEvent($event);openModal(cropTemplate);" hidden>
            <!--<input type="file"  ngFileSelect [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" multiple>-->
          </label>
        </div>
				<!-- Remove avatar: not yet since !!avatar in subject save loop! Needs nullable in backend too...
        <div class="d-grid gap-2">
				  <a class="btn btn-danger my-3" (click)="removeImage()" *ngIf="!!avatar">Remove</a>
        </div>
				-->

			</div>

			<div class="col-md-9 col-xs-12">

				<div class="my-3">
					<h4>Hovedklubb</h4>
					<strong>
						<nlf-resolve-lungo-organization [organization_id]="user.settings.default_discipline" [show_activity]="true" [long]="true" [link]="true"></nlf-resolve-lungo-organization>
						<fa-icon (click)="openDefaultClub()" class="ms-3 pointer" [icon]="faPencil"></fa-icon>
					</strong>
				</div>
				<div class="my-3">
					<h4>Mine Medlemsskap</h4>
					<div *ngFor="let role of user.acl">
						<div *ngIf="role.org>0 && role.role===10000000 && [2,19,14].indexOf(role.type)>-1">
							<nlf-resolve-lungo-organization [organization_id]="role.org" [link]="true" [show_activity]="true"></nlf-resolve-lungo-organization>:
							<strong>
								<nlf-resolve-lungo-function-type [type_id]="role.role"></nlf-resolve-lungo-function-type>
							</strong>
						</div>
					</div>
				</div>
				<div class="my-3">
					<h4>Mine funksjoner</h4>
					<div *ngFor="let role of user.acl">
						<div *ngIf="role.org>0 && role.activity>0 && role.role!=10000000">
							<nlf-resolve-lungo-organization [organization_id]="role.org" [link]="true" [show_activity]="true"></nlf-resolve-lungo-organization>:
							<strong>
								<nlf-resolve-lungo-function-type [type_id]="role.role"></nlf-resolve-lungo-function-type>
							</strong>
						</div>
					</div>
				</div>
				<div class="my-3">
					<h4>Mine kompetanser</h4>
					<div *ngIf="!user.hasOwnProperty('competences')||user.competences.length===0">Ingen kompetanser registrert</div>
					<div *ngFor="let competence of user.competences">
						<nlf-resolve-lungo-competence [competence_id]="competence.id"></nlf-resolve-lungo-competence>
					</div>
					<nlf-nif-compare [person_id]="person_id" [generate]="true" [showSuccess]="false"></nlf-nif-compare>
				</div>

				<!--
				<div class="my-3">
					<h4>Mine Kompetanser</h4>
					<div *ngFor="let role of user.acl">
						<div *ngIf="role.org>0 && role.role!=10000000">
							<nlf-resolve-lungo-organization [organization_id]="role.org" [link]="true"></nlf-resolve-lungo-organization>:
							<strong>
								<nlf-resolve-lungo-function-type [type_id]="role.role"></nlf-resolve-lungo-function-type>
							</strong>
						</div>
					</div>
				</div>

				<div class="my-3">
					<h4>Mine Lisenser</h4>
					<div *ngFor="let role of user.acl">
						<div *ngIf="role.org>0 && role.role!=10000000">
							<nlf-resolve-lungo-organization [organization_id]="role.org" [link]="true"></nlf-resolve-lungo-organization>:
							<strong>
								<nlf-resolve-lungo-function-type [type_id]="role.role"></nlf-resolve-lungo-function-type>
							</strong>
						</div>
					</div>
				</div>
			-->

				<!--{{ user | json }}-->

			</div>

		</div>
		<div class="row">
			<div class="col-xs-12 col-md-12 mt-6">
				<div class="my-3">
					<h4>Mine observasjoner som involvert</h4>
					<!-- Første er default activity -->
					<nlf-user-ors *ngIf="user.settings.default_activity==109" activity="fallskjerm"></nlf-user-ors>
					<nlf-user-ors *ngIf="user.settings.default_activity==238" activity="motorfly"></nlf-user-ors>
					<nlf-user-ors *ngIf="user.settings.default_activity==237" activity="sportsfly"></nlf-user-ors>
					<nlf-user-ors *ngIf="user.settings.default_activity==111" activity="seilfly"></nlf-user-ors>
					<!-- Lister så resten -->
					<nlf-user-ors *ngIf="user.settings.default_activity!=109" activity="fallskjerm"></nlf-user-ors>
					<nlf-user-ors *ngIf="user.settings.default_activity!=238" activity="motorfly"></nlf-user-ors>
					<nlf-user-ors *ngIf="user.settings.default_activity!=237" activity="sportsfly"></nlf-user-ors>
					<nlf-user-ors *ngIf="user.settings.default_activity!=111" activity="seilfly"></nlf-user-ors>

				</div>
			</div>
		</div>

	</div>
</div>
<div *ngIf="!dataReady" class="text-center">
	<nlf-ui-component-spinner size="3"></nlf-ui-component-spinner>
</div>

<ng-template #cropTemplate>

	<div class="modal-header">
		<h4 class="modal-title pull-left">
			<img class="me-3 avatar avatar-sm img-fluid img-rounded" [src]="modal_avatar||avatar">Profilbilde
		</h4>
		<button type="button" class="close float-end" aria-label="Close" (click)="modalRef.dismiss()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<div class="modal-body">
		<img class="img-fluid avatar avatar-lg" [src]="modal_avatar||avatar" />

		<nlf-ui-component-spinner *ngIf="!avatarReady">loading...</nlf-ui-component-spinner>
		<image-cropper class="img-fluid" resizeToWidth="300" cropperMinWidth="300" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" alignImage="center" [aspectRatio]="1 / 1" format="png" (imageCropped)="imageCropped($event)"
			(imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
		</image-cropper>
	</div>

	<div class="modal-footer">
		<button class="btn btn-outline-dark" (click)="cancelModal()">
			<fa-icon [icon]="faTimes" [fixedWidth]="true"></fa-icon>Avbryt
		</button>
		<button class="btn btn-success" (click)="closeModal()">
			<fa-icon [icon]="faCheck" [fixedWidth]="true"></fa-icon>Bruk
		</button>
	</div>
</ng-template>
