<ng-progress [thick]="true" #progressBar></ng-progress>

<ng-container *ngIf="loggedInObservable | async as authenticated; else loading">
  <nlf-ui-navbar></nlf-ui-navbar>

  <main role="main">
    <nlf-toast></nlf-toast>
    <nlf-alert-service></nlf-alert-service>

    <span>
      <!-- HEARTBEAT -->
      <router-outlet></router-outlet>
      <nlf-ui-footer></nlf-ui-footer>
      <p></p>
      <p></p>
    </span>
  </main>
</ng-container>

<ng-template #loading>
  <nlf-alert-service></nlf-alert-service>
  <div class="clearfix">
    <nlf-auth></nlf-auth>
  </div>
</ng-template>