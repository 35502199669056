<div *ngIf="!!dataReady && !error">
  <div [id]="activity" class="list-group list-group-flush">
    <a [routerLink]="['/ors', o._model.type, 'report', o.id]" *ngFor="let o of data"
    class="small list-group-item list-group-item-action d-flex justify-content-between align-items-center">
      <span>#{{ o.id }} {{ o.title || (o.tags | nlfOrsTags:'/') }}</span>
      <fa-icon *ngIf="o.files?.length > 0" [icon]="faPaperclip"></fa-icon>
      <span ngbTooltip="{{ o.rating.actual }} av {{ o.rating.potential }}" container="body" class="badge bg-{{o.rating | nlfOrsRatingCalc | nlfDynamicColor}} rounded-pill">{{ o.rating | nlfOrsRatingCalc }}</span>
    </a>
  </div>
</div>
<div *ngIf="!dataReady && !error">
  <p class="align-middle text-center text-secondary">
    <nlf-ui-component-spinner></nlf-ui-component-spinner>
  </p>
</div>
<div *ngIf="!!dataReady && !!error">
  <p class="align-middle text-center text-secondary">
    Ingen
  </p>
</div>
